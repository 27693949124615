import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from "react-router-dom";
import '../../assets/css/components/Home.css';
import { CREATE_CHECKPOINT_MODE } from '../common/Constants';

const Home = () => {
    const history = useHistory();

    const FormattedTooltip = withStyles({
        tooltip: {
            fontSize: "14px",
            maxWidth: 600,
            textAlign: "center",
            backgroundColor: "black",
            fontFamily: `"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif`
        },
        arrow: {
            color: "black"
        }
    })(Tooltip);

    /**
     * Navigates to the selected page
     */
    const navigateToPage = (url) => {
        history.push(url);
    };

    return (
        <div id="Home">
            <div id="Home-title">
                <h2>Welcome to Checkpoint!</h2>
                <h2>Streamline Project Responsibilities with Our RACI Mapping Solution</h2>
            </div>
            <div id="home-cards" className='home-cards'>
                <FormattedTooltip arrow interactive 
                    title={<p>To be used by PDP meeting organizers to generate a list of Assignees (Approver, Consulted, Informed) and Delegates (1 & 2) 
                        for PDP milestone reviews. Default selection: Approver, Consulted and Delegate 1.</p>}>
                    <div onClick={() => navigateToPage(`/checkpoint/${CREATE_CHECKPOINT_MODE}`)} className='home-card'
                        style={{backgroundImage: `url("${process.env.PUBLIC_URL}/Create_Checkpoint.svg")`}}
                        title=''>
                    </div>
                </FormattedTooltip>
                <FormattedTooltip arrow interactive 
                    title={<p>To be used by Assignees (Approvers, Consulted, Informed) and Delegates to maintain a list of 
                        names for their respective functions across different Product Lines, Milestones and Hardware Leaders.</p>}>
                    <div onClick={() => navigateToPage('/bulk-edit-keys')} className='home-card'
                        style={{backgroundImage: `url("${process.env.PUBLIC_URL}/Manage_Responsibility_Matrix.svg")`}}>
                    </div>
                </FormattedTooltip>
                <div onClick={() => navigateToPage('/checkpoints')} className='home-card'
                    style={{backgroundImage: `url("${process.env.PUBLIC_URL}/Manage_Checkpoints.svg")`}}>
                </div>
                <div onClick={() => {
                    window.open("https://wiki.labcollab.net/confluence/pages/viewpage.action?pageId=581671402", '_blank', 'noopener,noreferrer')}} 
                    className='home-card'
                    style={{backgroundImage: `url("${process.env.PUBLIC_URL}/Checkpoint_Training.svg")`}}>
                </div>
            </div>
        </div>
    );
};

export default Home;