import { useCollection } from '@amzn/awsui-collection-hooks';
import { Button, Pagination, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/css/components/ManageCheckpoints.css";
import EmptyState from "../EmptyState";
import { CREATE_CHECKPOINT_MODE, MILESTONE_ATTRIBUTE_NAME } from '../common/Constants';

/**
 * Component to Manage Checkpoints for the logged-in user
 */
const ManageCheckpoints = (props) => {
    const CHECKPOINTS_PER_PAGE = 100;
    const history = useHistory();
    const [checkpoints, setCheckpoints] = useState([]);
    const [enableLoadingSpinner, setEnableLoadingSpinner] = useState(false);
    const columns = [
        {
            id: "checkpointDate",
            header: "Milestone Review Date",
            cell: e => <p>{e.checkpointDate}</p>,
            sortingField: "checkpointDate"
        },
        {
            id: "programName",
            header: "Program Name",
            //eslint-disable-next-line jsx-a11y/anchor-is-valid
            cell: e => <p>{e.programName}</p>,
            sortingField: "programName"
        },
        {
            id: "milestone",
            header: "Milestone",
            cell: e => <p>{retrieveMilestoneAttributeValue(e.checkpointCriteria)}</p>,
            sortingComparator: (a, b) => {
                const milestoneA = retrieveMilestoneAttributeValue(a.checkpointCriteria) ?? '';
                const mileStoneB = retrieveMilestoneAttributeValue(b.checkpointCriteria) ?? '';
                return milestoneA.toLowerCase().localeCompare(mileStoneB.toLowerCase());
            },
            sortingField: "milestone"
        },
        {
            id: "description",
            header: "Description",
            cell: e => <p className="Checkpoint-Description-col">{e.description}</p>,
        }
    ];

    useEffect(() => {
            void (async () => {
                setEnableLoadingSpinner(true);
                try {
                    const response = await API.get('ApproverMatrixAPI', `/checkpoints-for-user/${props.user.userAlias}?processId=${props.process.processId}`);
                    setCheckpoints(response?.checkpoints ?? []);
                }
                catch(err) {
                    props.updateMessage('Unable to retrieve Checkpoints. Please try again.', 'error');
                }
                finally {
                    setEnableLoadingSpinner(false);
                }
            })()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.attributes]);

    /**
     * Retrieves the value of 'Milestone' attribute, from a saved Checkpoint criteria.
     */
    const retrieveMilestoneAttributeValue = (checkpointCriteria) => {
        if(!props.attributes || !checkpointCriteria) return '';
        const milestoneAttributeId = Object.keys(props.attributes ?? {}).find(attributeId => props.attributes[attributeId]?.attributeName === MILESTONE_ATTRIBUTE_NAME);        
        return checkpointCriteria[milestoneAttributeId] ?? '';
    };

    /**
     * Navigates to the Create Checkpoint page
     */
    const navigateToCreateCheckpoint = () => {
        history.push(`/checkpoint/${CREATE_CHECKPOINT_MODE}`);
    };

    /**
     * Set props for filtering checkpoints
     */
    const { items: checkpointItems, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(checkpoints, {
        filtering: {
            empty: ( !enableLoadingSpinner &&
                        <EmptyState
                            title="No checkpoints"
                            subtitle="No checkpoints to display"
                        />
                    ),
            noMatch: (
                        <EmptyState
                            title="No matches"
                            subtitle="We can’t find a match."
                            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                        />
                    ),
            filteringFunction: (item, filteringText) => {
                const stringifiedItem = JSON.stringify(item);
                return stringifiedItem.toLowerCase().includes(filteringText.toLowerCase());
            }
        },
        sorting: { 
            defaultState: {
                sortingColumn: {
                    id: "checkpointDate",
                    header: "Milestone Review Date",
                    cell: e => e.checkpointDate,
                    sortingField: "checkpointDate",
                    sortingDescending: true
                },
                isDescending: true
            }
        },
        pagination: { pageSize: CHECKPOINTS_PER_PAGE }
    });

    /**
     * Function to handle row clicked
     * Navigate the user to the corresponding Checkpoint in edit mode
     */
    const handleRowClick = (event) => {
        if(event.detail?.item?.checkpointId) {
            history.push(`/checkpoints/${event.detail.item.checkpointId}`);
        }
    };


     /**
     * Display the header info for the Checkpoints table
     */
     const generateHeader = () => {
        return (
            <div className="manage-checkpoints-header">
                <div>
                    <h3>My Checkpoints ({checkpoints?.length})</h3>
                </div>
                <Button variant="primary" onClick={navigateToCreateCheckpoint}>Create Checkpoint</Button>
            </div>
        );
    };

    /**
     * Function to return the count of filtered input matched items in the format of: #matchedItemsCount matches
     */
    const getFilteredItemsCount = (count) => {
        return count + " matches";
    };

    return (
        <div id="manage-checkpoints" className="manage-checkpoints">
            <div id='manage-checkpoints-dashboard-wrapper' className='manage-checkpoints-dashboard-wrapper'>
                <Table
                    {...collectionProps}
                    columnDefinitions={columns}   
                    items={checkpointItems}
                    onRowClick={handleRowClick}
                    header={generateHeader()}
                    
                    filter = {
                        <TextFilter 
                            {...filterProps}
                            countText={getFilteredItemsCount(filteredItemsCount)}
                            filteringAriaLabel="Filter checkpoints"
                            filteringPlaceholder="Filter checkpoints"
                        />
                    }
                    pagination={
                        <Pagination
                            {...paginationProps}
                            ariaLabels={{
                                nextPageLabel: "Next page",
                                previousPageLabel: "Previous page",                            
                            }}
                            pagesCount={Math.ceil(filteredItemsCount / CHECKPOINTS_PER_PAGE)}
                        />
                    }
                />
                <div id='manage-checkpoints-spinner-wrapper' className='manage-checkpoints-spinner-wrapper'>
                    {
                        enableLoadingSpinner && <Spinner size='large' />
                    }
                </div>
            </div>
        </div>
    );
};

export default ManageCheckpoints;