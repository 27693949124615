import React, {useEffect, useState} from "react";
import {FormField, Input} from "@amzn/awsui-components-react";
import Icon from "@mdi/react";
import { mdiAccountCheck } from '@mdi/js';
import { mdiClose } from '@mdi/js';
// import { mdiLockOpenOutline } from '@mdi/js';
import { mdiUndo } from '@mdi/js';
import {Button, Col, Row} from "react-bootstrap";
import {UserService} from "../service/UserService";
import {CircularProgress} from "@material-ui/core";
import "../assets/css/components/Process.css";

export default function ProcessAdmin(props) {
    const [admin, setAdmin] = useState(props.admin);
    const setProcessAdmins = props.setProcessAdmins;
    const processAdmins = props.processAdmin;
    const index = props.index;
    const userPromises = props.userPromises;
    const [validationResponse, setValidationResponse] = useState("");
    const removeAdminByIndex = props.removeAdminByIndex;
    const undoRemoveAdminByIndex = props.undoRemoveAdminByIndex;
    const setDisableProcessSave = props.setDisableProcessSave;
    const [adminValid, setAdminValid] = useState(false);
    const [adminName, setAdminName] = useState("");
    const [validating, setValidating] = useState(false);
    const [disableValidate, setDisableValidate] = useState(false);

    const updateLocalAdmin = (val, valid) => {
        userPromises.push(setAdmin((prevAdminState)=>{
            return {alias:val, validationError:"", validated:valid, persisted:prevAdminState.persisted, remove:prevAdminState.remove}
        }));
    };

    const updateAdmin = (val, valid) => {
        userPromises.push(setAdmin((prevAdminState)=>{
            return {alias:val, validationError:"", validated:valid, persisted:prevAdminState.persisted, remove:prevAdminState.remove}
        }));
        userPromises.push(setProcessAdmins((prevAdmins)=>{
            const updatedAdmins = [...prevAdmins];
            const prevAdminState = processAdmins[index];
            updatedAdmins[index] = {alias:val, validationError:"", validated:valid, persisted:prevAdminState.persisted, remove:prevAdminState.remove};
            return updatedAdmins;
        }));
    };

    const validateAlias = () => {
        // check for duplicate first
        let otherAliasList = processAdmins.map((pa, idx) => {return idx === index || pa.remove ? undefined : pa.alias.toLowerCase()});
        if (otherAliasList.includes(admin.alias.toLowerCase())){
            setAdminValid(false);
            setValidationResponse(`Unable to add duplicate alias as admin.`);
            setValidating(false);
            setDisableValidate(true);
            return;
        }
        // console.log(otherAliasList);
        // validate via database
        UserService.validateUser(admin.alias.toLowerCase()).then(
            response => {
                if (!response.validUser){
                    setAdminValid(false);
                    setValidationResponse(`Unable to find alias ${admin.alias.toLowerCase()} in system.`);
                    updateAdmin(admin.alias.toLowerCase(), false);
                    setValidating(false);
                    setDisableValidate(true);
                }
                else{
                    UserService.getUser(admin.alias.toLowerCase()).then(response=>{
                        setAdminName(response.userName);
                        setAdminValid(true);
                        setValidationResponse("");
                        updateAdmin(admin.alias.toLowerCase(), true);
                        setValidating(false);
                    })
                }
            })
            .catch(err => {
                setAdminValid(false);
                setValidationResponse(`Unable to find alias ${admin.alias.toLowerCase()} in system.`);
                updateAdmin(admin.alias.toLowerCase(), false);
                setValidating(false);
                setDisableValidate(true);
            });
    }

    const removeAdmin = () => {
        setDisableProcessSave(false);
        removeAdminByIndex(index);
    };

    const undoRemoveAdmin = () => {
        setDisableProcessSave(false);
        undoRemoveAdminByIndex(index);
    };

    useEffect(()=>{
        if (props.admin.persisted){
            setValidating(true);
            validateAlias(); 
        } // eslint-disable-next-line
    }, [props.admin.persisted]);

    useEffect(()=>{
        setAdmin((prevAdminState)=>{
            const updatedAdminState = {...prevAdminState};
            updatedAdminState.remove = props.admin.remove;
            return updatedAdminState;
        });
    }, [props.admin.remove]);

    return (
        <Row className={`p-2 d-flex mt-2 mx-3 justify-content-center align-items-center ${admin.remove ? "processview-admin-grey-row" : ""}`}>
            {
                validating? 
                <Col lg={4} className="d-flex justify-content-center">
                    <h2 className="mt-1">Validating alias...</h2>
                    <CircularProgress className="ms-3 mb-1" size={30}/>
                </Col> :
                <React.Fragment>
                    {
                        adminValid? 
                        <React.Fragment>
                            <Col lg={2} className="d-flex justify-content-center">
                                <Icon path={mdiAccountCheck} size={2} color={admin.remove ? "grey" : "black"} />
                            </Col>  
                            <Col lg={2} className="d-flex justify-content-center">
                                <h4 className={`pt-2 ${admin.persisted ? "" : "processview-new-admin-text"} ${admin.remove ? "processview-admin-grey-text" : ""}`}>{admin.alias}</h4>
                            </Col>
                            <Col lg={2} className="d-flex justify-content-center">
                                <h4 className={`pt-2 ${admin.persisted ? "" : "processview-new-admin-text"} ${admin.remove ? "processview-admin-grey-text" : ""}`}>{adminName}</h4>
                            </Col>
                            <Col lg={3} className="d-flex justify-content-center">
                                <h4 className={`pt-2 ${admin.persisted ? "" : "processview-new-admin-text"} ${admin.remove ? "processview-admin-grey-text" : ""}`}>{admin.alias + "@amazon.com"}</h4>
                            </Col>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Col lg={2} className="d-flex justify-content-center">
                                {/* <Icon path={mdiLockOpenOutline} size={2} /> */}
                            </Col>  
                            <Col lg={5} className="d-flex justify-content-center">
                                <Col className="d-flex" lg={9}>
                                    <Row className="flex-grow-1">
                                        <Input  value={admin.alias}
                                                type='text'
                                                className="flex-grow-1"
                                                placeholder={admin.persisted ? "please update alias or remove": "enter alias for admin"}
                                                onChange={ ({detail}) => {
                                                    setDisableValidate(false);
                                                    setAdminValid(false);
                                                    setDisableProcessSave(false);
                                                    updateLocalAdmin(detail.value, false);
                                                }}
                                                autoComplete={false} />                                  
                                        <FormField errorText={validationResponse}></FormField>
                                        <FormField errorText={processAdmins[index].validationError}></FormField>
                                    </Row>
                                </Col>
                            </Col>
                            <Col lg={2} className="d-flex justify-content-center">
                                <Button variant="primary" 
                                        className="p-2 process-button" 
                                        onClick = {() => {validateAlias()}}
                                        disabled = {disableValidate}>
                                    Validate
                                </Button>
                            </Col>
                        </React.Fragment>
                    }
                    <Col lg={2} className="d-flex justify-content-center py-2">
                        {
                            admin.remove ? 
                            <Button variant="outline-primary" onClick={()=>{undoRemoveAdmin()}}>
                                <Icon path={mdiUndo} size={1.3} />
                            </Button> :
                            <Button variant="outline-primary" onClick={()=>{removeAdmin()}}>
                                <Icon path={mdiClose} size={1.3} />
                            </Button>
                        }
                    </Col>
                </React.Fragment>
            }
        </Row>
    )

}