import React from "react";

import {Box, SpaceBetween, Button} from "@amzn/awsui-components-react";

export const TableNoMatchState = (props) => (
    <Box id="no_match_container" margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div id="no_match_div">
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    We can't find a match.
                </Box>
            </div>
            <Button onClick={props.onClearFilter}>Clear filter</Button>
        </SpaceBetween>
    </Box>
);
