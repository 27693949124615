import {Container, Row} from "react-bootstrap";
import React from "react";

export default function Error() {

    return (
        <Container className="pt-4">
            <Row className="m-4 text-center">
                <h1>Application Error</h1>
            </Row>
            <Row className="m-4 text-center">
                <h2>An error occurred in the application and your page couldn't be served.</h2>
            </Row>
            <Row className="m-4 text-center">
                <h3>
                    To report an issue please use <a href="https://tiny.amazon.com/15rtz8sa4/tcorpamazcreaopti">this link</a>.
                </h3>
            </Row>
        </Container>
    );
}
