import React from "react";

import {Box, SpaceBetween, Button} from "@amzn/awsui-components-react";

export const TableEmptyState = (props) => (
    <Box id="empty-container" margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
        <div id="empty-div">
            <b>No {props.resourceName.toLowerCase()}s</b>
            <Box id="no-match-inner-container" variant="p" color="inherit">
                No {props.resourceName.toLowerCase()}s associated with this resource.
            </Box>
        </div>
        {props.onClick !== undefined &&
            <Button id="create-button" onClick={props.onClick}>Create {props.resourceName.toLowerCase()}</Button>}
        </SpaceBetween>
    </Box>
);