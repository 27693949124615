import { useCollection } from '@amzn/awsui-collection-hooks';
import { Button, Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import { Amplify } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/css/components/Drafts.css";
import EmptyState from "../EmptyState";

const Drafts = (props) => {
    const user =  props.user;
    const [drafts, setDrafts] = useState([]);
    const [draftColumns, setDraftColumns] = useState([]);
    const [processId] = useState(props.process.processId);
    const updateMessage = props.updateMessage;
    const process = props.process;
    const history = useHistory();
    const DRAFTS_PER_PAGE = 10;
    const phoneToolURL = "https://phonetool.amazon.com/users";

    /**
     * Validate whether the logged in User is authorized to view drafts during the initial page load
     */
    useEffect(() => {
        if(!user?.devAdmin && !process.processAdmins.includes(user?.userAlias)) {
            updateMessage("You are not authorized to view drafts", "error");
            history.push("/");
        }
    // eslint-disable-next-line
    }, []);

    /**
     * Set props for filtering drafts
     */
    const { items: draftItems, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(drafts, {
        filtering: {
            empty: (
                        <EmptyState
                            title="No drafts"
                            subtitle="No drafts to display"
                            action={<Button onClick={() => history.push('/key')}>Create Keys</Button>}
                        />
                    ),
            noMatch: (
                        <EmptyState
                            title="No matches"
                            subtitle="We can’t find a match."
                            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                        />
                    )
        },
        sorting: { },
        pagination: { pageSize: DRAFTS_PER_PAGE }
    });

    /**
     * Retrieve all the drafts during the initial load of the component for the current process
     */
    useEffect(() => {
        Amplify.API.get("ApproverMatrixAPI", `/drafts?processId=${processId}`)
        .then(response => {
            //Append the createdDate, lastUpdatedDate properties from their respective timestamps
            //to make those dates filterable
            const retrievedDrafts = response.drafts.map(draft => {return {...draft, 
                lastUpdatedDate:  new Date(parseInt(draft.lastUpdatedTimestamp) * 1000).toLocaleDateString(),
                createdDate: new Date(parseInt(draft.createdTimestamp) * 1000).toLocaleDateString()}});
            setDrafts(retrievedDrafts);
        })
        .catch(err => {
            err?.response?.data?.errorMessage ?
                updateMessage("Unable to retrieve drafts! " + err.response.data.errorMessage, "error")
                : updateMessage("Unable to retrieve drafts! Please try again.", "error");
        });
    // eslint-disable-next-line
    }, []);

    /**
     * Set the columns to be displayed for the draft
     * Draft Name, totalKeys columns are sortable
     */
    useEffect(() => {
        let cols = [];
        cols.push({
            id: "name",
            header: "Name",
            // eslint-disable-next-line
            cell: e => <a className="Drafts-name-cols">{e.draftName}</a>,
            sortingField: "draftName"
        });
        cols.push({
            id: "totalKeys",
            header: "Key Combinations",
            cell: e => e.totalKeys,
            sortingComparator: (item1, item2) => item1.totalKeys - item2.totalKeys
        });
        cols.push({
            id: "createdUser",
            header: "Created User",
            cell: e => <a className="Drafts-name-cols" href={`${phoneToolURL}/${e.createdUserAlias}`} 
                onClick="event.preventDefault()" rel="noreferrer" target="_blank">{e.createdUserName}</a>,
            sortingField: "createdUserName"
        });
        cols.push({
            id: "lastUpdatedUser",
            header: "Last Updated User",
            cell: e => <a className="Drafts-name-cols" href={`${phoneToolURL}/${e.lastUpdatedUserAlias}`} 
               onClick="event.preventDefault()" rel="noreferrer" target="_blank">{e.lastUpdatedUserName}</a>,
            sortingField: "lastUpdatedUserName"
        });
        cols.push({
            id: "createdTimestamp",
            header: "Created",
            cell: e => e.createdDate,
            sortingField: "createdTimestamp"
        });
        cols.push({
            id: "lastUpdatedTimestamp",
            header: "Last Updated",
            cell: e => e.lastUpdatedDate,
            sortingField: "lastUpdatedTimestamp"
        });
        setDraftColumns(cols);
    }, []);

    /**
     * Function to handle row clicked
     * Navigate the user to the corresponding draft view
     */
    const handleRowClick = (event) => {
        if(event.detail?.item?.draftId) {
            history.push(`/key/draft/${event.detail.item.draftId}`);
        }
    };

    /**
     * Display the header info for the drafts table
     */
    const createHeader = () => {
        return (
            <div>
                <h3>Drafts ({drafts?.length})</h3>
            </div>
        );
    };


    /**
     * Function to return the count of filtered input matched items in the format of: #matchedItemsCount matches
     */
    const getFilteredItemsCount = (count) => {
        return count + " matches";
    };


    return (
        <div className="Drafts">
            <Table
                {...collectionProps}
                columnDefinitions={draftColumns}   
                items={draftItems}
                onRowClick={handleRowClick}
                header={createHeader()}
                
                filter = {
                    <TextFilter 
                        {...filterProps}
                        countText={getFilteredItemsCount(filteredItemsCount)}
                        filteringAriaLabel="Filter drafts"
                        filteringPlaceholder="Find drafts"
                    />
                }
                pagination={
                    <Pagination
                        {...paginationProps}
                        ariaLabels={{
                            nextPageLabel: "Next page",
                            previousPageLabel: "Previous page",                            
                        }}
                        pagesCount={Math.ceil(filteredItemsCount / DRAFTS_PER_PAGE)}
                    />
                }
            />
        </div>
    );
};

export default Drafts;