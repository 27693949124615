import { FormField, Select } from "@amzn/awsui-components-react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../assets/css/components/AttributeFilter.css";

export default function AttributeFilter(props) {
    const setSelectedValues = props.setSelectedValues;
    const selectedValues = props.selectedValues;
    const attribute = props.attribute;
    const [selectedValue, setSelectedValue] = useState(null);
    const mode = props.mode;
    const index =props.index;
    const applyCascadingFilters = props.applyCascadingFilters ?? false;

    /**
     * Updates the state of the current attribute .
     * Scenarios: Empties the value of the current attribute, in reaction to a Reset or a Parent attribute selection update.
     */
     useEffect(() => {
        if(!selectedValues || !attribute) return;
        if(selectedValues && !selectedValues[attribute.attributeId] && selectedValue?.value) {
            setSelectedValue(null);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValues, attribute]);

    /** 
     * Update the selected-value state for the current attribute, based on the 'criteria' object received from the response
     * (Existing Checkpoint / Existing Draft)
    */
    useEffect(() => {
        if(!attribute || !props.criteria) return;
        if(props.criteria[attribute.attributeId]) {
            if(Array.isArray(props.criteria[attribute.attributeId])) {
                const currentSelectedValue = props.criteria[attribute.attributeId].length > 0 ? {attribute: attribute, value: props.criteria[attribute.attributeId][0]} : null;
                setSelectedValue(currentSelectedValue);
            }
            else {
                setSelectedValue({attribute: attribute, value: props.criteria[attribute.attributeId]});
            }
        }
    }, [props.criteria, attribute]);

    /**
     * 
     * Updates the state the selected value for the current attribute.
     * 
     * If the cascading filters flag is on, the control flow is as follows:
     *      Approach: The current attribute (assuming is a parent to at-least one other attribute) is handled the responsibility to resetting the state of all
     *            the dependents that originate from the attribute. This approach prevents the dependent attributes on the same-level overriding the global state.
     *      STEPS:
     *          1) Determine all the dependents(both direct and indirect) starting from the current attribute.
     *          2) For all the dependent attributes, delete their values from the global 'selectedValues' state
     *          3) Update the global 'selectedValues' state
     * 
     *      This update to the global 'selectedValues' state triggers the dependent attribute's to update their own value state to null.
     * 
     * 
     */
    const updateAttributeValue = function (option){
        setSelectedValue(option);
        const values = JSON.parse(JSON.stringify(selectedValues));

        if(applyCascadingFilters) {
            const visitedDependents = new Set();
            const trackedParentAttributeIds = new Set();
            trackedParentAttributeIds.add(attribute.attributeId);
            while(true) {
                const dependentsSize = visitedDependents.size;
                for(const processAttributeId of Object.keys(props.attributes ?? [])) {
                    const processAttribute = props.attributes[processAttributeId];
                    if(processAttribute.parentAttributeId && trackedParentAttributeIds.has(processAttribute.parentAttributeId)
                     && !visitedDependents.has(processAttributeId)) {
                        visitedDependents.add(processAttributeId);
                        trackedParentAttributeIds.add(processAttributeId);
                    }
                }
                if(visitedDependents.size === dependentsSize) break; //Guaranteed to terminate due to the 'visitedDependents' set.
            }
            for(const dependentId of visitedDependents) {
                delete values[dependentId];
            }
        }
        values[attribute.attributeId] = [option.value];
        setSelectedValues(values);
    }

    /**
     * Filters the displayed options based on the 'applyCascadingFilters' flag.
     *  applyCascadingFilters is TRUE: retrieve the values mapped to the parent attribute's selection, in a sorted fashion.
     *  applyCascadingFilters is FALSE: return all the values, in a sorted fashion.
     */
    const filterAttributeValues = (attributeValues) => {
        if(applyCascadingFilters && attribute?.parentAttributeId) {
            if(selectedValues) {
                const selectedParentValue = Array.isArray(selectedValues[attribute.parentAttributeId]) ? 
                    selectedValues[attribute.parentAttributeId][0] : selectedValues[attribute.parentAttributeId];
                const mappedValuesForSelectedParentAttributeValue = Object.keys(attribute.cascadingMappings ?? [])
                    .filter(key => attribute.cascadingMappings[key].find(value => value === selectedParentValue));
                return sortAttributeValues(mappedValuesForSelectedParentAttributeValue) ?? [];
            }
            else {
                return [];
            }
        }
        else {
            return sortAttributeValues(attributeValues);
        }
    };

    const sortAttributeValues = (attributeValues) => {
        return Object.values(attributeValues).sort(function(a, b) {

            let l =  a.toLowerCase(), r = b.toLowerCase();

            if (l < r) { return -1; }
            if (l > r) { return 1; }
            return 0;
        });
    }

    const getAttributeName = () => {
        return attribute.coreAttribute ? attribute.attributeName + "*" : attribute.attributeName;
    }

    return (
        <Row  className="p-1 mb-3 justify-content-md-center row-control gap-5">
            <Col lg={2}
                 className={`d-flex justify-content-end ${props.classNamePrefix}-single-select-label-wrapper`}>
                <FormField className="pt-2 px-2 fw-bold"
                           id = {"attributeName-" + index}
                           label={getAttributeName()}/>
            </Col>
            <Col lg={3} className={`justify-content-right ${props.classNamePrefix}-single-select-filter-wrapper`}>
                <Select placeholder="Not Specified"
                        className="pt-1 pb-2"
                        id ={"attributeSelectDropdown-" + attribute.attributeName.replace(/\s/g, "")}
                        selectedOption={selectedValue}
                        options={filterAttributeValues(attribute?.attributeValues ?? []).map(elem => ({attribute: attribute, value: elem}))}
                        onChange={(event) => updateAttributeValue(event.detail.selectedOption)}
                        autoComplete={false}
                        filteringType="auto"
                        disabled={mode==="view"}/>
            </Col>
        </Row>
    )
}