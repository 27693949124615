import {Amplify} from "aws-amplify";

export class KeyService {

    /**
     * Call service API to save/update a single key
     *
     * @param requestObj
     */
    static saveKey(requestObj) {
        return Amplify.API.post("ApproverMatrixAPI", `/keys`, {body: requestObj});
    }

    /**
     * Call service API to bulk update keys
     *
     * @param requestObj
     */
    static bulkUpdateKeys(requestObj) {
        return Amplify.API.post("ApproverMatrixAPI", `/keys/bulk-update-keys`, {body: requestObj});
    }

    /**
     * Call service API to bulk delete keys
     *
     * @param requestObj
     */
    static bulkDeleteKeys(requestObj) {
        return Amplify.API.del("ApproverMatrixAPI", `/keys/bulk-delete-keys`, {body: requestObj});
    }

    /**
     * Call service API to fetch matching keys
     *
     * @param username
     * @param processId
     * @param userType
     * @param userStatus
     * @return {*}
     */
    static getMatchingKeysForUserAndProcess(username, processId, userType,userStatus) {
        const isActive = true;
        return Amplify.API.get("ApproverMatrixAPI", `/user-keys/${username}?processId=${processId}&userType=${userType}&isActive=${isActive}&userStatus=${userStatus}`);
    }

    /**
     * Call service API to fetch matching keys
     *
     * @param criteria
     * @param processId
     * @return {*}
     */
    static getMatchingKeysForCriteriaAndProcess(criteria, processId) {
        const requestObj = {attributeCriteria: criteria, processId: processId};
        console.log(requestObj);
        return Amplify.API.post("ApproverMatrixAPI", `/keys/attribute-criteria`, {body: requestObj});
    }

    /**
     * Call service API to bulk create imported keys
     *
     * @param keysToCreate
     * @param processId
     * @param loggedInAlias
     */
    static bulkCreateKeysFromImport(keysToCreate, processId, loggedInAlias) {
        const requestObj = {processId: processId, keys: keysToCreate, loggedInAlias: loggedInAlias};
        return Amplify.API.post("ApproverMatrixAPI", `/keys/bulk-create-keys-from-import`, {body: requestObj});
    }

    /**
     * Call service API to bulk update imported keys
     *
     * @param keysToUpdate
     * @param processId
     * @param loggedInAlias
     */
    static bulkUpdateKeysFromImport(keysToUpdate, processId, loggedInAlias) {
        const requestObj = {processId: processId, keys: keysToUpdate, loggedInAlias: loggedInAlias};
        return Amplify.API.post("ApproverMatrixAPI", `/keys/bulk-update-keys-from-import`, {body: requestObj});
    }

}