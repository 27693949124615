import '@amzn/awsui-global-styles/polaris.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@mdi/font/css/materialdesignicons.min.css';

import Amplify, {Auth} from "aws-amplify";
import React, {useEffect, useState} from "react";
import Navigation from "./components/Navigation";
import {BrowserRouter} from "react-router-dom";
import LOCAL_CONFIG from "./stages/local";
import DEV_CONFIG from "./stages/dev";
import ALPHA_CONFIG from "./stages/alpha";
import INTEG_CONFIG from "./stages/integ";
import PROD_CONFIG from "./stages/prod";
import {getOrSaveUser} from "./components/common/Constants";

/**
 * Main application, what is loaded by index.js
 * @returns {JSX.Element}
 * @constructor
 */
export default function App() {
    const [config, setConfig] = useState(null);
    // state variables for whether app is loading and the user object
    const [user, setUser] = useState(null);
    const [alias, setAlias] = useState('');

    const [loadingUser, setLoadingUser] = useState(true);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchConfig = async () => {
            if (process.env.NODE_ENV === "development") {
                // do not change. Modify your .env.local file instead
                // see https://create-react-app.dev/docs/adding-custom-environment-variables/
                setConfig(LOCAL_CONFIG);
            } else {
                fetch("/settings.json").then(resp => resp.json()).then(data => {
                    if (data.stage === "prod") {
                        setConfig(PROD_CONFIG);
                    } else if (data.stage === "alpha") {
                        setConfig(ALPHA_CONFIG);
                    } else if(data.stage === "integ") {
                        setConfig(INTEG_CONFIG);
                    } else {
                        setConfig(DEV_CONFIG);
                    }
                })
            }
        };
        fetchConfig();
    }, []);

    useEffect(() => {
        if (config) {
            Amplify.configure({
                Auth: {
                    mandatorySignId: true,
                    region: "us-west-2",
                    userPoolId: config.REACT_APP_USER_POOL_ID,
                    userPoolWebClientId: config.REACT_APP_USER_POOL_WEB_CLIENT_ID,
                },
                oauth: {
                    domain: config.REACT_APP_COGNITO_DOMAIN, // check domain name under app integration
                    redirectSignIn: config.REACT_APP_REDIRECT_URL, // this should be added in the appclient settings as well
                    redirectSignOut: config.REACT_APP_REDIRECT_URL,
                    scope: ['openid'], // important
                    responseType: 'code'
                },
                API: {
                    endpoints: [
                        {
                            name: "ApproverMatrixAPI",
                            endpoint: config.REACT_APP_API_ENDPOINT,
                            custom_header: async () => {
                                return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                            }
                        }
                    ]
                }
            });
        }
    }, [config]);

    // method responsible for taking cognito user information and updating the user alias
    const parseAndUpdateUser = (cognitoUserData) => {
        const idToken = cognitoUserData.getSignInUserSession().getIdToken()
        const userId = idToken.payload['identities'][0].userId
        setAlias(userId);
        getOrSaveUser(userId, (response) => {
                setUser(response);
                setLoadingUser(false);
            }, (response) => {
                setUser(response.user);
                setLoadingUser(false);
            }, () => setLoadingUser(false));
    };

    // method to log in user (checks if authenticated, if not prompts midway auth)
    useEffect(() => {
        if (config) {
            Auth.currentAuthenticatedUser()
                .then(cognitoUserData => {
                    setIsLoading(false)
                    parseAndUpdateUser(cognitoUserData)
                })
                .catch((err) => {
                    Auth.federatedSignIn({customProvider: 'AmazonFederate'})
                })
        }
    }, [config])

    return (
        <BrowserRouter>
            {!isLoading && user && alias !== "" ? <Navigation user={user} alias={alias} loadingUser={loadingUser}/> : null}
            {!isLoading && !user && alias !== "" ?<Navigation alias={alias} loadingUser={loadingUser}/> : null}
        </BrowserRouter>

    );
}