export const flattenKeys = (keys, attributes) => {
    let items = [];
    for (const key of keys) {
        let item = {};
        Object.values(attributes).forEach((attribute, idx) => {
            item[attribute.attributeName.toLowerCase()] =
                key.keyCriteria[attribute.attributeId] !== undefined ? key.keyCriteria[attribute.attributeId] : '';
        });
        item['keyId'] = key.keyId;
        item['assignee'] = key.keyAssignee;
        item['assigneeName'] = key.keyAssigneeName;
        item['assigneeActive'] = key.isKeyAssigneeActive;
        key.keyDelegates.forEach((delegate, rank) => {
            item['delegate' + (delegate.rank)] = delegate.alias;
            item['delegate' + (delegate.rank) + 'Name'] = delegate.name;
            item['delegate' + (delegate.rank) + 'Active'] = delegate.isActive;
        });
        items.push(item);
    }
    return items;
};

/**
 * Function to validate the character composition of the  name
 * Only alpha numeric and spaces are allowed
 */
export const validateNameCharacters = (name) => {
    const regex = /[A-Za-z0-9\s]/g;
    if(name.trim().length === 0 || (!name.match(regex) || name.match(regex).length !== name.length)) {
        return false;
    }
    return true;
};