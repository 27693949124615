import {Amplify} from "aws-amplify";

export class FilterService {

    /**
     * Call service API to save a single filter
     *
     * @param requestObj
     */
    static saveFilter(requestObj) {
        return Amplify.API.post("ApproverMatrixAPI", `/filter`, requestObj);
    }

    /**
     * Call service API to delete a filter
     *
     * @param requestObj
     */
    static deleteFilter(requestObj) {
        return Amplify.API.del("ApproverMatrixAPI", `/filter`, {body: requestObj});
    }

    /**
     * Call service API to fetch all filter
     *
     * @param processId
     * @return list of filters
     */
    static getAllFilters(processId) {
        return Amplify.API.get("ApproverMatrixAPI", `/filters?processId=${processId}`);
    }
}