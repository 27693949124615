import { Button, Input, Select } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { applyBlueThemeToButton } from '../common/Utils';
import { ASSIGNEE, DELEGATE_1, DELEGATE_2, EDIT_TEXT, REMOVE_TEXT } from './Constants';

const AddAttribute = (props) => {
    const [selectedAttributeLabel, setSelectedAttributeLabel] = useState({label: ASSIGNEE, value: ASSIGNEE});
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [enableAddButton, setEnableAddButton] = useState(false);
    const bulkEditOptions = props.bulkEditOptions ?? [];
    const actionOptions = props.actionOptions;
    const attributes = props.attributes;
    const bulkEditAttributes = props.bulkEditAttributes;
    const setBulkEditAttributes = props.setBulkEditAttributes;

    /**
     * Generates the labels of attributes which have not been added so far
     */
    const generateAttributeLabels = () => {
        const chosenLabels = bulkEditAttributes?.map(attribute => attribute.label);
        return bulkEditOptions.filter(option => !chosenLabels.includes(option.label));
    };

    /** 
     * Generates the Attribute Value field based on the selected attribute label
     * ASSIGNEE: Enter New Alias: Input
     * DELEGATE: Select Action: Dropdown. If Action equals 'Edit', then render Enter New Alias Input field
     * Process Attributes: Select Value: Dropdown.
    */
    const generateAttributeValueField = () => {
        if(!selectedAttributeLabel) return null;
        if(selectedAttributeLabel.label === ASSIGNEE) {
            return (
                <div className='addAttribute-value-selection-wrapper'>
                    <span className='addAttribute-select-attribute-title-wrapper'>New Alias</span>
                    <div>
                        <Input value={selectedValue} onChange={({detail}) => {
                            setSelectedValue(detail.value);
                            if(detail.value) setEnableAddButton(true);
                            else setEnableAddButton(false);
                        }} />
                    </div>
                </div>
            );
        }
        else if(selectedAttributeLabel.label === DELEGATE_1 || selectedAttributeLabel.label === DELEGATE_2) {
            return (
                <>
                    <div className='addAttribute-value-selection-wrapper'>
                        <span className='addAttribute-select-attribute-title-wrapper'>Action</span>
                        <div id="addAttribute-value-selection-field-wrapper" className='addAttribute-value-selection-field-wrapper'>
                            <Select options={actionOptions} selectedOption={selectedAction}
                                onChange={event => {
                                    const selectedOption = event.detail.selectedOption;
                                    if(selectedOption.label === REMOVE_TEXT) {
                                        setEnableAddButton(true);
                                        setSelectedValue(null);
                                    }
                                    else setEnableAddButton(false);
                                    setSelectedAction(selectedOption);
                                }} />
                        </div>
                    </div>
                    {
                        selectedAction && selectedAction.value === EDIT_TEXT &&
                        <div className='addAttribute-value-selection-wrapper'>
                            <span className='addAttribute-select-attribute-title-wrapper'>New Alias</span>
                            <div id="addAttribute-value-selection-field-wrapper" className='addAttribute-value-selection-field-wrapper'>
                                <Input value={selectedValue} onChange={({detail}) => {
                                    setSelectedValue(detail.value)
                                    if(detail.value) setEnableAddButton(true);
                                    else setEnableAddButton(false);
                                }} />
                            </div>
                        </div>
                    }
                </>
            );
        }
        else {
            return (
                <div className='addAttribute-value-selection-wrapper'>
                    <span className='addAttribute-select-attribute-title-wrapper'>Value</span>
                    <div id="addAttribute-value-selection-field-wrapper" className='addAttribute-value-selection-field-wrapper'>
                        <Select options={(() => {
                                const selectedAttributeId = Object.keys(attributes)
                                    ?.filter(attributeId => attributes[attributeId]?.attributeName === selectedAttributeLabel.label)?.at(0);
                                if(!selectedAttributeId) return [];
                                return attributes[selectedAttributeId]?.attributeValues?.sort().map(value => ({label: value, value: value}));
                            })()}
                            filteringType='auto' 
                            selectedOption={selectedValue}
                            onChange={({detail}) => {
                                setSelectedValue(detail.selectedOption);
                                setEnableAddButton(true);
                            }} />
                    </div>
                </div>
            );
        }
    };

    /**
     * Add's the selected attribute along with its value to the Update Request queue
     */
    const handleAttributeAdd = () => {
        //Update the global object
        setBulkEditAttributes(attributes => {
           const newAttribute = {label: selectedAttributeLabel.value} 
            if(selectedAction) newAttribute.action = selectedAction.value;
            if(selectedValue) newAttribute.value = selectedValue?.value ?? selectedValue;
            return [...attributes, newAttribute];
        });

        //Reset state
        setSelectedAttributeLabel(null);
        setSelectedAction(null);
        setSelectedValue(null);
        setEnableAddButton(false);
    };

    return (
        <div id='addAttribute' className='addAttribute'>
            <div id='addAttribute-select-attribute' className='addAttribute-select-attribute'>
                <span className='addAttribute-select-attribute-title-wrapper'>Add Attribute</span>
                <div id="addAttribute-select-attribute-selection-wrapper" className='addAttribute-select-attribute-selection-wrapper'>
                    <Select options={generateAttributeLabels()} selectedOption={selectedAttributeLabel} 
                        filteringType='auto'
                        onChange={event => {
                            setSelectedAttributeLabel(event.detail.selectedOption);
                            setEnableAddButton(false);
                            setSelectedValue(null);
                            setSelectedAction(null);
                        }} />
                </div>
            </div>
            {
                selectedAttributeLabel && generateAttributeValueField() 
            }
            <div id='addAttribute-add-button' className='addAttribute-add-button'>
                <Button disabled={!enableAddButton} onClick={handleAttributeAdd} className={applyBlueThemeToButton(enableAddButton)}>
                    Select Attribute
                </Button>
            </div>
        </div>
    );
};

export default AddAttribute;