import {Amplify, API} from "aws-amplify";

export class UserService {

    /**
     * Call service API to validate a single user
     *
     * @param userAlias
     */
    static validateUser(userAlias) {
        return Amplify.API.post("ApproverMatrixAPI", `/user/validate-user/${userAlias}`);
    }

    /**
     * Call service API to get active user details
     *
     * @param username
     * @return {*}
     */
    static getUser(username) {
        return Amplify.API.get("ApproverMatrixAPI", `/users/${username}`);
    }

    /**
     * Call service API to get active or inactive user details
     *
     * @param username
     * @return {*}
     */
    static getActiveInactiveUser(username) {
        return Amplify.API.get("ApproverMatrixAPI", `/activeinactiveusers/${username}`);
    }

    /**
     * Call service API to save user details
     *
     * @param username
     * @return {*}
     */
    static saveUser(username) {
        return API.post("ApproverMatrixAPI", `/users`, {body: {userAlias: username, alias: username, devAdmin:false}})
    }
}