import { Alert, Box, Button, Container as DialogContainer, ExpandableSection, FormField, Grid, Header, Input, Select } from "@amzn/awsui-components-react";
import { CircularProgress } from "@material-ui/core";
import { Amplify } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../assets/css/components/AttributeFilter.css";
import "../../assets/css/components/BulkEdit.css";
import "../../assets/css/components/Keys.css";
import "../../assets/css/components/Process.css";
import { FilterService } from "../../service/FilterService";
import { KeyService } from "../../service/KeyService";
import { UserService } from "../../service/UserService";
import AdditionalInfo from "../AdditionalInfo";
import ConfirmationDialog from "../ConfirmationDialog";
import FilteredKeysTable from "../FilteredKeysTable";
import MultiAttributeFilter from "../MultiAttributeFilter";
import { ACTIVITY_TYPE_ATTR_VALUES_UPDATE } from "../common/Constants";
import { flattenKeys, validateNameCharacters } from "../common/KeyUtils";
import { applyBlueThemeToButton, verifyUpdateReasonSelected } from "../common/Utils";
import AddAttribute from "./AddAttribute";
import AddedAttribute from "./AddedAttribute";
import { ASSIGNEE, DELEGATE_1, DELEGATE_2 } from "./Constants";
import DeleteKeysDialogContent from "./DeleteKeysDialogContent";
import UpdateMatrixConfirmationDialogContent from "./UpdateMatrixConfirmationDialogContent";


/**
 * Page that loads when you try to create a new process or edit an existing process
 *
 * @param props properties needed to render the page
 * - mode: whether we are creating or editing a processs
 * - processId: the process id that is selected ,empty for a new process
 * - user: the user who is logged in
 * - attributes: list of all attributes for given process
 * @returns {JSX.Element}
 * @constructor
 */
export default function BulkKeyEditView(props) {

    const attributes = props.attributes;
    const user =props.user;
    const process = props.process;
    const updateMessage = props.updateMessage;
    const [keys,setKeys] = useState([]);
    const [relevantKeys, setRelevantKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userFilter,setUserFilter] = useState(user.userAlias);
    const [aliasError, setAliasError] = useState("");
    const [generateTable,setGenerateTable] = useState(false);
    const [generateButton,setGenerateButton] = useState(true);
    const [generateRMLoading, setGenerateRMLoading] = useState(false);
    const [userType,setUserType] = useState({label: 'All', value: 'ALL'});
    const [selectedFilter,setSelectedFilter] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterNameError, setFilterNameError] = useState("");
    const [expandSelectionCriteria, setExpandSelectionCriteria] = useState(true);

    const [userLoaded,setUserLoaded] = useState(false);
    const [keyOptions,setKeyOptions] = useState([]);
    const [filterOptions,setFilterOptions] = useState([]);

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const confirmationDialogContent = useRef(null);
    const [keySelectionCriteria, setKeySelectionCriteria] = useState({});
    const [mandatoryAttributesMissing, setMandatoryAttributesMissing] = useState(false);
    const [criteriaOverflowError, setCriteriaOverflowError] = useState(false);
    const [receiveNotification, setReceiveNotification] = useState(user.devAdmin ||process.processAdmins.includes(user.userAlias)?false:true);
    const [userStatus, setUserStatus]= useState({label: 'Both', value: 'BOTH'});
    const [bulkEditAttributes, setBulkEditAttributes] = useState([]);

    const DELETE_DELEGATE_TEXT = "DELETE_DELEGATE";
    const simTicketIdRef = useRef("");
    const notesRef = useRef("");
    const updateReasonRef = useRef("");
    const [updateReason, setUpdateReason] = useState("");
    const NOT_AVAILABLE_TEXT = "Not Available";
    const NOT_APPLICABLE_TEXT = "Not Applicable";
    const DUMMY_VALUES = new Set([NOT_APPLICABLE_TEXT,NOT_AVAILABLE_TEXT]);

    const CONFIRM_ACTION_CONSTANTS = {
        SAVE_FILTER: 'save filter',
        DELETE_FILTER: 'delete filter',
        BULK_UPDATE_KEYS:'bulk update',
        BULK_DELETE_KEYS:'bulk delete'
    };

    const MANDATORY_ATTRIBUTES = new Set(['Product Family Type', 'Product Line']);
    const MANDATORY_ATTRIBUTE_IDS =
        Object.entries(attributes)
            .filter(([key, value]) => MANDATORY_ATTRIBUTES.has(value.attributeName))
            .map(([key, value]) => value.attributeId);

    let bulkEditOptions = [];
    bulkEditOptions.push({label: 'Assignee', value: 'Assignee'});
    bulkEditOptions.push({label: 'Delegate 1', value: 'Delegate 1'});
    bulkEditOptions.push({label: 'Delegate 2', value: 'Delegate 2'});


    if (user.devAdmin  || process.processAdmins.includes(user.userAlias)) {
        Object.values(attributes).forEach(elem => {
            bulkEditOptions.push({label: elem.attributeName, value: elem.attributeName});
        });
    }

    const initialEdit =[];
    initialEdit.push(bulkEditOptions[0]);

    //const [selectedBulkEditLabels,setSelectedBulkEditLabels] = useState(initialEdit);

    let userTypeOptions = [];
    userTypeOptions.push({label: 'Assignee', value: 'ASSIGNEE'});
    userTypeOptions.push({label: 'Delegate 1', value: 'DELEGATE1'});
    userTypeOptions.push({label: 'Delegate 2', value: 'DELEGATE2'});
    userTypeOptions.push({label: 'All', value: 'ALL'});

    let userStatusOptions = [];
    userStatusOptions.push({label: 'Active', value: 'ACTIVE'});
    userStatusOptions.push({label: 'Inactive', value: 'INACTIVE'});
    userStatusOptions.push({label: 'Both', value: 'BOTH'});


    let actionOptions = [];
    actionOptions.push({label: 'Remove', value: 'Remove'})
    actionOptions.push({label: 'Edit', value: 'Edit'})

    useEffect(()=>{
        setKeyOptions(
            Object.values(attributes).map(attribute => ({label: attribute.attributeName,
                value: attribute.attributeId,
                attributeValues:attribute.attributeValues.map(elem => ({label: elem, value: elem})).sort((a, b) =>
                    {
                        let l =  a.value.toLowerCase(), r = b.value.toLowerCase();

                        if (l < r) { return -1; }
                        if (l > r) { return 1; }
                        return 0;
                    }
                ),
                selectedAttributes:[],
                selectedValues:[],
                coreAttribute:attribute.coreAttribute
            })));
    },[attributes]);

    const attributeIDNameMap = {};
    Object.values(attributes).forEach(attribute =>  attributeIDNameMap[attribute.attributeName] = attribute.attributeId);

    useEffect(() => {
         setLoading(true);
         setUserLoaded(true);

        getAllFilters();

        if (user.userAlias !== undefined && user.userAlias !== null) {
            let aliasValue;
            if(DUMMY_VALUES.has(user.userAlias))
                aliasValue = user.userAlias;
            else aliasValue =user.userAlias.toLowerCase();
            KeyService.getMatchingKeysForUserAndProcess(aliasValue, process.processId,userType.value, userStatus.value).then(
                response => {
                    setKeys(response.keys);
                    setAliasError("");
                    setUserLoaded(true);
                    setLoading(false);
                    filterKeys(response.keys);
                    setGenerateButton(true);
                    setGenerateTable(false);
                }
            ).catch(err => {
                setGenerateTable(false);
                setGenerateButton(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for user' + user.userAlias + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for user." + user.userAlias, "error")
            });
        } else {
            KeyService.getMatchingKeysForCriteriaAndProcess(keySelectionCriteria, process.processId).then(
                response => {
                    setKeys(response.keys);
                    setAliasError("");
                    setUserLoaded(true);
                    setLoading(false);
                    setGenerateTable(false);
                    setRelevantKeys(flattenKeys(response.keys, attributes));
                    setGenerateTable(false);
                    setGenerateButton(true);
                    setLoading(false);
                }
            ).catch(err => {
                setGenerateButton(false);
                setUserLoaded(false);
                setGenerateTable(false);
                setLoading(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for process' + process.processId + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for process." + process.processId, "error")
            });
        }// eslint-disable-next-line
     }, [process,user]);


    const deleteFilter = () => {

        FilterService.deleteFilter(selectedFilter.value).then(
            response => {
                setSelectedFilter("");
                getAllFilters();
                handleDialogClose();
            })
            .catch(err => {
                let error = err?.response?.data?.errorMessage ? err.response.data.errorMessage :err.response.data.message

                updateMessage('Unable to delete filter' + error, "error");
            });

    };

    //Fetching all selected criteria as a mapto save filter
    const retrieveCriteriaAttributeValuesToSave = () => {
        const criteria = {};
        for(const [, value] of Object.entries(keyOptions)) {


            if( value.selectedAttributes!== undefined && value.selectedAttributes.length!==0){
                criteria[value.value] = [];
                value.selectedAttributes.forEach(attributeOptions => {
                    criteria[value.value].push(attributeOptions.value);
                });
            }

        }
        return criteria;
    };

    const saveFilter = () => {
        if(!validateNameCharacters(filterName)) {//Validate the composition of draft name characters
            if(filterName.trim().length === 0) {
                setFilterNameError("Filter name cannot be empty");
                updateMessage("Filter name cannot be empty", "error");
            }
            else {
                setFilterNameError("Filter name should only contain alphanumerics or spaces");
                updateMessage("Filter name should only contain alphanumerics or spaces", "error");
            }
            return;
        }
        if(filterOptions.length === 10)
        {
            setFilterNameError("You cannot have more than 10 filters for a process.");
            updateMessage("You cannot have more than 10 filters for a process.", "error");
            return;
        }
        else {
            setFilterNameError("");
        }
        let filterCriteria = retrieveCriteriaAttributeValuesToSave();
        const requestObj = {
            body: {
                processId:process.processId,
                filterName: filterName.trim(),
                filterCriteria:filterCriteria,
                active:true
            }
        };
        FilterService.saveFilter(requestObj).then(
            response => {
                setSelectedFilter("");
                getAllFilters();
                handleDialogClose();
            })
            .catch(err => {

                let error = err?.response?.data?.errorMessage ? err.response.data.errorMessage :err.response.data.message

                setFilterNameError(error);
                updateMessage( error, "error");
            });
    };

    const getAllFilters = () => {
        FilterService.getAllFilters(process.processId).then(
            response => {
                 setFilterOptions(
                    Object.values(response.filters).map(filter => ({label: filter.filterName,
                        value: filter
                    })));
            }).catch(err => {

                updateMessage('Unable to fetch filters for the process' + err.response.data.message, "error");
            });
    };

    /**
     * Function to pop up the confirmation dialog for deleting  keys
     */
    const confirmDeleteKeys = () => {
        if(!verifyUpdateReasonSelected(updateReason, updateMessage)) {
            return;
        }
        confirmationDialogContent.current = CONFIRM_ACTION_CONSTANTS.BULK_DELETE_KEYS;
        setOpenConfirmationDialog(true);
    };

    /**
     * Function to pop up the confirmation dialog for bulk edit keys
     */
    const confirmBulkUpdateKeys = () => {
        confirmationDialogContent.current = CONFIRM_ACTION_CONSTANTS.BULK_UPDATE_KEYS;
        setOpenConfirmationDialog(true);
    };

    /**
     * Handler to update the change in filter name input
     */
    const handleFilterNameChange = (event) => {
        setFilterName(event.detail.value.toUpperCase());
    };

    const updateUserFilter = () => {
        setGenerateButton(false);
        setGenerateRMLoading(true);
        if(DUMMY_VALUES.has(userFilter))
        {
            setAliasError("");
            setUserLoaded(true);
            setGenerateTable(false);
            setGenerateButton(false);
            getUserSpecificKeys(userFilter);
        } else if (userFilter !== undefined && userFilter !== null && userFilter !== "") {
            UserService.getActiveInactiveUser(userFilter.toLowerCase()).then(
                response => {
                    setAliasError("");
                    setUserLoaded(true);
                    setUserFilter(response.userAlias);
                    setGenerateTable(false);
                    getUserSpecificKeys(response.userAlias);
                })
                .catch(err => {
                    setGenerateButton(true);
                    setGenerateRMLoading(false);
                    setUserLoaded(false);
                    setGenerateTable(false);
                    setAliasError(`Unable to find alias ${userFilter} in system.`);
                });
        } else {
            setAliasError("");
            setUserLoaded(true);
            setGenerateTable(false);

            let callSearchApi = true;
            for (const attrId of MANDATORY_ATTRIBUTE_IDS) {
                if ((attrId in keySelectionCriteria && keySelectionCriteria[attrId].length !== 1) || !(attrId in keySelectionCriteria)){
                    setMandatoryAttributesMissing(true);
                    setGenerateButton(false);
                    setGenerateRMLoading(false);
                    setGenerateTable(false);
                    callSearchApi = false;
                    break;
                }
            }

            let totalCombinationCount = 1;
            Object.entries(keySelectionCriteria).forEach(([k,v]) => {
                totalCombinationCount = totalCombinationCount * v.length;
            });

            if (totalCombinationCount > 150000) {
                setCriteriaOverflowError(true);
                callSearchApi = false;
            }

            if (callSearchApi) {
                getUserSpecificKeys(null);
            }
        }
    };

    const resetMandatoryAttributesSelectionError = () => {
        setCriteriaOverflowError(false);
        setMandatoryAttributesMissing(false);
        setGenerateButton(true);
    };

    // function that pulls all the keys when this page is loaded
    const getUserSpecificKeys = (alias)=> {
        setLoading(true);

        // get keys under user alias
        if (alias !== undefined && alias !== null && alias !== "") {
            let aliasValue;
            if(DUMMY_VALUES.has(alias))
                aliasValue = alias;
            else aliasValue =alias.toLowerCase();
            KeyService.getMatchingKeysForUserAndProcess(aliasValue, process.processId, userType.value, userStatus.value).then(
                response => {
                    setKeys(response.keys);
                    setLoading(false);
                    setGenerateTable(true);
                    filterKeys(response.keys)
                    setGenerateButton(true);
                    setGenerateRMLoading(false);
                    setExpandSelectionCriteria(false);
                }
            ).catch(err => {
                setGenerateButton(true);
                setGenerateRMLoading(false);
                setUserLoaded(false);
                setGenerateTable(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for user' + alias + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for user." + alias, "error")
            });
        // get keys without user alias
        } else {
            KeyService.getMatchingKeysForCriteriaAndProcess(keySelectionCriteria, process.processId).then(
                response => {
                    setKeys(response.keys);
                    setLoading(false);
                    setRelevantKeys(flattenKeys(response.keys, attributes));
                    setGenerateTable(true);
                    setGenerateButton(true);
                    setGenerateRMLoading(false);
                }
            ).catch(err => {
                setGenerateButton(true);
                setGenerateRMLoading(false);
                setUserLoaded(false);
                setGenerateTable(false);
                setLoading(false);
                err?.response?.data?.errorMessage ?
                    updateMessage('Unable to fetch keys for process' + process.processId + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to fetch keys for process." + process.processId, "error")
            });
        }
    };

    //Filter Keys
    const filterKeys = (response= keys) => {
        let result = response;
        setLoading(true);

       //Filtering Keys according the key filter criterias provided
        let filteredOptions =
            keyOptions.filter(option => option.selectedAttributes!== undefined && option.selectedAttributes.length !== 0);
        filteredOptions.forEach(opt => {
                  result = result.filter(key =>
                      ((key.keyCriteria)[opt.value] !== undefined) &&
                      (Object.values(opt.selectedAttributes).map(v => v.label)).includes(key.keyCriteria[opt.value])
                  )
        });

        setRelevantKeys(flattenKeys(result, attributes));
        setGenerateTable(true);
        setLoading(false);
    };

    const reloadPageOnSuccessfulBulkEdit = ()=> {
        //Reload keys for successful edit

        // user alias not provided
        if(userFilter === null || userFilter === ""){
            setAliasError("");
            setUserLoaded(true);
            setUserFilter(userFilter);
            setGenerateTable(false);
            setGenerateRMLoading(true);
            // get newly edited keys
            getUserSpecificKeys(userFilter);
        }
        // user alias provided
        else {
            UserService.getActiveInactiveUser(userFilter.toLowerCase()).then(
                response => {
                    setAliasError("");
                    setUserLoaded(true);
                    setUserFilter(response.userAlias);
                    setGenerateTable(false);
                    setGenerateRMLoading(true);
                    // get newly edited keys
                    getUserSpecificKeys(response.userAlias);
                })
                .catch(err => {
                    setGenerateButton(false);
                    setUserLoaded(false);
                    setGenerateTable(false);
                    setAliasError(`Unable to find alias ${userFilter} in system.`);
                });
        }

    };

    /**
     * Fires an API to delete the generated keys
     * @returns true if the operation is a success, false otherwise
     */
    const bulkDeleteKeys = async () => {
        let requestObj = {
            processId: process.processId,
            loggedInAlias: user.userAlias,
            updateReason: updateReason,
            simTicketId: simTicketIdRef.current,
            notes: notesRef.current,
            keys: relevantKeys.map(key => key.keyId)
        };
        try {
            const response = await KeyService.bulkDeleteKeys(requestObj);
            reloadPageOnSuccessfulBulkEdit();
            setUpdateReason("");
            simTicketIdRef.current = "";
            notesRef.current = "";
            return {
                status: true,
                successfulKeys: response?.deleteSuccessfulKeys?.keys?.length ?? 0,
                failedKeys: response?.deleteFailedKeys?.keys?.length ?? 0
            }
        }
        catch(err) {
            return {
                status: false,
                errorMessage: err?.response?.data?.errorMessage ?? ''
            }
        }
    };

    /**
     * Fires an API to update the generated keys
     * @returns true if the operation is a success, false otherwise
     */
    const bulkEditKeys = async () => {
        let requestObj = {
            processId: process.processId,
            loggedInAlias: user.userAlias,
            keys: relevantKeys.map(key => key.keyId),
            receiveNotification:receiveNotification,
            updateReason: updateReason,
            simTicketId: simTicketIdRef.current,
            notes: notesRef.current
        };
       let criteriaToUpdate = {};

       for(const bulkEditAttribute of bulkEditAttributes) {
           if(bulkEditAttribute.label === ASSIGNEE) {
                requestObj['assignee'] = bulkEditAttribute.value.trim().toLowerCase();
           }
           else if(bulkEditAttribute.label === DELEGATE_1 || bulkEditAttribute.label === DELEGATE_2) {
                const key = bulkEditAttribute.label === DELEGATE_1 ? 'delegate1' : 'delegate2';
                const rank = bulkEditAttribute.label === DELEGATE_1 ? 1 : 2;
                if(bulkEditAttribute.value) { //Edit action, as 'value' is present
                    requestObj[key] = {
                        alias: bulkEditAttribute.value.trim().toLowerCase(),
                        rank: rank,
                        confirmationStatus: "CONFIRMED"
                    };
               }
                else if(bulkEditAttribute.action) { //Remove action
                    requestObj[key] = {
                        alias: DELETE_DELEGATE_TEXT,
                        name:"",
                        rank: rank,
                        confirmationStatus: "CONFIRMED"
                    };
               }
           }
           else {
               criteriaToUpdate[attributeIDNameMap[bulkEditAttribute.label]] = bulkEditAttribute.value;
           }
       }

        //Only passing criteria when any criteria is selected to update. Passing empty criteria causes failure of permission service
        if(Object.keys(criteriaToUpdate).length !== 0){
            requestObj['criteriaToUpdate'] = criteriaToUpdate;
        }
        try {
            const response = await KeyService.bulkUpdateKeys(requestObj);
            setBulkEditAttributes([]);
            reloadPageOnSuccessfulBulkEdit();
            setUpdateReason("");
            simTicketIdRef.current = "";
            notesRef.current = "";
            return {
                status: true,
                successfulKeys: response?.updateSuccessfulKeys?.keys?.length ?? 0,
                failedKeys: response?.updateFailedKeys?.keys?.length ?? 0
            }
        }
        catch(err) {
                return {
                    status: false,
                    errorMessage: err?.response?.data?.errorMessage ?? ''
                };
        }
    };


    const clearAllFilters = () =>{
        setMandatoryAttributesMissing(false);
        setSelectedFilter("");
        setKeyOptions(
            Object.values(attributes).map(attribute => ({label: attribute.attributeName,
                value: attribute.attributeId,
                attributeValues:attribute.attributeValues.map(elem => ({label: elem, value: elem})).sort((a, b) =>
                {
                    let l =  a.value.toLowerCase(), r = b.value.toLowerCase();
                    if (l < r) { return -1; }
                    if (l > r) { return 1; }
                    return 0;
                }
                ),
                selectedAttributes:[],
                selectedValues:[],
                coreAttribute:attribute.coreAttribute
            })));
    };

    /**
     * Function to retrieve the current confirmation dialog content based on the action selected by the user
     */
    const generateConfirmationDialogContent = () => {
        switch(confirmationDialogContent.current) {
            case CONFIRM_ACTION_CONSTANTS.SAVE_FILTER: return generateSaveFilterDialogContent();
            case CONFIRM_ACTION_CONSTANTS.DELETE_FILTER: return generateDeleteFilterContent();

            default: return null;
        }
    };

    /**
     * Function to generate the content for the confirmation dialog in the case of save filter
     * action selected by the user
     */
    const generateFilterNameContent = () => {
        return (
            <Row  className="p-1 mb-3 justify-content-md-center row-control draft-name-wrapper">
                <Col lg={2}
                     className={`d-flex draft-name-label-wrapper`}>
                    <FormField className="pt-2 px-2"
                               label="Filter Name"/>
                </Col>
                <Col lg={2} className={`justify-content-center draft-name-input-wrapper`}>
                    <Input id="criteria-filter-name-input"
                           type='text'
                           className = "pt-2 px-4 justify-content-md-left"
                           value = {filterName}
                           onChange = {handleFilterNameChange}
                           autoComplete = {false}/>
                </Col>
                <Col lg={1} className={`justify-content-center pt-1 draft-name-error-wrapper`}>
                    <FormField id= "username-filter-error-label "
                               className="pt-2 px-2 fw-bold error-label"
                               errorText={filterNameError}/>
                </Col>
            </Row>
        );
    };

    /**
     * Function to generate the content for the confirmation dialog in the case of delete filter
     * action selected by the user
     */
    const generateDeleteFilterContent = () => {
        return (
            <DialogContainer>
                <Box className="delete-draft-title-wrapper">
                    <h4>Do you want to delete the saved filter?</h4>
                </Box>
                <Grid className="py-5 justify-content-md-center"  gridDefinition={[{ colspan: 5 }, { colspan: 6 }]}>
                    <div  className="d-flex justify-content-center">
                        <Button id="bulk-edit-confirm-delete-filter-button"
                                onClick={()=> {
                                    deleteFilter();
                                    setSelectedFilter("");
                                }}
                                className="p-2 save-draft-button keysButton"
                        >Confirm</Button>
                    </div>
                    <div  className="d-flex justify-content-center">
                        <Button id="bulk-edit-cancel-delete-filter-button"
                                onClick={()=> handleDialogClose()}
                                variant="normal"
                                className="p-2 editButton keysButton cancel-button-wrapper"
                        >Cancel</Button>
                    </div>
                </Grid>
            </DialogContainer>
        );
    };

    /**
     * Function to generate the filter name content for the confirmation dialog.
     */
    const generateSaveFilterDialogContent = () => {
        return (
            <DialogContainer
                header={<Header variant="h2">Save Filter?</Header>}
            >
                {generateFilterNameContent()}
                <Grid className="py-5 justify-content-md-center"  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <div className="d-flex justify-content-center">
                        <Button id="bulk-edit-save-filter-button"
                                onClick={()=> saveFilter()}
                                className="p-2 save-draft-button keysButton"
                        >Save</Button>
                    </div>
                    <div  className="d-flex justify-content-center">
                        <Button id="bulk-edit-cancel-filter-button"
                                onClick={()=> handleDialogClose()}
                                variant="normal"
                                className="p-2 editButton keysButton cancel-button-wrapper"
                        >Cancel</Button>
                    </div>
                </Grid>
            </DialogContainer>
        );
    };

    /**
     * Function to close the confirmation dialog
     */
    const handleDialogClose = () => {
        setOpenConfirmationDialog(false);
    };

    const asyncValidateBackgroundActivityAndAttributesNoCatch = async () => {
        let attributeList = Object.values(attributes);
        attributeList.forEach(attr => {attr.alias = user.userAlias});
        let result = await Amplify.API.post("ApproverMatrixAPI", "/background-activity", {
            body: {
                processId: process.processId,
                activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                attributeValidation: true,
                attributes: attributeList
            }
        });
        console.log(result);
        return !(result.existUnfinishedActivity || !result.attributeValuesMatched);
    }

    const makeValuestoDisplayInConfirmation = async function() {
        if(!verifyUpdateReasonSelected(updateReason, updateMessage)) {
            return;
        }
        confirmBulkUpdateKeys();
    }

    const handleClickBulkEditConfirmed = async ()=>{
        let passed = false;
        try{
            passed = await asyncValidateBackgroundActivityAndAttributesNoCatch();
            if (!passed){
                updateMessage("Unable to bulk edit keys because there are other admins editing this process! Please come back later and refresh page", "error");
                return {
                    status: false
                };
            }
        }catch(err){
            updateMessage("Unable to bulk edit keys because we cannot validate background activity and attribtues!", "error");
            console.log(err);
            return {
                status: false
            };
        }
        if (passed){
            return await bulkEditKeys();
        }
        return {
            status: false
        };
    }

    const handleClickBulkDeleteConfirmed = async ()=>{
        let passed = false;
        try {
            passed = await asyncValidateBackgroundActivityAndAttributesNoCatch();
            if (!passed){
                updateMessage("Unable to bulk delete keys because there are other admins editing this process! Please come back later and refresh page", "error");
                return {
                    status: false
                }
            }
        }catch(err){
            console.log(err);
            updateMessage("Unable to bulk delete keys because we cannot validate background activity and attribtues!", "error");
            return {
                status: false
            }
        }
        if (passed){
            return await bulkDeleteKeys();
        }
        return {
            status: false
        }
    }

    /**
     * Invokes Modal dialog for Bulk Update / Bulk Delete operations
     */
    const generateModalDialogContent = () => {
        if(!openConfirmationDialog) return;
        if(confirmationDialogContent.current === CONFIRM_ACTION_CONSTANTS.BULK_UPDATE_KEYS) {
            return  <UpdateMatrixConfirmationDialogContent bulkEditAttributes={bulkEditAttributes} handleClickBulkEditConfirmed={handleClickBulkEditConfirmed}
                        handleDialogClose={handleDialogClose} displayIssueNotification={user.devAdmin || process.processAdmins.includes(user.userAlias)}
                        receiveNotification={receiveNotification} setReceiveNotification={setReceiveNotification} relevantKeys={relevantKeys}
                    />
        }
        else if(confirmationDialogContent.current === CONFIRM_ACTION_CONSTANTS.BULK_DELETE_KEYS) {
            return <DeleteKeysDialogContent handleDialogClose={handleDialogClose} handleClickBulkDeleteConfirmed={handleClickBulkDeleteConfirmed} 
                    relevantKeys={relevantKeys}
                />
        }
    };

    return (
        <Container className="pt-4 justify-content-lg-center">

            <React.Fragment key="username-search-fragment">
                    <Row id="page-header-row"className="pb-4 justify-content-center ">
                        <Col lg={4} className="d-flex justify-content-center fw-bold px-lg-5">
                            <h1 id="page-header">Manage Responsibility Matrix</h1>
                        </Col>
                    </Row>

                    <Row id="username-filter-input-row" className="py-4 justify-content-center px-lg-2">
                        <Col lg={1} className="d-flex justify-content-end">
                            <FormField className="pt-3 fw-bold" label="User Alias: " />
                        </Col>
                        <Col  lg={1} className="justify-content-left bulk-edit-first-row-field">
                            <Input id= "username-filter-input-control"
                                   className="pt-2 pb-2 "
                                   type='text'
                                   onChange={({detail}) => {
                                       setUserLoaded(false);
                                       setUserFilter(detail.value);
                                       setExpandSelectionCriteria(true);
                                   }}
                                   value={userFilter}/>
                        </Col >
                        <Col  lg={1} className="d-flex justify-content-end">
                            <FormField className="pt-3 fw-bold" label="User Type: " />
                        </Col>
                        <Col lg={1} className="justify-content-left  bulk-edit-first-row-field">
                            <Select id="user-type-selection-control"
                                    placeholder="Not Specified"
                                    className="pt-2 pb-2"
                                     selectedOption={userType}
                                     options={userTypeOptions}
                                     filteringType="auto"
                                     onChange={(event) => {
                                         setUserLoaded(false);
                                         setUserType(event.detail.selectedOption);

                                     }}
                                     autoComplete={false}/>

                        </Col>
                        <Col  lg={1} className="d-flex justify-content-end">
                            <FormField className="pt-3 fw-bold" label="User Status: " />
                        </Col>
                        <Col lg={1} className="justify-content-left bulk-edit-first-row-field">
                            <Select id="user-type-selection-control"
                                    placeholder="Not Specified"
                                    className="pt-2 pb-2"
                                    filteringType="auto"
                                    selectedOption={userStatus}
                                    options={userStatusOptions}
                                    onChange={(event) => {
                                        setUserStatus(event.detail.selectedOption);
                                    }}
                                    autoComplete={false}/>
                        </Col>

                    </Row>
                    <Row id= "username-filter-error-row" className="justify-content-md-center mb-4">
                        <Col lg={3} className=" d-flex px-2 mx-2 d-flex justify-content-center ">
                            <FormField id= "username-filter-error-label"
                                       className="d-flex mx-4"
                                       errorText={aliasError} />
                        </Col>
                    </Row>
                </React.Fragment>

            <div className="selection-criteria-wrapper" id="selection-criteria-wrapper">
                <ExpandableSection header={<div className="selection-criteria-title-wrapper">
                            <h3 style={{fontWeight: "bold"}}>Selection Criteria</h3>
                        </div>}
                    onChange={() => setExpandSelectionCriteria(prevState => !prevState)}
                    expanded={expandSelectionCriteria}
                >
                    <React.Fragment key="criteria-filter-provider-fragment">
                        {
                            keyOptions.map((attribute,index) => {
                                return (
                                    <MultiAttributeFilter id="criteria-filter-div"
                                                        key={index}
                                                        attribute={attribute}
                                                        index={index}
                                                        keyOptions={keyOptions}
                                                        setSelectedFilter={setSelectedFilter}
                                                        setKeyOptions={setKeyOptions}
                                                        setSelectedAttributeValues={setKeySelectionCriteria}
                                                        resetMandatoryAttributesSelectionError={resetMandatoryAttributesSelectionError}
                                                        setGenerateTable={setGenerateTable}
                                    />
                                )
                            })
                        }

                        <Row id= "criteria-filter-selection-missing-row" className="py-2 justify-content-md-left">
                            <Col lg={10} className="d-flex justify-content-left px-3 fw-bold">
                                <Alert
                                    onDismiss={() => setMandatoryAttributesMissing(false)}
                                    visible={mandatoryAttributesMissing}
                                    dismissAriaLabel="Close alert"
                                    dismissible
                                    type="warning"
                                >
                                    Please specify exactly one value for each of these - {[...MANDATORY_ATTRIBUTES].join(', ')}.
                                </Alert>
                            </Col>
                        </Row>

                        <Row id= "criteria-filter-selection-missing-row" className="py-2 justify-content-md-left">
                            <Col lg={10} className="d-flex justify-content-left px-3 fw-bold">
                                <Alert
                                    onDismiss={() => setCriteriaOverflowError(false)}
                                    visible={criteriaOverflowError}
                                    dismissAriaLabel="Close alert"
                                    dismissible
                                    type="warning"
                                >
                                        Too many criteria combinations possible. Please refine your search.
                                </Alert>
                            </Col>
                        </Row>

                        <div id="generate-filter-table-action-row" className="selection-criteria-actions-wrapper">
                            <div>
                                <Button id="generate-filter-table-action-button"
                                        className={applyBlueThemeToButton(generateButton)}
                                        disabled={!generateButton}
                                        onClick={() => {
                                            updateUserFilter();
                                            setBulkEditAttributes([]);
                                        }
                                        }>Generate Responsibility Matrix</Button>
                            </div>
                            <div>
                                <Button
                                    id="reset-filter-table-action-button"
                                    className="reset-all-button-wrapper"
                                    onClick={() => {clearAllFilters()}}
                                >Reset All</Button>
                            </div>
                        </div>
                        {
                            generateRMLoading ?
                                <Row id="generateRM-spinner-row" className="justify-content-md-center" >
                                    <Col lg={4} className="mt-5 d-flex justify-content-center">
                                        <CircularProgress id="generateRM-spinner" className="ms-3 mb-1" size={40}/>
                                        <FormField id="generateRM-spinner-label"className="pt-3 px-2 fw-bold"
                                                label="Fetching keys..."></FormField>
                                    </Col>
                                </Row>:undefined
                        }
                    </React.Fragment>
                        
                </ExpandableSection>
            </div>

            <>
                <div key="filtered-table-fragment" id="bulkKeyEdit-keys-table" className="bulkKeyEdit-keys-table">
                    { generateTable && userLoaded ?
                      <FilteredKeysTable elementId="bulk-edit-"
                                         relevantKeys={relevantKeys}
                                         attributes = {attributes}
                                         enableExport={user.devAdmin || process.processAdmins.includes(user.userAlias)}
                                         loading={loading} />
                        : undefined

                    }
                </div>

                <React.Fragment key="bulk-key-edit-fragment">
                    {
                        userLoaded && generateTable && relevantKeys.length > 0 
                            && (user.devAdmin || (userFilter !== undefined && userFilter !== null && userFilter !== "" && user.userAlias === userFilter) || process.processAdmins.includes(user.userAlias)) &&
                             <div id="update-responsibility-matrix" className="update-responsibility-matrix">
                                <div id="update-responsibility-matrix-main-wrapper" className="update-responsibility-matrix-main-wrapper">
                                    <Row id="bulk-edit-section-header-row" className="py-4 justify-content-md-center px-lg-1"> 
                                        <Col lg={6} className="d-flex justify-content-center fw-bold update-responsibility-matrix-title"> 
                                            <h2 id="bulk-edit-section-header">Update Responsibility Matrix</h2>
                                        </Col>
                                    </Row>
                                    <div id="update-responsibility-matrix-add-wrapper" className="update-responsibility-matrix-add-wrapper">
                                        <p className="update-responsibility-matrix-add-wrapper-title" id="update-responsibility-matrix-add-wrapper-title">
                                            <h3>Attributes to Update</h3>
                                        </p>
                                        <AddAttribute bulkEditAttributes={bulkEditAttributes} setBulkEditAttributes={setBulkEditAttributes}
                                            attributes={attributes} actionOptions={actionOptions} bulkEditOptions={bulkEditOptions} />
                                    </div>
                                    <div id="update-responsibility-matrix-added-wrapper" className="update-responsibility-matrix-added-wrapper">
                                        {
                                            (bulkEditAttributes && bulkEditAttributes.length) ?
                                                <>
                                                    <p className="update-responsibility-matrix-added-wrapper-title" id="update-responsibility-matrix-added-wrapper-title">
                                                        <h3>Preview Attributes to Update ({bulkEditAttributes.length})</h3>
                                                    </p>
                                                    {
                                                        bulkEditAttributes.map(addedAttribute => 
                                                            <AddedAttribute key={addedAttribute.label} bulkEditAttribute={addedAttribute} setBulkEditAttributes={setBulkEditAttributes} />)
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                 <AdditionalInfo simTicketIdRef={simTicketIdRef} notesRef={notesRef} updateReasons={props.updateReasons}
                                    updateReasonRef={updateReasonRef} setUpdateReason={setUpdateReason} 
                                    hideNonMandatoryFields={!(user.devAdmin || process.processAdmins.includes(user.userAlias))}>
                                 </AdditionalInfo>

                                 <div id="update-responsibility-matrix-action-buttons-wrapper" className="update-responsibility-matrix-action-buttons-wrapper">
                                     <div>
                                         <Button id="bulk-edit-submit-button"
                                                 className={applyBlueThemeToButton((relevantKeys.length && bulkEditAttributes.length && updateReason))}
                                                 disabled={!(relevantKeys.length && bulkEditAttributes.length && updateReason)}
                                                 onClick={makeValuestoDisplayInConfirmation}
                                                 > 
                                            Update RACI
                                         </Button>
                                     </div>
                                     {
                                         userLoaded && generateTable && (user.devAdmin || process.processAdmins.includes(user.userAlias)) &&
                                         <div>
                                             <Button id="bulk-edit-delete-button"
                                                 className={`reset-all-button-wrapper`}
                                                 onClick={confirmDeleteKeys}
                                                 disabled={!(relevantKeys.length && !bulkEditAttributes.length && updateReason)}
                                             > 
                                                Delete Keys
                                             </Button>
                                         </div>
                                     }
                                 </div>
                            </div>
                    }
                </React.Fragment>
            </>
            {
                openConfirmationDialog && generateModalDialogContent()
                   
            }

            {openConfirmationDialog && confirmationDialogContent.current !== CONFIRM_ACTION_CONSTANTS.BULK_UPDATE_KEYS ?
                <ConfirmationDialog open={openConfirmationDialog} onClose={handleDialogClose}>
                    {
                        generateConfirmationDialogContent()
                    }
                </ConfirmationDialog>: undefined
            }

        </Container>
    );
}