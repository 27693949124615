import { Alert, Button, Modal } from '@amzn/awsui-components-react';
import { API } from 'aws-amplify';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { applyBlueThemeToButton } from '../common/Utils';

const DeleteCheckpointDialogContent = (props) => {
    const [isCheckpointBeingDeleted, setIsCheckpointBeingDeleted] = useState(false);
    const history = useHistory();
    const [alertMessage, setAlertMessage] = useState(null);

    const handleDelete = async () => {
        setIsCheckpointBeingDeleted(true);
        try {
            const response = await API.del('ApproverMatrixAPI', '/checkpoint', {
                body: {
                    ...props.checkpoint,
                    alias: props.user.userAlias
                }
            });
            if(response) {
                history.push('/checkpoints');
                props.updateMessage(`Deleted Checkpoint ${response.programName ?? ''} successfully!`, "success")
            }
        }
        catch(err) {
            setIsCheckpointBeingDeleted(false);
            setAlertMessage({
                message: "Unable to delete checkpoint. Please try again.",
                variant: "error"
            });
        }
    }

    return (
        <div id="DeleteCheckpointDialogContent-wrapper">
            <Modal visible={true} header={`Delete Checkpoint: ${props?.checkpoint?.programName ?? ''}`} id='modal-dialog-wrapper' 
                footer={
                    <div style={{float: "right"}} id="DeleteCheckpointDialogContent-delete-wrapper">
                        <Button className={applyBlueThemeToButton(!isCheckpointBeingDeleted)} 
                            disabled={isCheckpointBeingDeleted} onClick={handleDelete}>Confirm Delete</Button>
                    </div>
                }
                onDismiss={() => {if(!isCheckpointBeingDeleted) props?.handleDialogClose();}}
            >
                <div id="DeleteCheckpointDialogContent">
                    <div id='DeleteCheckpointDialogContent-alert-info' style={{marginBottom: "2rem"}}>
                            {
                                alertMessage && <Alert type={alertMessage.type} header={alertMessage.content} onDismiss={() => setAlertMessage(null)} />
                            }
                    </div>
                    <div className='DeleteCheckpointDialogContent-info-text'>
                        <h4>{`The action will delete the Checkpoint ${props?.checkpoint?.programName ?? ''}. Please confirm to proceed.`}</h4>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DeleteCheckpointDialogContent;