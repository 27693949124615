import { Alert, Button, Checkbox, Modal, Table } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { UserService } from '../../service/UserService';
import { applyBlueThemeToButton } from '../common/Utils';
import { ASSIGNEE, DELEGATE_1, DELEGATE_2 } from "./Constants";

const UpdateMatrixConfirmationDialogContent = (props) => {
    const bulkEditAttributes = props.bulkEditAttributes;
    const [bulkEditAttributeRows, setBulkEditAttributeRows] = useState(new Array(bulkEditAttributes?.length));
    const bulkEditAttributeRowsRef = useRef(bulkEditAttributeRows);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [areKeysBeingUpdated, setAreKeysBeingUpdated] = useState(false);
    const [isOperationSuccessful, setIsOperationSuccessful] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const STATUS_MAP = {
        VALID: "Valid",
        VALID_ALIAS: "Valid Alias",
        INVALID: "Invalid",
        INVALID_ALIAS: "Invalid Alias",
        PENDING: "Pending"
    };
    const columns = [
        {
            id: "attributeName",
            header: "Attribute Name",
            cell: e => e.label,
        },
        {
            id: "newValue",
            header: "New Value",
            cell: e => e.value,
        },
        {
            id: "status",
            header: "Status",
            cell: e => <p className={`${generateStyleForStatusColumn(e.status)}`}>{e.status}</p>,
        },
    ];

    const generateStyleForStatusColumn = (status) => {
        switch(status) {
            case STATUS_MAP.VALID:
                return 'status-green';
            case STATUS_MAP.VALID_ALIAS:
                return 'status-green';
            case STATUS_MAP.INVALID:
                return 'status-red';
            case STATUS_MAP.INVALID_ALIAS:
                return 'status-red';
            case STATUS_MAP.PENDING:
                return 'status-orange';
            default:
                return '';
        }
    }

    /** 
     * Validates the user-alias updates for Assignee / Delegates
    */
    const handleValidateUser = async (alias, index) => {
        try {
            const response = await UserService.validateUser(alias);
            if(response?.validUser) {
                const userDetails = await UserService.getUser(alias);
                const rows = [...bulkEditAttributeRowsRef.current];
                rows[index].value = `${alias} (${userDetails?.userName})`;
                rows[index].status = STATUS_MAP.VALID_ALIAS;
                bulkEditAttributeRowsRef.current = rows;
                setBulkEditAttributeRows(rows);
            }
        }
        catch(error) {
            const rows = [...bulkEditAttributeRowsRef.current];
            rows[index].status = STATUS_MAP.INVALID_ALIAS;
            bulkEditAttributeRowsRef.current = rows;
            setBulkEditAttributeRows(rows);
        }
    };

    /**
     * Initializes the display rows for updated attributes
     */
    useEffect(() => {
        const rows = [];
        for(let index = 0; index < bulkEditAttributeRows.length; index++) {
            const bulkEditAttribute = bulkEditAttributes[index];
            if(bulkEditAttribute.label === ASSIGNEE) {
                rows[index] = {
                    label: bulkEditAttribute.label,
                    value: bulkEditAttribute.value,
                    status: bulkEditAttribute.value ? STATUS_MAP.PENDING : STATUS_MAP.INVALID
                };
                handleValidateUser(bulkEditAttribute.value, index);
            }
            else if(bulkEditAttribute.label === DELEGATE_1 || bulkEditAttribute.label === DELEGATE_2) {
                if(bulkEditAttribute.value) {
                    rows[index] = {
                        label: bulkEditAttribute.label,
                        value: bulkEditAttribute.value,
                        status: bulkEditAttribute.value ? STATUS_MAP.PENDING : STATUS_MAP.INVALID
                    };
                    handleValidateUser(bulkEditAttribute.value, index);
                }
                else {
                    rows[index] = {
                        label: bulkEditAttribute.label,
                        value: "",
                        status: STATUS_MAP.VALID
                    };
                }
            }
            else {
                rows[index] = {
                    label: bulkEditAttribute.label,
                    value: bulkEditAttribute.value,
                    status: bulkEditAttribute.value ? STATUS_MAP.VALID : STATUS_MAP.INVALID
                };
            }
        }
        bulkEditAttributeRowsRef.current = rows;
        setBulkEditAttributeRows(rows);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * Enables submit button if all updated attributes are valid
     */
    useEffect(() => {
        let isValid = true;
        for(const bulkEditAttribute of bulkEditAttributeRows) {
            if(!(bulkEditAttribute?.status === STATUS_MAP.VALID || bulkEditAttribute?.status === STATUS_MAP.VALID_ALIAS)) {
                isValid = false;
                break;
            }
        }
        if(isValid) {
            setEnableSubmit(true);
        }
        else {
            setEnableSubmit(false);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkEditAttributeRows]);

    const handleSubmit = async () => {
        setEnableSubmit(false);
        setAreKeysBeingUpdated(true);
        setAlertMessage({
            type: 'info',
            content: 'Updating keys...'
        });
        const response = await props.handleClickBulkEditConfirmed();
        setAreKeysBeingUpdated(false);
        if(response?.status) {
            setAlertMessage({
                type: 'success',
                content: `Successfully updated keys. Successful keys: ${response?.successfulKeys ?? 0}, Failed keys: ${response?.failedKeys ?? 0}.`
            });
            setIsOperationSuccessful(true);
        }
        else { //Allow a retry
            setAlertMessage({
                type: 'error',
                content: `Failed to Bulk update keys. ${response?.errorMessage ?? ''}.`
            });
            setEnableSubmit(true);
        }
    };

    return (
        <div id="update-raci-confirmation-dialog">
            <Modal id='modal-dialog-wrapper' visible={true} header="Update RACI" footer={
                <div>
                    {
                        props.displayIssueNotification &&
                        <div style={{float: "left"}}>
                            <Checkbox checked={props.receiveNotification} disabled={areKeysBeingUpdated || isOperationSuccessful} 
                                onChange={_ => props.setReceiveNotification(prevCheck => !prevCheck)}>
                                Issue Notification?
                            </Checkbox>
                        </div>
                    }
                    <div id="UpdateMatrixConfirmationDialogContent-submit-wrapper" style={{float: "right"}}>
                        <Button disabled={!enableSubmit} onClick={handleSubmit}
                            className={applyBlueThemeToButton(enableSubmit)}>
                                Confirm
                            </Button>
                    </div>
                </div>
            }
            onDismiss={() => {
                if(!areKeysBeingUpdated) props.handleDialogClose();
            }}
            >
                <div id="UpdateMatrixConfirmationDialogContent">
                    {
                        alertMessage && 
                            <div id='UpdateMatrixConfirmationDialogContent-alert-info' style={{marginBottom: "2rem"}}>
                                <Alert type={alertMessage.type} header={alertMessage.content} onDismiss={() => setAlertMessage(null)} />
                            </div>
                    }
                    <div className='Dialog-info-text'>
                        <h4>
                            {`The action will update ${props.relevantKeys.length} keys with the following selections. Do you want to proceed?`}
                        </h4>
                    </div>
                    <div id="UpdateMatrixConfirmationDialogContent-table-wrapper">
                        <Table columnDefinitions={columns} items={bulkEditAttributeRows} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UpdateMatrixConfirmationDialogContent;