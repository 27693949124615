import { Select } from "@amzn/awsui-components-react";
import { Amplify } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Route, Switch, useHistory, useLocation } from "react-router-dom";

import { Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import "../assets/css/components/Navigation.css";
import BulkKeyEditView from "./BulkKeyEdit/BulkKeyEdit";
import ChangeLog from './ChangeLog';
import CheckpointView from "./Checkpoint/CheckpointView";
import ManageCheckpoints from './Checkpoint/ManageCheckpoints';
import Drafts from './CreateKeys/Drafts';
import KeysView from "./CreateKeys/Keys";
import Error from "./Error";
import Home from "./Home/Home";
import ImportKeyCsv from "./ImportKeyCsv";
import ProcessView from "./ProcessView";
import { CHECKPOINT_SUBDIRECTORY, CREATE_CHECKPOINT_MODE, PROCESSVIEW_SUBDIRECTORY } from "./common/Constants";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * Navigation component displayed at top of UI
 * @param props properties needed to display the navbar
 * - user the logged in user
 * - alias midway provided alias
 * - loadingUser: boolean to indicate if loading user information
 * @returns {JSX.Element}
 * @constructor
 */
export default function Navigation(props) {
    // Pull user info from props and build login message
    const user = props.user;
    const loadingUser = props.loadingUser;

    // State variables needed for setting up process select
    const [selectedProcess, setSelectedProcess] = useState('');
    const [selectedProcessAdmins, setSelectedProcessAdmins] = useState([]);
    // select options used in the dropdown
    const [processLabels, setProcessLabels] = useState([]);
    // map from process id to process object
    const [processMap, setProcessMap] = useState({});

    // state variables related to the snackbar alert at the bottom (success or failure display to user)
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [autoHideDuration, setAutoHideDuration] = useState(12000);

    //state to hold updateReasons for a given process
    const [updateReasons, setUpdateReasons] = useState([]);

    // page history to allow for onchange to select to update the URL
    const history = useHistory();

    // query parameter used in the routing
    const query = useQuery();

    // values of ids for role and responsibility
    const [roleAttributeId, setRoleAttributeId] = useState({});
    const [responsibilityAttributeId, setResponsibilityAttributeId] = useState({});
    // list of all attributes in the process
    const [attributes, setAttributes] = useState({});

    const userLoginMessage = user ? `Signed in as: ${user.userAlias}` : ''

    // method to fetch new list of attributes when process id changes
    useEffect(() => {
        const processId = selectedProcess.value
        if (processId && processId !== "") {
            // Fetch process attributes
            Amplify.API.get("ApproverMatrixAPI", `/attributes?processId=${processId}`)
                .then(response => {
                    let attributesAPI = response.attributes.sort(function(a, b) {

                        if (a.attributeOrder < b.attributeOrder) { return -1; }
                        if (a.attributeOrder > b.attributeOrder) { return 1; }
                        return 0;
                    });
                    let attributeMap = {};
                    attributesAPI.forEach((attribute) => {
                        attributeMap[attribute.attributeId] = attribute;
                        if (attribute.attributeName === "Role") {
                            setRoleAttributeId({id:attribute.attributeId, values:attribute.attributeValues})
                        }
                        if (attribute.attributeName === "Responsibility") {
                            setResponsibilityAttributeId({id: attribute.attributeId, values: attribute.attributeValues})
                        }
                    });
                    setAttributes(attributeMap);
                });
            // Fetch the update reasons for a specific process
            Amplify.API.get("ApproverMatrixAPI", `/updateReasons?processId=${processId}`)
                .then(response => {
                    setUpdateReasons(response.values);
                });
        }
    }, [selectedProcess.value]);


    // method to update the message at the bottom
    const updateMessage = (newMessage, severity, autoHideMessage) => {
        setMessage(newMessage)
        setSeverity(severity)
        setOpen(true)
        // keep message open
        if ((autoHideMessage !== undefined && !autoHideMessage) || severity === "error") {
            setAutoHideDuration(null);
        // by default, message is hidden after 12000 ms;
        } else {
            setAutoHideDuration(12000);
        }
    };

    // method to update selected process
    const handleNewSelectedProcess = (selectedOption)=>{
        setSelectedProcess(selectedOption);
        let newProcessId = selectedOption.value;
        setSelectedProcessAdmins(processMap[newProcessId].processAdmins);
        history.push("/");
    
    }

    // called when user saved changes in manage process page and rename the selected process
    const refreshProcessMetadata = ()=>{
        Amplify.API.get("ApproverMatrixAPI", "/processes")
        .then(response => {
            let newProcessLabels = response.processes.map(elem => ({label: elem.processName, value: elem.processId}));
            let newProcessMap = response.processes.reduce(function (result, item) {
                result[item.processId] = item;
                return result
            }, {});
            setProcessMap(newProcessMap);
            setProcessLabels(newProcessLabels);
            if (newProcessLabels.length > 0 && selectedProcess !== "") {
                if (newProcessMap[selectedProcess.value]){
                    let newSelectedProcessName = newProcessMap[selectedProcess.value].processName;
                    let newSelectedProcessAdmins = newProcessMap[selectedProcess.value].processAdmins;
                    if (newSelectedProcessName !== selectedProcess.label){
                        setSelectedProcess((prevState)=>{return {
                            label: newSelectedProcessName,
                            value: prevState.value
                        }});
                    }
                    setSelectedProcessAdmins(newSelectedProcessAdmins);
                }else{
                    setSelectedProcess(processLabels[0])
                    setSelectedProcessAdmins(processMap[processLabels[0].value].processAdmins);
                }
            }
        })
    }

    // function that pulls processes when this page is loaded
    useEffect(() => {
        // get processes and convert to dropdown options and map from process id to process object
        Amplify.API.get("ApproverMatrixAPI", "/processes")
            .then(response => {
                let processLabels = response.processes.map(elem => ({label: elem.processName, value: elem.processId}));
                let processMap = response.processes.reduce(function (result, item) {
                    result[item.processId] = item;
                    return result
                }, {});

                setProcessMap(processMap);
                setProcessLabels(processLabels);
                if (processLabels.length > 0) {
                    setSelectedProcess(processLabels[0])
                    setSelectedProcessAdmins(processMap[processLabels[0].value].processAdmins);
                }
            })
    }, []);

    useEffect(() => {
        // when checkpoint view/edit page is loaded, checkpoint page is set to process of checkpoint instead of default process
        // when editing process with specific id, the selected process is also set to one being edited
        let inCheckpointPage = history.location.pathname.startsWith(CHECKPOINT_SUBDIRECTORY);
        let inProcessViewPage = history.location.pathname.startsWith(PROCESSVIEW_SUBDIRECTORY) && history.location.search.length > 0;
        let queryProcessId = "";
        let editProcessView = false;
        if (inProcessViewPage){
            // check if it's in edit mode
            let paramSearchURL = new URLSearchParams(history.location.search);
            queryProcessId = paramSearchURL.get("processId");
            let queryMode = paramSearchURL.get("mode");
            editProcessView = (queryMode === "edit") && (queryProcessId !== "");
        }
        if (inCheckpointPage || editProcessView) {
            Amplify.API.get("ApproverMatrixAPI", "/processes")
                .then(response => {
                    let processLabels = response.processes.map(elem => ({
                        label: elem.processName,
                        value: elem.processId
                    })).sort((a, b) =>
                        {
                            let l =  a.value.toLowerCase(), r = b.value.toLowerCase();

                            if (l < r) { return -1; }
                            if (l > r) { return 1; }
                            return 0;
                        }
                    );
                    let processMap = response.processes.reduce(function (result, item) {
                        result[item.processId] = item;
                        return result
                    }, {});

                    setProcessMap(processMap);
                    setProcessLabels(processLabels);

                    if (processLabels.length > 0) {
                        // set selected process for edit process page
                        if (editProcessView){
                            const labelIndex = processLabels.findIndex(process => process.value === queryProcessId);
                            setSelectedProcess(processLabels[labelIndex]);
                            setSelectedProcessAdmins(processMap[queryProcessId].processAdmins);
                        }
                    }
                });
        }

    }, [history.location]);

    const isDuplidateProcessName = (inputName)=>{
        let filtered = processLabels.filter((elem)=>{return elem.label===inputName});
        return filtered.length > 0;
    }


    return (
        <React.Fragment >
            <Navbar bg="dark" variant="dark" className="py-4" expand="lg">
                <Container className='Checkpoint-top-menu'>
                    <NavLink to="/" id="navbar-amazon-checkpoint" className="me-2 navbar-brand navbar-title">Amazon Checkpoint</NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav" className="navbar-margin">
                        <Nav className="me-auto top-navbar">
                            <NavLink to={`/checkpoint/${CREATE_CHECKPOINT_MODE}`} exact id="navbar-home" activeClassName="navbar-menu-item-active" className="navbar-menu-item">Create Checkpoint</NavLink>
                            <NavLink to="/checkpoints" id="navbar-home" activeClassName="navbar-menu-item-active" className="navbar-menu-item">Manage Checkpoints</NavLink>
                            <NavLink to="/bulk-edit-keys" id="navbar-bulk-edit-keys" activeClassName="navbar-menu-item-active" className="navbar-menu-item">Manage Responsibility Matrix</NavLink>
                            { 
                                (user?.devAdmin || selectedProcessAdmins.includes(user?.userAlias)) &&
                                    <NavLink to={`/process?processId=${selectedProcess.value}&&mode=edit`} activeClassName="navbar-menu-item-active" id="navbar-manage-process" className="navbar-menu-item">Manage Process</NavLink> 
                            }
                            { 
                                (user?.devAdmin || selectedProcessAdmins.includes(user?.userAlias)) &&
                                    <NavLink to="/key" id="navbar-keys" activeClassName="navbar-menu-item-active" className="navbar-menu-item">Create Keys</NavLink> 
                            }
                            {
                                (user?.devAdmin || selectedProcessAdmins.includes(user?.userAlias)) &&
                                    <NavLink to="/changelog" activeClassName="navbar-menu-item-active" id="navbar-changelog" className="navbar-menu-item">Change Log</NavLink>
                            }
                            {
                                (user?.devAdmin || selectedProcessAdmins.includes(user?.userAlias)) &&
                                    <NavLink to="/import-key-csv" id="navbar-changelog" activeClassName="navbar-menu-item-active" className="navbar-menu-item">Import Keys</NavLink>
                            }
                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Text className="me-2 mb-0 h6 menu-items">Process: </Navbar.Text>
                    <Nav.Item id="navbar-process" className="pe-5 navbar-dropdown">
                        <Select
                            options={processLabels}
                            selectedOption={selectedProcess}
                            onChange={(event) => {
                                handleNewSelectedProcess(event.detail.selectedOption);
                            }}
                            selectedAriaLabel="selected"
                        />
                    </Nav.Item>
                    <Navbar.Text className="h6 mb-0 d-none d-lg-block menu-items">{userLoginMessage}</Navbar.Text>
                    <NavDropdown id="user-help" className="h6 mb-0 menu-items nav-dropdown" title="User Help">
                            <NavDropdown.Item href="https://knet.csod.com/ui/lms-learning-details/app/course/f81e07ed-5517-43c6-a198-ad897786b5e0">
                                KNET Training
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="https://issues.amazon.com/issues/create?template=f0d96837-bca0-4ba5-9a1b-efe5861d54de">
                                Support SIM Ticket
                            </NavDropdown.Item>
                    </NavDropdown>
                </Container>
            </Navbar>
            <Switch>

                <Route exact path="/checkpoints">
                    {
                        user && selectedProcess && <ManageCheckpoints process={processMap[selectedProcess.value]} attributes={attributes} user={user} updateMessage={updateMessage} />
                    }
                </Route>

                <Route path="/bulk-edit-keys">
                    {user && selectedProcess ? <BulkKeyEditView attributes={attributes} responsibilityAttributeId={responsibilityAttributeId} updateReasons={updateReasons}
                                                                roleAttributeId={roleAttributeId} updateMessage={updateMessage} mode={query.get("mode")} process={processMap[selectedProcess.value]} user={user}/>  : null}
                </Route>
                <Route path="/process">
                    {user && selectedProcess ? <ProcessView attributes={attributes} responsibilityAttributeId={responsibilityAttributeId} updateReasons={updateReasons}
                                                            roleAttributeId={roleAttributeId} updateMessage={updateMessage} mode={query.get("mode")} process={processMap[selectedProcess.value]} user={user} isDuplidateProcessName={isDuplidateProcessName} refreshProcessMetadata={refreshProcessMetadata}/>  : null}
                </Route>
                <Route path="/process/:id">
                    {user ? <ProcessView attributes={attributes} responsibilityAttributeId={responsibilityAttributeId} updateReasons={updateReasons}
                                         roleAttributeId={roleAttributeId} updateMessage={updateMessage} mode={query.get("mode")} process={processMap[selectedProcess.value]} user={user} isDuplidateProcessName={isDuplidateProcessName} refreshProcessMetadata={refreshProcessMetadata}/>  : null}
                </Route>

                <Route path="/key" exact>
                    {user && selectedProcess ? <KeysView attributes={attributes} updateMessage={updateMessage} updateReasons={updateReasons}
                                                         mode={query.get("mode")} process={processMap[selectedProcess.value]} user={user}/>  : null}
                </Route>

                <Route path="/import-key-csv">
                    {user && selectedProcess ? <ImportKeyCsv attributes={attributes} processId={selectedProcess.value} user={user} updateMessage={updateMessage} /> : null}
                </Route>

                <Route path="/key/draft/:id">
                    {user && selectedProcess ? <KeysView attributes={attributes} responsibilityAttributeId={responsibilityAttributeId} updateReasons={updateReasons}
                                                         roleAttributeId={roleAttributeId} updateMessage={updateMessage} mode={query.get("mode")} process={processMap[selectedProcess.value]} user={user}/>  : null}
                </Route>

                <Route exact path="/checkpoints/:checkpointId">
                    {user && selectedProcess ? <CheckpointView attributes={attributes} responsibilityAttributeId={responsibilityAttributeId}
                                                               roleAttributeId={roleAttributeId} updateMessage={updateMessage} process={processMap[selectedProcess.value]} user={user}/> : null}
                </Route>
                <Route exact path="/checkpoint/:checkpointId">
                    {user && selectedProcess ? <CheckpointView attributes={attributes} responsibilityAttributeId={responsibilityAttributeId}
                                                               roleAttributeId={roleAttributeId} updateMessage={updateMessage} process={processMap[selectedProcess.value]} user={user}/> : null}
                </Route>
                <Route path="/key/drafts">
                    {user && selectedProcess ? <Drafts process={processMap[selectedProcess.value]} updateMessage={updateMessage} user={user} /> : null}
                </Route>
                <Route path="/changelog">
                    {user && selectedProcess ? <ChangeLog process={processMap[selectedProcess.value]} attributes={attributes} updateMessage={updateMessage}
                                                          processId={selectedProcess.value} user={user} /> : null}
                </Route>
                <Route path="/">
                    {
                        user && selectedProcess && <Home process={processMap[selectedProcess.value]} attributes={attributes} />
                    }
                </Route>
            </Switch>
            {!user && !loadingUser &&
                <Error/>}
            <Snackbar className="d-flex align-items-center" open={open} autoHideDuration={autoHideDuration} onClose={() => setOpen(false)}>
                <Alert className="d-flex align-items-center" severity={severity} onClose={() => setOpen(false)}>
                    <div className="d-flex align-items-center message-bar-text">{message}</div>
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}