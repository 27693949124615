import { FormField, Input, Select, Textarea } from "@amzn/awsui-components-react";
import React, { useState } from 'react';
import "../assets/css/components/AdditionalInfo.css";

/**
 * Component to render the additional info fields: Update Reason dropdown, Notes input, Sim Ticket Id input.
 * Formalized them under a single component for reusability across multiple views: Key creation, Manage Responsibility Matrix, Process.
 *
 * @props - Ref's to all the fields in the component are expected to be passed down to prevent unnecessary re-render of the parent component, while retaining the 
 * updates on any / all of the fields present over here.
 */
const AdditionalInfo = (props) => {
    const [simTicketId, setSimTicketId] = useState("");
    const [notes, setNotes] = useState("");
    const updateReasonOptions = props.updateReasons;
    const [updateReasonSelectedOption, setUpdateReasonSelectedOption] = useState("");
    const hideNonMandatoryFields = props.hideNonMandatoryFields ?? false;

    /**
     * Handle the change in Sim Ticket Id input field. Update the parent component's Ref, if provided
     * A Maximum of 20 characters are allowed for sim ticket field
     */
    const handleSimTicketIdChange = (event) => {
        const value = event.detail.value.substr(0, 20);
        setSimTicketId(value);
        if(props.simTicketIdRef) {
            props.simTicketIdRef.current = value;
        }
    };

    /**
     * Handle the change in Notes text-area field. Update the parent component's Ref, if provided
     * A maximum of 200 characters are allowed for notes field
     */
    const handleNotesChange = (event) => {
        const value = event.detail.value.substr(0, 500);
        setNotes(value);
        if(props.notesRef) {
            props.notesRef.current = value;
        }
    };

    /**
     * Handle the change in Update Reason selection. Update the parent component's Ref, if provided
     */
    const handleUpdateReasonOptionChanged = (event) => {
        const value = event.detail.selectedOption.value;
        setUpdateReasonSelectedOption(value);
        if(props.updateReasonRef) {
            props.updateReasonRef.current = value;
        }
        if(props.setUpdateReason) {
            props.setUpdateReason(value);
        }
    };

    return (
            <div id="additional-info" className='additional-info'>
                <div>
                    <FormField label="Update Reason*"/>
                    <Select
                        type='text'
                        onChange = {handleUpdateReasonOptionChanged}
                        selectedOption = {{label: updateReasonSelectedOption, value: updateReasonSelectedOption}}
                        options = {updateReasonOptions.map(val =>  {return {label: val, value: val}} )}
                        id="additionalInfoUpdateReasonSelect"
                        key= "AdditionalInfo-UpdateReason-Select"
                        filteringType='auto'
                    />
                </div>

                {!hideNonMandatoryFields &&
                    <>
                        <div>
                            <FormField label="Sim Ticket ID"/>
                            <Input
                                type='text'
                                value = {simTicketId}
                                onChange = {handleSimTicketIdChange}
                                autoComplete = {false}
                                id="additionalInfoSimTicketIdInput"
                                key= "AdditionalInfo-SimTicketId-Input"
                            />
                        </div>
                        <div id="additional-info-notes-wrapper" className='additional-info-notes-wrapper'>
                            <FormField label="Notes"/>
                            <Textarea
                                type='text'
                                value = {notes}
                                onChange = {handleNotesChange}
                                autoComplete = {false}
                                key= "AdditionalInfo-Notes-Input"
                            />
                        </div>
                    </>
                }

            </div> 
    );
};


export default AdditionalInfo;