import React, { useEffect, useRef, useState } from "react";
import { Pagination, Table, TextFilter, Button } from '@amzn/awsui-components-react';
import { Amplify } from "aws-amplify";
import "../assets/css/components/ChangeLog.css";
import { useCollection } from '@amzn/awsui-collection-hooks';
import EmptyState from "./EmptyState";
import ConfirmationDialog from "./ConfirmationDialog";
import "../assets/css/components/Keys.css";
import Select from "@amzn/awsui-components-react/polaris/select";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import { FormField } from "@amzn/awsui-components-react";
import { PHONE_TOOL_URL_PREFIX } from "./common/Constants";
import { generateOlderDateInMonths, convertDateFormat, convertTimestampToMilliseconds } from "./common/Utils";
import {PAGINATION_SELECTION_VALUES, ALL_ACTIONED_BY_USERS, ALL_UPDATE_TYPES_RACI, ALL_UPDATE_TYPES_PROCESS,
    CHANGE_LOG_TYPE_PROCESS, CHANGE_LOG_TYPE_RACI, CHANGE_LOG_TYPES, PEOPLE_PORTAL_SYNC_ALIAS} from "../config/ChangeLogConfig.js";

/**
 * Component to render the ChangeLog view
 * @param {*} props expected props are: list of attributes for the current process & the current process Id
 * @returns Change log view encapsulating both RACI & Process changes
 */
const ChangeLog = (props) => {
    const [data, setData] = useState([]);
    const [openSummaryView, setOpenSummaryView] = useState(false);
    const summaryViewData = useRef("");
    const updateMessage = props.updateMessage;
    const attributes = props.attributes;
    const [changeLogsPerPage, setChangeLogsPerPage] = useState(PAGINATION_SELECTION_VALUES[0]);
    const uniqueActionByUsers = useRef([ALL_ACTIONED_BY_USERS]);
    const [selectedActionedByUser, setSelectedActionedByUser] = useState(ALL_ACTIONED_BY_USERS);
    const [selectedUpdateType, setSelectedUpdateType] = useState(ALL_UPDATE_TYPES_RACI[0]);
    const currentSelectedType = useRef(CHANGE_LOG_TYPE_RACI);

    /**
     * Function to generate columns for the change-log table
     * Will be invoked only once, during the component load
     */
    const generateColumns = () => {
        const cols = [];
        cols.push({
            id: "type",
            header: "Update Type",
            cell: e => e.type + " " + e.action,
            sortingField: "action"
        });
        cols.push({
            id: "timestamp",
            header: "Timestamp",
            cell: e => new Date(parseInt(convertTimestampToMilliseconds(e.timestamp), 10)).toLocaleString(undefined, {timeZone: "UTC", hour: '2-digit', minute:'2-digit',
                year: 'numeric', month: 'numeric', day: 'numeric',}),
            sortingField: "timestamp"
        });
        cols.push({
            id: "actionedBy",
            header: "Actioned By",
            cell: e => <a id="changeLogUserPhoneTool" className={`Changelog-cols ${e.actionedByUserAlias === PEOPLE_PORTAL_SYNC_ALIAS && "disabled"}`} 
                href={`${PHONE_TOOL_URL_PREFIX}/${e.actionedByUserAlias}`} onClick="event.preventDefault()" 
                rel="noreferrer" target="_blank">{e.actionedByUserAlias}</a>,
            sortingField: "actionedByUserAlias"
        });
        cols.push({
            id: "info",
            header: "Info",
            cell: e => generateInfoSection(e)
        });
        cols.push({
            id: "additional Info",
            header: "Additional Info",
            cell: e => (
                            <div className="Changelog-additional-info-section-wrapper" id="changeLogAdditionalInfoCell">
                                <div>{e.updateReason ? generateAdditionalInfoField('Update Reason', e.updateReason) : null}</div>
                                <div>{e.simTicketId ? generateAdditionalInfoField('Sim Ticket Id', e.simTicketId) : null}</div>
                                <div>{e.notes ? generateAdditionalInfoField('Notes', e.notes) : null}</div>
                            </div>
                        )
        });
        if(currentSelectedType.current === CHANGE_LOG_TYPE_RACI) {
            cols.push({
                id: "summary",
                header: "Summary",
                cell: e => <Button id="changeLogSummaryButton" onClick={() => handleSummaryViewClicked(e)}>View Summary</Button>
            });
        }
        return cols;
    };

    const [columns, setColumns] = useState(() => generateColumns());

    //This will only come into effect when there is a change in RACI -> Process / vice-versa or when the application loads 
    // through the change-log page by default instead of the usual home-page to update the attribute id : name mappings
    // to prevent empty values showing up before the "props.attribute" object is initialized
    useEffect(() => {
        setColumns(generateColumns());
        // eslint-disable-next-line
    }, [props.attributes, currentSelectedType.current]);

    /**
     * Handler to generate a table of change log items upon receiving the data from the GenerateHeader component
     * This method is invoked early-on, once, as soon as the user lands on the page to load the past 2-years RACI logs data up-front
     * Afterwards, based on the user selection of the fields(type, start & end date) present in the GenerateHeader component, this 
     * method is invoked with the appropriate parameters
     */
    const generateChangeLogs = (data) => {
        currentSelectedType.current = data.type;
        Amplify.API.get("ApproverMatrixAPI", `/changelogs?processId=${props.processId}&type=${data.type}&from=${data.startDate}&to=${data.endDate}`)
        .then(response => {
            const changeLogs = response.changeLogs;
            const uniqueUsers = new Set();
            //Capture a list of unique users from the list of the response received for additional filtering, based on the actioned by user
            for(const changeLog of changeLogs) {
                uniqueUsers.add(changeLog.actionedByUserAlias);
            }
            const uniqueUsersSorted = Array.from(uniqueUsers).sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}));
            uniqueActionByUsers.current = [ALL_ACTIONED_BY_USERS, ...uniqueUsersSorted];
            setData(changeLogs);
        })
        .catch(err => {
            err?.response?.data?.errorMessage ?
                updateMessage("Unable to retrieve change logs! " + err.response.data.errorMessage, "error")
                : updateMessage("Unable to retrieve change logs! Please try again.", "error");
        });
    };

    /**
     * A common method to generate the Additional-info(UpdateReason, SimTicketId, Notes) cell
     */
    const generateAdditionalInfoField = (field, value) => {
        return (
            <>
                <b>{field}: </b>
                <span>{value}</span>
            </>
        );
    };

    /**
     * Verify whether a changelog item is inline with the selected value of the actioned by users filter drop-down
     */
    const matchesActionedByUser = (item) => {
        return selectedActionedByUser === ALL_ACTIONED_BY_USERS || item.actionedByUserAlias === selectedActionedByUser;
    };

    /**
     * Verify whether the a changelog item is inline with the selected value of the update type filter drop-down
     */
    const matchesUpdateType = (item) => {
        if(currentSelectedType.current === CHANGE_LOG_TYPE_RACI) {
            return selectedUpdateType === ALL_UPDATE_TYPES_RACI[0] || (item.type + " " + item.action) === selectedUpdateType;
        }
        return selectedUpdateType === ALL_UPDATE_TYPES_PROCESS[0] || (item.type + " " + item.action) === selectedUpdateType;
    };

    /**
     * Set props for change logs for the Polaris table
     */
    const { items: changeLogItems, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(data, {
        filtering: {
            empty: (
                        <EmptyState
                            title="No logs"
                            subtitle="Update selections to view logs"
                        />
                    ),
            noMatch: (
                        <EmptyState
                            title="No matches"
                            subtitle="We can’t find a match."
                            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                        />
                    ),
            filteringFunction: (item, filteringText) => {
                //Verify whether the current changelog item is inline with the drop-down selections
                if(!matchesActionedByUser(item)) return false;
                if(!matchesUpdateType(item)) return false;
                //If the filtering text is empty, the current item is a valid one
                if(!filteringText) return true;

                for(const key in item) {
                    //Compare lower-case texts to avoid case-sensitivity of toLowerCase()
                    if(item[key] !== null && typeof item[key] === 'string' && item[key].toLowerCase().indexOf(filteringText.toLowerCase()) > - 1) return true;
                }
                return false;
            },
        },
        sorting: { },
        pagination: { pageSize: changeLogsPerPage }
    });

    /**
     * Handler to construct the table-view for the summary-view dialog
     */
    const handleSummaryViewClicked = (data) => {
        const summary = JSON.parse(data.summary);
        summaryViewData.current = (
            <div className="Changelog-summary-view-dialog-wrapper" id="changeLogSummary">
                <div className="Changelog-summary-view-info-wrapper" id="ChangelogSummaryInfoWrapper">
                    <h3>{data.type + " " + data.action}:</h3>
                    <h3 className="Changelog-summary-view-info">{new Date(parseInt((convertTimestampToMilliseconds(data.timestamp)), 10)).toLocaleDateString()}</h3>
                </div>
                <div className="Changelog-summary-view-content-wrapper" id="ChangelogSummaryContentWrapper">
                    {
                        Object.values(attributes).map(attribute =>  
                            summary[attribute.attributeId] && summary[attribute.attributeId].length && attribute.displayInEmail &&
                            <div className="Changelog-summary-view-content-item-wrapper" key={attribute.attributeId} id="ChangelogSummaryContentItemWrapper">
                                <div><h4 id="ChangelogSummaryContentItemHeader">{attribute.attributeName}</h4></div>
                                <div className="Changelog-summary-view-content-value" id="ChangelogSummaryContentItemValue">{summary[attribute.attributeId].join()}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
        setOpenSummaryView(true);
    };


    /**
     * Function to generate an anchor tag for the user-name in the info-box with alias appended as href phone-tool link
     */
    const generateUserDetail = (valueString) => {
        if(!valueString) return <></>;
        const valueStringArray = valueString.split(",");
        return valueStringArray.flatMap(value => {
            const user = value.split(":");
            if(user[0] === 'null' || user[1] === undefined) return [];
            return (
                [<a className="Changelog-cols" id="changeLogInfoUserPhoneTool" href={`${PHONE_TOOL_URL_PREFIX}/${user[1]}`} 
                onClick="event.preventDefault()" rel="noreferrer" target="_blank">{user[0]}</a>]
            );
        });
    }


    /**
     * Function to generate the Info section of the polaris table.
     * Attribute name, Old & New values of the operation are displayed through this section
     */
    const generateInfoSection = (data) => {
        const info = JSON.parse(data.info);
        const criteriaOldValues = data.criteriaOldValues ? JSON.parse(data.criteriaOldValues) : "";
        const criteriaNewValues = data.criteriaNewValues ? JSON.parse(data.criteriaNewValues): "";
        return (
            <div className="Changelog-info-expandable-section-wrapper">
                <table id="changeLogInfoTable">
                    <thead>
                        <tr>
                            <th className="Changelog-info-section-cell">Attribute</th>
                            <th className="Changelog-info-section-cell">Old Value</th>
                            <th className="Changelog-info-section-cell">New Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (info.assigneeNewValue|| info.assigneeOldValues) &&
                            <tr id="changeLogAssigneeInfoTableRow">
                                <td className="Changelog-info-section-cell">Assignee</td>
                                <td className="Changelog-info-section-cell">{generateUserDetail(info.assigneeOldValues)}</td>
                                <td className="Changelog-info-section-cell">{generateUserDetail(info.assigneeNewValue)}</td>
                            </tr>
                        }
                        {
                            (info.delegate1NewValue || info.delegate1OldValues) &&
                            <tr id="changeLogDelegate1InfoTableRow">
                                <td className="Changelog-info-section-cell">Delegate1</td>
                                <td className="Changelog-info-section-cell">{generateUserDetail(info.delegate1OldValues)}</td>
                                <td className="Changelog-info-section-cell">{generateUserDetail(info.delegate1NewValue)}</td>
                            </tr>
                        }
                        {
                            (info.delegate2NewValue|| info.delegate2OldValues) &&
                            <tr id="changeLogDelegate2InfoTableRow">
                                <td className="Changelog-info-section-cell">Delegate2</td>
                                <td className="Changelog-info-section-cell">{generateUserDetail(info.delegate2OldValues)}</td>
                                <td className="Changelog-info-section-cell">{generateUserDetail(info.delegate2NewValue)}</td>
                            </tr>
                        }
                        {
                            (criteriaNewValues || criteriaOldValues) ? 
                                currentSelectedType.current === CHANGE_LOG_TYPE_RACI ?
                                    Object.values(attributes).map(attribute =>  
                                        (criteriaOldValues[attribute.attributeId] || criteriaNewValues[attribute.attributeId]) &&
                                        <tr key={attribute.attributeId}>
                                            <td className="Changelog-info-section-cell">{attribute.attributeName}</td>
                                            <td className="Changelog-info-section-cell">{criteriaOldValues[attribute.attributeId] && 
                                                criteriaOldValues[attribute.attributeId].join(", ")}</td>
                                            <td className="Changelog-info-section-cell">{criteriaNewValues[attribute.attributeId] && 
                                                criteriaNewValues[attribute.attributeId].join(", ")}</td>
                                        </tr>
                                    )
                                    
                                    :
                                
                                    (criteriaNewValues.length || criteriaOldValues.length) ? 
                                        <tr id="changeLogCriteriaInfoTableRow">
                                            <td className="Changelog-info-section-cell">{info.processAttributeNewName}</td>
                                            <td className="Changelog-info-section-cell">{criteriaOldValues.length ? criteriaOldValues.join(", ") : ""}</td>
                                            <td className="Changelog-info-section-cell">{criteriaNewValues.length ? criteriaNewValues.join(", ") : ""}</td>
                                        </tr>
                                        :
                                        null
                                :
                                null
                                
                                
                        }
                        {
                            currentSelectedType.current === CHANGE_LOG_TYPE_PROCESS && info.processAttributeOldName !== info.processAttributeNewName ?
                            <tr id="changeLogAttributeInfoTableRow">
                                <td className="Changelog-info-section-cell">Process Attribute</td>
                                <td className="Changelog-info-section-cell">{info.processAttributeOldName}</td>
                                <td className="Changelog-info-section-cell">{info.processAttributeNewName}</td>
                            </tr>
                            :
                            null
                        }
                    </tbody>
                </table>
            </div>
        );
    };

    /**
     * Function to close the summary view dialog
     */
    const handleSummaryViewClose = () => {
        setOpenSummaryView(false);
        summaryViewData.current = "";
    };

    /**
     * Function to return the count of filtered input matched items in the format of: #matchedItemsCount matches
     */
    const getFilteredItemsCount = (count) => {
        return  "(" + count + " matches)";
    };

    /**
     * Function to generate the Polaris table header for the Change logs view
     * Header includes the title "ChangeLog" along with the no.of logs retrieved based on the user selections, and
     * a dropdown to select the no.of logs to be displayed per page
     */
    const generatePolarisTableHeader = () => {
        return (
            <div className="Changelog-table-header-wrapper">
                <div>
                    <h3 id="ChangelogTableLabel">Change Logs ({data?.length})</h3>
                </div>
                <div className="Changelog-table-header-pagination-dropdown-wrapper">
                    <h4 id="ChangelogPaginationSelectLabel">Logs per page</h4>
                    <Select
                        onChange={({ detail }) => setChangeLogsPerPage(detail.selectedOption.value)}
                        options={PAGINATION_SELECTION_VALUES.map(val =>  {return {label: val, value: val}} )}
                        selectedOption = {{label: changeLogsPerPage, value: changeLogsPerPage}}
                        id="ChangelogPaginationSelect"
                        key="ChangeLog-Pagination-Select"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="Changelog">
            <GenerateHeader onSubmit={generateChangeLogs} />
            <div className="Changelog-table-wrapper" id="ChangelogTableWrapper">
                <Table
                    {...collectionProps}
                    columnDefinitions={columns}   
                    items={changeLogItems}
                    id="ChangelogTable"
                    header={generatePolarisTableHeader()}
                    filter = {
                        <div className="Changelog-filter-wrapper">
                            <div className="Changelog-filter-text-wrapper">
                                <TextFilter 
                                    {...filterProps}
                                    countText={getFilteredItemsCount(filteredItemsCount)}   
                                    filteringAriaLabel="Filter logs"
                                    filteringPlaceholder="Filter logs"
                                    id="ChangelogTextFilter"
                                />
                            </div>
                            <div className="Changelog-actionedByUser-select-wrapper">
                                <Select
                                    onChange={({ detail }) => setSelectedUpdateType(detail.selectedOption.value)}
                                    options={
                                        currentSelectedType.current === CHANGE_LOG_TYPE_RACI ?
                                            ALL_UPDATE_TYPES_RACI.map(val =>  {return {label: val, value: val}} )
                                            :
                                            ALL_UPDATE_TYPES_PROCESS.map(val =>  {return {label: val, value: val}} )
                                    }
                                    selectedOption = {{label: selectedUpdateType, value: selectedUpdateType}}
                                    id="ChangelogUpdateTypeSelect"
                                />
                            </div>
                            <div className="Changelog-actionedByUser-select-wrapper">
                                <Select
                                    onChange={({ detail }) => setSelectedActionedByUser(detail.selectedOption.value)}
                                    options={uniqueActionByUsers.current.map(val =>  {return {label: val, value: val}} )}
                                    selectedOption = {{label: selectedActionedByUser, value: selectedActionedByUser}}
                                    id="ChangelogActionedBySelect"
                                    filteringType="auto"
                                />
                            </div>
                        </div>
                    }
                    pagination={
                        <Pagination
                            {...paginationProps}
                            ariaLabels={{
                                nextPageLabel: "Next page",
                                previousPageLabel: "Previous page",                            
                            }}
                            pagesCount={Math.ceil(filteredItemsCount / changeLogsPerPage)}
                            id="ChangelogPagination"
                        />
                    }
                />
            </div>

            <ConfirmationDialog open={openSummaryView} onClose={handleSummaryViewClose} className="Changelog-confirmation-dialog" id="changeLogSummaryDialog">
               {
                    summaryViewData.current
               }
            </ConfirmationDialog>
        </div>
    );
};

/**
 * Header component for Change Log view
 * Comprises of the type, start and end-dates & a submit button
 */
const GenerateHeader = (props) => {
    const currentDate = new Date();
    const currentDateMinus2Years = new Date(generateOlderDateInMonths(new Date(), 24));
    const [startDate, setStartDate] = useState(convertDateFormat(currentDateMinus2Years)); // startDate in YYYY/MM/DD
    const [endDate, setEndDate] = useState(convertDateFormat(currentDate)); // endDate in YYYY/MM/DD
    const [selectedType, setSelectedType] = useState({label: CHANGE_LOG_TYPE_RACI, value: CHANGE_LOG_TYPE_RACI});
    const [errorText, setErrorText] = useState("");
    const startDateErrorRef = useRef(false);
    const endDateErrorRef = useRef(false);



    /**
     * Handler to verify the selections and & submit the selected values to the parent ChangeLog component
     */
    const  generateChangeLogs = () => {
        setErrorText("");
        const currentStartDateUTC =  Math.floor(new Date(startDate).getTime());
        const currentEndDateUTC =  Math.floor(new Date(endDate).getTime());

        //Abort if the date selections are invalid
        if(verifyDateSelections(currentStartDateUTC, currentEndDateUTC) === false) {
            return;
        }

        //Adding one-day to the selected endDate to get the UTC epoch timestamp till 12:00 AM of the next-day(from the selected day)
        //i.e if 16th Jan is the endDate, records should be fetched till 12:00 AM of 17th Jan, and the timezone offset(in milliseconds) to adjust for
        // the offset
        const endDateAddOneDay = new Date(endDate);
        endDateAddOneDay.setDate(endDateAddOneDay.getDate() + 1);
        const endDateAddOneDayUTC = Math.floor(endDateAddOneDay.getTime());
        const timezoneOffsetMilliSeconds = new Date().getTimezoneOffset() * 60 * 1000;
        props.onSubmit({type: selectedType.value, startDate: currentStartDateUTC + timezoneOffsetMilliSeconds, endDate: endDateAddOneDayUTC + timezoneOffsetMilliSeconds});
    };

    /**
     * Handler to verify the date selections of the User
     * 1: current start date should not be exceed the current end date
     * 2: End date should not be greater than the today's date
     * 3: Start date should not be lower than 3 years from the current date
     * 4: Range of start and end dates should not exceed 2 years
     */
    const verifyDateSelections = (currentStartDateUTC, currentEndDateUTC) => {
        //Reset the invalid references
        startDateErrorRef.current = false;
        endDateErrorRef.current = false;

        // 1: current start date should not be exceed the current end date
        if(currentStartDateUTC > currentEndDateUTC) {
            startDateErrorRef.current = true;
            endDateErrorRef.current = true;
            setErrorText("Start date should not be greater than the End date");
            return false;
        }

        // 2: End date should not be greater than the today's date
        if(currentEndDateUTC > Math.floor(Date.now())) {
            endDateErrorRef.current = true;
            setErrorText("End date should not be greater than today's date");
            return false;
        }

        // 3: Start date should not be lower than 3 years from the current date
        const currentDateMinus3YearsUTC = Math.floor(generateOlderDateInMonths(new Date(), 36));
        if(currentStartDateUTC < currentDateMinus3YearsUTC) {
            startDateErrorRef.current = true;
            setErrorText("Start date should not be lower than 3 years from today's date");
            return false;
        }

        // 4: Range of start and end dates should not exceed 2 years
        if(currentEndDateUTC - currentStartDateUTC > (2 * 365 * 24 * 60 * 60 * 1000)) {
            startDateErrorRef.current = true;
            endDateErrorRef.current = true;
            setErrorText("Range of start and end dates should not exceed 2 years");
            return false;
        }

        return true;
    };

    /**
     * Generate logs for the past 2 years from the current date on the initial load of 
     * the Change log view
     */
    useEffect(() => {
        const startDateUTC = Math.floor(new Date(startDate).getTime()); //startDate in UTC
        const endDateUTC = Math.floor(Date.parse(currentDate)); //endDate in UTC
        props.onSubmit({type: selectedType.value, startDate: startDateUTC, endDate: endDateUTC});
    // eslint-disable-next-line
    }, []);

    return (
        <div className="Changelog-header">
            <div className="Changelog-header-content-wrapper">
                <div className="Changelog-header-select-wrapper">
                    <Select
                        onChange={({ detail }) => setSelectedType(detail.selectedOption)}
                        options={CHANGE_LOG_TYPES.map(val =>  {return {label: val, value: val}} )}
                        selectedOption = {selectedType}
                        id="changelogHeaderSelect"
                        key="ChangeLog-Header-Type-Select"
                    />
                </div>
                <div className="Changelog-header-dates-wrapper">
                    <div className="Changelog-header-date-wrapper">
                        <h4>Start date</h4>
                        <DatePicker
                            onChange={({ detail }) => {setStartDate(detail.value)}}
                            value={startDate}
                            placeholder="YYYY/MM/DD"
                            invalid = {startDateErrorRef.current === true}
                            id="changelogHeaderStartDate"
                            key="ChangeLog-Header-StartDate"
                            locale="en-CA"
                        />
                    </div>

                    <div className="Changelog-header-date-wrapper">
                        <h4>End date</h4>
                        <DatePicker
                            onChange={({ detail }) => setEndDate(detail.value)}
                            value={endDate}
                            placeholder="YYYY/MM/DD"
                            invalid = {endDateErrorRef.current === true}
                            id="changelogHeaderEndDate"
                            key="ChangeLog-Header-EndDate"
                            locale="en-CA"
                        />
                    </div>
                </div>
                <div>
                    <Button onClick={generateChangeLogs} id="changelogHeaderSubmitButton" key="ChangeLog-Header-Submit-Button">Submit</Button>
                </div>
            </div>

            <div className="Changelog-header-error-wrapper">
                <FormField className="pt-2 px-2 fw-bold error-label" errorText={errorText}/>
            </div>
        </div>
        
        
    );
};


export default ChangeLog;