import React, {useEffect, useState, useRef} from "react";
import {Col, Row, Button, Form} from "react-bootstrap";
import {FormField} from "@amzn/awsui-components-react";
import Dropdown from 'react-bootstrap/Dropdown';
import Icon from "@mdi/react";
import { mdiChevronUp } from '@mdi/js';
import { mdiPencil } from '@mdi/js';
import { mdiUndo } from '@mdi/js';
import { mdiCheck } from '@mdi/js';
import { mdiClose } from '@mdi/js';
import { mdiPlusCircleOutline} from "@mdi/js";
import { mdiMagnify } from '@mdi/js';
import "../assets/css/components/Process.css";

export default function ProcessAttributes(props) {
    const [attribute, setAttribute] = useState(props.attribute);
    const [searchingValues, setSearchingValues] = useState(false);
    const [valueFilter, setValueFilter] = useState("");
    const [newAttributeName, setNewAttributeName] = useState("");
    const [addingAttributeValue, setAddingAttributeValue] = useState(false);
    const [editingAttributeName, setEditingAttributeName] = useState(false);
    const [newAttributeValue, setNewAttributeValue] = useState("");
    const [tempAttributeValues, setTempAttributeValues] = useState(new Map());
    const [attributeValuesFiltered, setAttributeValuesFiltered] = useState(props.attribute.attributeValues);
    const [showDropdown, setShowDropdown] = useState(false);
    const [disableAddNewValues, setDisableAddNewValues] = useState(props.attribute.attributeName === "");

    const dropdownMenuComponentRef = useRef(null);
    const placeholderRef = useRef(null);
    const attributes = props.attributes;
    const setAttributes = props.setAttributes;
    const setDisableProcessSave = props.setDisableProcessSave;
    const setRoles = props.setRoles;
    const setRolesDiffMap = props.setRolesDiffMap
    const setProductFamilyTypeOptions = props.setProductFamilyTypeOptions;
    const setProductFamilyTypeDiffMap = props.setProductFamilyTypeDiffMap;
    const index = props.index;
    const setDisableAddAttribute = props.setDisableAddAttribute;
    const updateMessage = props.updateMessage;

    const removeFromAttributesList = ()=>{
        let newAttributeObj={
            ...attribute,
            remove: true
        };
        let removedAttributeOrder = newAttributeObj.attributeOrder;
        setAttribute(newAttributeObj);
        let attrs = [...attributes];
        attrs[index] = newAttributeObj;
        for (let i = index+1; i < attrs.length; i++){
            if (attrs[i].remove){
                continue;
            }
            attrs[i].attributeOrder = removedAttributeOrder;
            removedAttributeOrder += 1;
        }
        attrs.filter(attr => ((attr.attributeValues.length === 0 || attr.attributeValues[0] === "")
            && attr.attributeName === '' && !attr.remove)).length > 0 ?
            setDisableAddAttribute(true) : setDisableAddAttribute(false);
        setAttributes(attrs);
        setDisableProcessSave(false);
    }

    const updateAttributeName = (value) => {
        if (value.match(/[#%{}^*.@[\]]/)) {
            updateMessage("Special characters []#%{}^*.@ are not allowed.", "error");
            return;
        }
        let newAttributeObj = {
            ...attribute,
            attributeName: value,
            attributeNameError: ""
        };
        setAttribute(newAttributeObj);
        let attrs = [...attributes];
        attrs[index] = newAttributeObj;
        attrs.filter(attr => ((attr.attributeValues.length === 0 || attr.attributeValues[0] === "")
            && attr.attributeName === '' && !attr.remove)).length > 0 ?
            setDisableAddAttribute(true) : setDisableAddAttribute(false);
        setAttributes(attrs);
        setDisableProcessSave(false);
    };

    const updateAttributeValues = (nextAttrValues, nextAttrDiffMap) => {
        let newAttributeObj = {
            ...attribute,
            attributeValues: nextAttrValues,
            attributeValuesError: "",
            attributeValuesDiffMap: nextAttrDiffMap
        };

        setAttribute(newAttributeObj);
        if (attribute.attributeName === "Role"){
            setRoles(nextAttrValues);
            setRolesDiffMap(nextAttrDiffMap);
        }
        if (attribute.attributeName === "Product Family Type"){
            setProductFamilyTypeDiffMap(nextAttrDiffMap);
            setProductFamilyTypeOptions(nextAttrValues.map((v)=>{return {label: nextAttrDiffMap.get(v).oldValue, value: v}}));
        }
        let attrs = [...attributes];
        attrs[index] = newAttributeObj;
        attrs.filter(attr => ((attr.attributeValues.length === 0 || attr.attributeValues[0] === "")
            && attr.attributeName === '' && !attr.remove)).length > 0 ?
            setDisableAddAttribute(true) : setDisableAddAttribute(false);
        setAttributes(()=>{
            return attrs;
        });
        setDisableProcessSave(false);
    };

    const addAttributeValue = (newValue) => {
        newValue = newValue.trim();
        if (newValue !== ""){
            // check for special characters
            if (newValue.match(/[#%{}^*.@[\]]/)) {
                updateMessage("Special characters []#%{}^*.@ are not allowed.", "error");
                return;
            }
            // check for duplicates
            let duplicate = attribute.attributeValues.filter((attrVal)=>{return attrVal.toLowerCase()===newValue.toLowerCase()});
            if (duplicate.length>0){
                updateMessage("Cannot add attribute value because of duplicate", "error");
                return;
            }
            let nextAttrValues = [...attribute.attributeValues];
            nextAttrValues.unshift(newValue);
            let nextAttrValueDiffMap = new Map(attribute.attributeValuesDiffMap);
            nextAttrValueDiffMap.set( newValue, {
                    oldValue: "",
                    modifyType: "ADD",
                    persisted: false
                }
            )
            updateAttributeValues(nextAttrValues, nextAttrValueDiffMap);
        }
    }

    const renameAttributeValue = (oldValue, newValue) => {
        let nextAttrValues = [...attribute.attributeValues];
        nextAttrValues = nextAttrValues.map((v)=>{return v===oldValue? newValue : v});
        let oldInfo = attribute.attributeValuesDiffMap.get(oldValue);
        let nextAttrValueDiffMap = new Map(attribute.attributeValuesDiffMap);
        nextAttrValueDiffMap.delete(oldValue);
        if (oldInfo.persisted){
            nextAttrValueDiffMap.set( newValue, {
                    ...oldInfo,
                    modifyType: "RENAME"
                }
            )
        }else{
            nextAttrValueDiffMap.set(newValue, {
                ...oldInfo,
                modifyType: "ADD"
            })
        }
        updateAttributeValues(nextAttrValues, nextAttrValueDiffMap);
    }

    const undoRenameAttributeValue = (currentValue, diffmapInfo) => {
        let targetValue = diffmapInfo.oldValue;
        let nextAttrValues = [...attribute.attributeValues];
        nextAttrValues = nextAttrValues.map((v)=>{return v===currentValue? targetValue : v});
        let nextAttrValueDiffMap = new Map(attribute.attributeValuesDiffMap);
        nextAttrValueDiffMap.delete(currentValue);
        if (diffmapInfo.persisted){
            nextAttrValueDiffMap.set( targetValue, {
                    oldValue: targetValue,
                    modifyType: "NONE",
                    persisted:true
                }
            )
            updateAttributeValues(nextAttrValues, nextAttrValueDiffMap);
        }else{
            console.log("no need to undo rename for a non-persisted value");
        }
    }

    const deleteAttributeValue = (value)=>{
        let nextAttrValues = [...attribute.attributeValues];
        nextAttrValues = nextAttrValues.filter((v)=>{return v!==value});
        let nextAttrValueDiffMap = new Map(attribute.attributeValuesDiffMap);
        nextAttrValueDiffMap.delete(value);
        updateAttributeValues(nextAttrValues, nextAttrValueDiffMap);
    }

    const handleClickSearch = ()=>{
        setSearchingValues(true);
        setShowDropdown(true);
    }

    const handleDropdownButtonClick = ()=>{
        if (showDropdown){
            setSearchingValues(false);
        }else{
            setSearchingValues(true);
        }
        setShowDropdown(!showDropdown);
    }

    const filterAttributeValues = (inputValueFilter) => {
        setValueFilter(inputValueFilter);
    }

    useEffect(()=>{
        setAttributeValuesFiltered(()=>{
            let newFilteredValues = attribute.attributeValues.filter((val)=>{return val.toLowerCase().includes(valueFilter.toLowerCase())});
            return newFilteredValues;
        });
    }, [attribute.attributeValues, valueFilter])

    const setTempEditingValue= (oldval, newval) => {
        setTempAttributeValues(prevState=>new Map(prevState).set(oldval, newval));
    }

    const handleClickAttrRename = ()=>{
        let newAttributeNameTrim = newAttributeName.trim();
        if (editingAttributeName && newAttributeNameTrim!==""){
            // check if the renamed takes no effect
            if (newAttributeNameTrim === attribute.attributeName){
                setEditingAttributeName(!editingAttributeName);
                return;
            }
            // check if the renamed string will cause duplicate attr names
            let duplicate = attributes.filter((attr)=>{return !attr.remove && attr.attributeName.toLowerCase()===newAttributeNameTrim.toLowerCase()});
            if (duplicate.length>0){
                updateMessage("Cannot rename attribute with duplicate name", "error");
                return;
            }
            setDisableAddNewValues(false);
            updateAttributeName(newAttributeNameTrim);
        }
        setEditingAttributeName(!editingAttributeName);
    }

    const handleConfirmRename = (value)=>{
        let newval = tempAttributeValues.get(value);
        newval = newval.trim();
        setTempAttributeValues((prevState)=>{
            let nextState = new Map(prevState);
            nextState.delete(value);
            return nextState;
        });
        if (value !== newval){
            // check for special characters
            if (newval.match(/[#%{}^*.@[\]]/)) {
                updateMessage("Special characters []#%{}^*.@ are not allowed.", "error");
                return;
            }
            // check for possible duplicate attr values
            let duplicate = attribute.attributeValues.filter((attrVal)=>{return attrVal.toLowerCase()===newval.toLowerCase()});
            if (duplicate.length>0){
                updateMessage("Cannot rename attribute value because of duplicate", "error");
                return;
            }
            renameAttributeValue(value, newval);
        }
    }

    const handleCancelRename = (value)=>{
        setTempAttributeValues((prevState)=>{
            let nextState = new Map(prevState);
            nextState.delete(value);
            return nextState;
        });
    }

    const handleUndoRename = (value, diffmapInfo)=>{
        undoRenameAttributeValue(value, diffmapInfo);
    }

    const handleClickRename = (value)=>{
        setTempAttributeValues(prevState=>new Map(prevState).set(value, value));
    }

    const handleClickDelete = (value)=>{
        if (attribute.attributeValuesDiffMap.has(value)){
            let diffmapInfo = attribute.attributeValuesDiffMap.get(value);
            if (!diffmapInfo.persisted){
                deleteAttributeValue(value);
            }
        }
    }

    const handleClickAddAttributeValue = ()=>{
        setAddingAttributeValue(true);
        setShowDropdown(true);
        setSearchingValues(true);
    }

    const handleEnterNewValue = ()=>{
        setShowDropdown(true);
        setSearchingValues(true);
        addAttributeValue(newAttributeValue);
        setNewAttributeValue("");
    }

    const handleDeleteAttribute = ()=>{
        if (! (attribute.hasOwnProperty("attributeId") && attribute.attributeId !== "")){
            // not persisted attribute can be deleted
            removeFromAttributesList();
        }
    }

    useEffect(()=>{
        if (dropdownMenuComponentRef.current){
            let targetHeight=dropdownMenuComponentRef.current.clientHeight;
            placeholderRef?.current.style.setProperty("--dynamic-menu-height", targetHeight+"px");
        }
    });

    return (
        <React.Fragment>
        <Row className="p-3 mb-3 justify-content-md-center row-control">
            <Col lg={4} className="d-flex justify-content-md-center align-items-center py-2">
                    <Button variant="light" className="attribute-name-btn">
                        {editingAttributeName?
                            <Form.Control
                                className="attribute-name-input align-self-center"
                                value={newAttributeName} 
                                onChange={(event) => {setNewAttributeName(event.target.value)}}
                                placeholder="edit new name" />
                            :
                            <React.Fragment>
                                {attribute.attributeName === "" ? 
                                    <div className="attribute-name-placeholder">
                                        New Attribute
                                    </div>
                                    :
                                    <div>
                                        {attribute.attributeName + (attribute.coreAttribute? "*":"")}
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </Button>
                    {attribute.hasOwnProperty("attributeId") && attribute.attributeId !== "" ?
                        undefined
                        :
                        <Button variant="light" 
                                className="mx-1 align-items-center justify-content-md-center"
                                onClick={()=>{handleClickAttrRename()}}>
                            {editingAttributeName?
                                <Icon path={mdiCheck} size={1.5} /> 
                                :
                                <Icon path={mdiPencil} size={1.5} />   
                            }
                        </Button>
                    }
            </Col>

            <Col lg={4} className="d-flex">
                <Dropdown show={showDropdown} className="d-flex attribute-values-dropdown justify-content-md-center">
                    <Row className="d-flex align-items-center">
                        <Col className="d-flex">
                            <Dropdown.Toggle    variant="light"
                                                className="attribute-dropdown-toggle"
                                                onClick={handleClickSearch}
                                                size="lg"
                                                >
                                {searchingValues?
                                    <Form.Control
                                        className="attribute-dropdown-input"
                                        value={valueFilter} 
                                        onChange={(event) => {filterAttributeValues(event.target.value)}}
                                        placeholder="Type to search" />
                                    :
                                    <Col className="d-flex justify-content-center">
                                        <Col lg={2}>
                                            <Icon path={mdiMagnify} size={1.5}/>
                                        </Col>
                                        <Col lg={8}>
                                            <span> Click to search </span>
                                        </Col>
                                    </Col>
                                }
                            </Dropdown.Toggle>
                            <Button variant="light" 
                                    className="align-items-center justify-content-md-center attribute-dropdown-open-btn"
                                    onClick={handleDropdownButtonClick}>
                                <Icon   path={mdiChevronUp} 
                                        id={"dropdown-arrow-"+index}
                                        className={`attribute-dropdown-arrow ${showDropdown ? "open" : ""}`} 
                                        size={1.5} />
                            </Button>
                        </Col>
                    </Row>
                    <Dropdown.Menu className="attribute-dropdown-menu" flip={false} ref={dropdownMenuComponentRef}>
                        {
                            attributeValuesFiltered.map((value, index) => {
                                if (!attribute.attributeValuesDiffMap.has(value)){
                                    return (undefined)
                                }
                                let attrValInfo = attribute.attributeValuesDiffMap.get(value);
                                let persisted = attrValInfo.persisted;
                                let modifyType = attrValInfo.modifyType;
                                let isEditing = tempAttributeValues.has(value);
                                return (
                                    <Row className="d-flex" key={index}>
                                        <Row className="d-flex">
                                            {/* input box or values */}
                                            {isEditing?
                                                <Col lg={9} className="d-flex">
                                                    <Form.Control
                                                        value={tempAttributeValues.get(value)} 
                                                        onChange={(event) => {setTempEditingValue(value, event.target.value)}}
                                                        placeholder="rename" 
                                                    />
                                                </Col>:
                                                <Col lg={9} className="d-flex flex-shrink-1"> 
                                                    <p className={`attribute-value-text mx-3 ${persisted && modifyType === "NONE" ? "" : "attribute-new-item-text"}`}>{value}</p>
                                                </Col>
                                            }
                                            <Col lg={2} className="d-flex"></Col>
                                            {/* control buttons */}
                                            {isEditing ? 
                                                <Col lg={1} className="d-flex justify-content-end">
                                                    <Button variant="light" onClick={()=>{handleConfirmRename(value)}}>
                                                        <Icon path={mdiCheck} size={1} />
                                                    </Button>
                                                    <Button variant="light" onClick={()=>{handleCancelRename(value)}}>
                                                        <Icon path={mdiClose} size={1} />
                                                    </Button>
                                                </Col>:
                                                <React.Fragment>
                                                    {persisted?
                                                        <Col lg={1} className="d-flex justify-content-end">
                                                            {/* persisted: show rename button or undo button */}
                                                            {modifyType === "NONE" ? 
                                                                <Button variant="light" onClick={()=>{handleClickRename(value)}}>
                                                                    <Icon path={mdiPencil} size={1} />
                                                                </Button>
                                                                : 
                                                                <Button variant="light" onClick={()=>{handleUndoRename(value, attrValInfo)}}>
                                                                    <Icon path={mdiUndo} size={1} />
                                                                </Button>
                                                            }
                                                        </Col> 
                                                        :  
                                                        <Col lg={1} className="d-flex justify-content-end">
                                                            {/* not persisted: show rename button and delete button */}
                                                            <Button variant="light" onClick={()=>{handleClickRename(value)}}>
                                                                <Icon path={mdiPencil} size={1} />
                                                            </Button>
                                                            <Button variant="light" onClick={()=>handleClickDelete(value)}>
                                                                <Icon path={mdiClose} size={1} />
                                                            </Button>
                                                        </Col>
                                                    }
                                                </React.Fragment>
                                            }
                                        </Row>
                                    </Row>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </Col>

            <Col lg={4} className="d-flex justify-content-center">
                <Col lg={2}></Col>
                <Col lg={8} className="d-flex justify-content-center">
                    {addingAttributeValue?
                        <React.Fragment>
                            <Form.Control
                                className="attribute-value-adding-input"
                                value={newAttributeValue}
                                onChange={(event)=>setNewAttributeValue(event.target.value)}
                                onKeyDown={(event)=>{if (event.key==="Enter"){handleEnterNewValue()}}}
                                placeholder="enter new attribute values"
                                />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Button variant="outline-primary" 
                                    className="add-attribute-values-btn" 
                                    size="lg" 
                                    onClick={()=>{handleClickAddAttributeValue()}}
                                    disabled={disableAddNewValues}>
                                <Icon className="mx-2" path={mdiPlusCircleOutline} size={1.3} />
                                Add New Values
                            </Button>
                        </React.Fragment>
                    }
                </Col>
                <Col lg={2} className="d-flex">
                    {attribute.hasOwnProperty("attributeId") && attribute.attributeId !== "" ?
                            undefined
                            :
                            <Button variant="outline-primary" size="lg" onClick={handleDeleteAttribute}>
                                <Icon path={mdiClose} size={1.5}/>
                            </Button>
                    }
                </Col>
            </Col>
        </Row>
        {/* error text fields */}
        <Row className="d-flex justify-content-md-center align-items-center">
            <Col className="d-flex justify-content-md-center align-items-center" lg={4}>
                <FormField errorText={attributes[index].attributeNameError}></FormField>
            </Col>
            <Col className="d-flex justify-content-md-center align-items-center" lg={4}>
                <FormField errorText={attributes[index].attributeValuesError}></FormField>
            </Col>
            <Col className="d-flex justify-content-md-center align-items-center" lg={4}></Col>
        </Row>
        {/* placeholder for the animation of expanding dropdown */}
        <Row className={`attribute-menu-placeholder ${showDropdown ? "open":""}`} ref={placeholderRef}>
        </Row>
        </React.Fragment>
    )

}