import { Alert, Button, Modal } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { applyBlueThemeToButton } from '../common/Utils';

const DeleteKeysDialogContent = (props) => {
    const [areKeysBeingDeleted, setAreKeysBeingDeleted] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [enableSubmit, setEnableSubmit] = useState(true);

    const handleDelete = async () => {
        setAreKeysBeingDeleted(true);
        setEnableSubmit(false);
        setAlertMessage({
            type: 'info',
            content: 'Deleting keys...'
        });
        const response = await props.handleClickBulkDeleteConfirmed();
        setAreKeysBeingDeleted(false);
        if(response?.status) {
            setAlertMessage({
                type: 'success',
                content: `Key delete operation complete. Deleted keys: ${response?.successfulKeys ?? 0}, Failed to delete keys: ${response?.failedKeys ?? 0}.`
            });
        }
        else { //Allow a retry
            setAlertMessage({
                type: 'error',
                content: `Unable to Bulk delete keys. ${response.errorMessage}.`
            });
            setEnableSubmit(true);
        }
    };

    return (
        <div id="delete-keys-dialog-content">
            <Modal visible={true} header="Delete Keys" id='modal-dialog-wrapper' footer={
                    <div style={{float: "right"}} id="DeleteKeysDialogContent-submit-wrapper">
                        <Button className={applyBlueThemeToButton(enableSubmit)} disabled={!enableSubmit} onClick={handleDelete}>Confirm Delete</Button>
                    </div>
                }
                onDismiss={() => {if(!areKeysBeingDeleted) props.handleDialogClose(); }}
            >
                <div id="DeleteKeysDialogContent">
                    <div id='DeleteKeysDialogContent-alert-info' style={{marginBottom: "2rem"}}>
                        {
                            alertMessage && <Alert type={alertMessage.type} header={alertMessage.content} onDismiss={() => setAlertMessage(null)} />
                        }
                    </div>
                    <div className='Dialog-info-text'>
                        <h4>{"The action will delete "+ props.relevantKeys.length +" keys. This action is irreversible."}</h4>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DeleteKeysDialogContent;