import { FormField, Multiselect } from "@amzn/awsui-components-react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../assets/css/components/AttributeFilter.css";

export default function MultiAttributeFilter(props) {
    const [selectedValues,setSelectedValues] = useState(() => {
        if(props.attribute.selectedValues !== undefined)
            return props.attribute.selectedValues;
        else
            return props.attribute.selectedAttributes;
    });
    const [attribute,setAttribute] = useState(props.attribute);
    const setSelectedAttributeValues = props.setSelectedAttributeValues;
    const setSelectedFilter = props.setSelectedFilter;
    const resetMandatoryAttributesSelectionError = props.resetMandatoryAttributesSelectionError;
    const setEditFilter = props.setEditFilter;
    const keyOptions = props.keyOptions;
    const setKeyOptions = props.setKeyOptions;
    const index = props.index;

    /**
     * useEffect hook to update the selected values for the attribute
     */
     useEffect(() => {
         if(props.attribute.selectedValues !== undefined)
             setSelectedValues(props.attribute.selectedValues);
         else
             setSelectedValues(props.attribute.selectedAttributes);

    }, [props.attribute.selectedAttributes,props.attribute.selectedValues]);

    useEffect(()=>{
        setSelectedAttributeValues((prevState) => {
            if (!(attribute.value in prevState)) {
                prevState[attribute.value] = attribute.attributeValues.map((opt) => opt.value);
                if (!attribute.coreAttribute) {
                    prevState[attribute.value].push('');
                }
            }
            return prevState;
        });
    }, [attribute, setSelectedAttributeValues]);

    const updateSelectedValue = function (options) {
        resetMandatoryAttributesSelectionError();

        //If Changes is coming from Buljk edit page reset any selected filter
        if(setSelectedFilter !== undefined && setEditFilter!==undefined )
        {
            setSelectedFilter("");
            setEditFilter(false);
        }

        setSelectedValues(options);
        setAttribute({
            label: attribute.label,
            value:attribute.value,
            attributeValues:attribute.attributeValues,
            selectedAttributes:options,
            coreAttribute:attribute.coreAttribute

        });

        let _options = keyOptions;
        _options[index] = {
            label: attribute.label,
            value:attribute.value,
            attributeValues:attribute.attributeValues,
            selectedAttributes:options,
            coreAttribute:attribute.coreAttribute

        };
        setKeyOptions(_options);

        setSelectedAttributeValues((prevState) => {
            if (options.length === 0) {
                prevState[props.attribute.value] = attribute.attributeValues.map((opt) => opt.value);
                prevState[props.attribute.value].push('');
            } else {
                prevState[props.attribute.value] = options.map((opt) => opt.value);
            }
            return prevState;
        });

        if(props.setGenerateTable) { //Hide the Keys Dashboard on selection criteria change
            props.setGenerateTable(false);
        }
    };

    return (
        <Row  className="p-3  mb-3 justify-content-center  row-control px-lg-4 gap-5" key={ index}>
            <Col id={"filter-criteria-label-col-" +index} lg={2}
                 className={`d-flex  justify-content-end ${props.classNamePrefix}-multi-select-label-wrapper`}>
                <FormField id={"filter-criteria-label-" +index}  className="pt-2 px-2 fw-bold multi-select-title"
                           label={attribute.label}/>
            </Col>

            <Col id={"filter-criteria-values-multiselect-dropdown-col-" + index} lg={3} 
                className={`justify-content-left ${props.classNamePrefix}-multi-select-filter-wrapper`}>
                <Multiselect id={"filter-criteria-values-multiselect-dropdown-" + index}
                             placeholder="Not Specified"
                             options={attribute.attributeValues}
                             className="pt-2"
                             selectedOptions={selectedValues}
                             onChange={({detail}) => updateSelectedValue(detail.selectedOptions)}
                             filteringType="auto"
                             autoComplete={false}/>
            </Col>
        </Row>
    )
}