import React from "react";

import {CollectionPreferences} from "@amzn/awsui-components-react";

export const Preferences = (props) => (
    <CollectionPreferences
        id={props.elementId+"preferences"}
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={props.disabled}
        preferences={props.preferences}
        onConfirm={({ detail }) => props.setPreferences(detail)}
        pageSizePreference={{
            title: 'Page size',
                options: props.pageSizeOptions
        }}
        wrapLinesPreference={{
            label: 'Wrap lines',
                description: 'Check to see all the text and wrap the lines'
        }}
        visibleContentPreference={{
            title: 'Select visible columns',
                options: props.visibleContentOptions
        }}
    />
);