import React from "react";
import { Box } from '@amzn/awsui-components-react';
const EmptyState = ({title, subtitle, action}) => {
    return (
        <Box textAlign="center" color="inherit">
          <Box variant="strong" textAlign="center" color="inherit">
            {title}
          </Box>
          <Box variant="p" padding={{ bottom: 's' }} color="inherit">
            {subtitle}
          </Box>
          {action}
        </Box>
    );
};

export default EmptyState;