import {Amplify} from "aws-amplify";
export class ReportService {

    /**
     * Call service API to get csv sort order
     *
     * @param requestObj
     */

    static getSortOrderForProductFamilyTypeAndProcess(processId, productFamilyType) {
        return Amplify.API.get("ApproverMatrixAPI", `/sortorder?processId=${processId}&productFamilyType=${productFamilyType}`);
    }

    static saveCSVSortOrder(requestObj) {
        return Amplify.API.post("ApproverMatrixAPI", `/sortorder`, {body: requestObj});
    }
}