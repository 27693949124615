import React from "react";
import {Input, FormField} from "@amzn/awsui-components-react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import "../assets/css/components/Process.css";

export default function ProcessRoleSortTable(props) {
    const items = props.items;
    const setItems = props.setItems;
    // const setDisableSave = props.setDisableSave;
    // const renameRolesMap=props.renameRolesMap;
    const newRolesSet=props.newRolesSet;
    const [sortOrderErrors, setSortOrderErrors] = React.useState({});

    return (
        <TableContainer className="sort-order-table-container">
            <Table stickyHeader aria-label="sticky table" id="role-sort-table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><b className="sort-order-table-header">Role</b></TableCell>
                        <TableCell align="left"><b className="sort-order-table-header">Sort Order</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(items).map(([key, item], idx) => (
                        <TableRow key={key+item}>
                            <TableCell>
                                <p className={(newRolesSet.has(item.role)) ? "sort-order-table-new-cell" : "sort-order-table-cell"}>
                                {/* <p className="sort-order-table-cell"> */}
                                    {/* {renameRolesMap.has(item.role) ? `${renameRolesMap.get(item.role)}` : item.role} */}
                                    {item.role}
                                </p>
                            </TableCell>
                            <TableCell>
                                <FormField errorText={sortOrderErrors[idx] !== undefined && sortOrderErrors[idx] && "Invalid sort order specified."}>
                                    <Input onChange={(event) => {
                                        const updatedValue = Number(event.detail.value);
                                        const updatedItem = {...item, sortOrder: updatedValue};
                                        setItems(prevState => ({
                                            ...prevState,
                                            [key]: updatedItem
                                        }));
                                        setSortOrderErrors(prevState => ({
                                            ...prevState,
                                            [idx]: updatedValue < 1 || updatedValue > Object.keys(items).length
                                        }));
                                    }}
                                    value={item.sortOrder}
                                    onBlur={() => {
                                        if (sortOrderErrors[idx] !== undefined && sortOrderErrors[idx]) {
                                            const updatedItem = {...item, sortOrder: Number(key)};
                                            setItems(prevState => ({
                                                ...prevState,
                                                [key]: updatedItem
                                            }));
                                        } else {
                                            const otherItem = items[item.sortOrder];
                                            const otherInitialOrder = Number(otherItem.sortOrder);
                                            const updatedItem = items[key];
                                            const updatedOtherItem = {...otherItem, sortOrder: Number(key)};
                                            setItems(prevState => ({
                                                ...prevState,
                                                [key]: updatedOtherItem,
                                                [otherInitialOrder]: updatedItem
                                            }));
                                            // setDisableSave(false);
                                        }
                                        setSortOrderErrors(prevState => ({...prevState, [idx]: false}));
                                    }}
                                    type="number"/>
                                </FormField>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}