import { Button, FormField, Input } from "@amzn/awsui-components-react";
import { Amplify } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/components/Keys.css";
import { UserService } from "../../service/UserService";
import AdditionalInfo from "../AdditionalInfo";
import AttributeFilter from "../AttributeFilter";
import ConfirmationDialog from "../ConfirmationDialog";
import FilteredKeysTable from "../FilteredKeysTable";
import KeyDelegates from "../KeyDelegates";
import { ACTIVITY_TYPE_ATTR_VALUES_UPDATE } from "../common/Constants";
import { flattenKeys, validateNameCharacters } from "../common/KeyUtils";
import { applyBlueThemeToButton, verifyUpdateReasonSelected } from "../common/Utils";
import KeyCreationMultiAttribute from "./KeyCreationMultiAttribute";

/**
 * Page that loads when you try to create a new process or edit an existing process
 *
 * @param props properties needed to render the page
 * - mode: whether we are creating or editing a processs
 * - processId: the process id that is selected ,empty for a new process
 * - user: the user who is logged in
 * - attributes: list of all attributes for given process
 * @returns {JSX.Element}
 * @constructor
 */
export default function KeysView(props) {

    // get ID from the URL
    const user=  props.user;
    const updateMessage = props.updateMessage;
    const attributes  = props.attributes;
    const [selectedValues,setSelectedValues] = useState({});
    const [processId] = useState(props.process.processId);
    const process = props.process;
    const [keyAssignee,setKeyAssignee] = useState("");
    const [delegates,setDelegates] = useState([]);
    // eslint-disable-next-line
    const [validDelegateList,setValidDelegateList] = useState([]);
    const [assigneeName,setAssigneeName] = useState("");
    const [assigneeEmail,setAssigneeEmail] = useState("");
    const [assigneeFound, setAsigneeFound] = useState(false);
    const [assigneeAliasError, setAssigneeAliasError] = useState("");
    const [enableValidateButton, setEnableValidateButton] = useState(true);
    const MAX_DELEGATES_ALLOWED = 2;
    const history = useHistory();
    const currentDraftId = useRef("");
    const [draftName, setDraftName] = useState("");
    const [draftNameError, setDraftNameError] = useState("");
    const [generatingKeys, setGeneratingKeys] = useState(false);
    const [generateTable, setGenerateTable] = useState(false);
    const [keyCombinations, setKeyCombinations] = useState([]);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const displayActionsRow = useRef(false);
    const confirmationDialogContent = useRef(null);
    const attributesErrorRef = useRef(null);
    const assigneeErrorRef = useRef(null);
    const DRAFT_ACTION_CONSTANTS = {
        SAVE_DRAFT: 'save draft',
        DELETE_DRAFT: 'delete draft'
    };
    const simTicketIdRef = useRef("");
    const notesRef = useRef("");
    const updateReasonRef = useRef("");
    const [draftCriteria, setDraftCriteria] = useState({});

    /**
     * Validate whether the logged in User is authorized to create keys during the initial page load
     * Otherwise, initialize the empty delegates list
     */
    useEffect(() => {
        if(!user?.devAdmin && !process.processAdmins.includes(user?.userAlias)) {
            updateMessage("You are not authorized to create keys", "error");
            history.push("/");
        }
        //Initialize an empty delegates list
        constructDelegatesList([]);
    // eslint-disable-next-line
    }, []);

    const constructDelegatesList = (delegates) => {
        const delegatesList = delegates || [];
        const delegatesFound =[];
        for(let idx = delegatesList.length; idx < MAX_DELEGATES_ALLOWED; idx++) {
            delegatesList.push({alias:"", name:"", rank:idx + 1 , confirmationStatus:"CONFIRMED"});
            delegatesFound.push(true);
        }
        setDelegates(delegatesList);
        setValidDelegateList(delegatesFound);
    };

    /**
     * Retrieve the current draft details based on the draft id in the URL path, if the User visits an existing draft.
     */
    useEffect(() => {
        //Retrieve the info only when the draft id parameter is present in the URL
        let pathnameList = history.location.pathname.split("/");
        if(pathnameList.length >= 2 && pathnameList[pathnameList.length - 2] === 'draft') {
            Amplify.API.get("ApproverMatrixAPI", `/drafts/${pathnameList.pop()}?processId=${processId}`).then(
                response => {
                    currentDraftId.current = response.draftId;
                    setDraftName(response.draftName);
                    setSelectedValues(response.draftCriteria);
                    setDraftCriteria(response.draftCriteria);
                    if(response.draftAssignee) {
                        setKeyAssignee(response.draftAssignee);
                        setAssigneeName(response.draftAssigneeName);
                        setAssigneeEmail(response.draftAssigneeEmail);
                        setAsigneeFound(true);
                        setEnableValidateButton(false);
                    }
                    if(response.draftDelegates && response.draftDelegates.length) {
                        constructDelegatesList(response.draftDelegates.map(delegate => {return {...delegate, email: delegate.alias + "@amazon.com"}}));
                    }
                })
                .catch(err => {
                    updateMessage("Unable to retrieve the draft. Please try again", "error");
                });
        }
    // eslint-disable-next-line
    }, [history.location.pathname]);


    const getNameFromPeopleAPI = function(){
        UserService.validateUser(keyAssignee.toLowerCase()).then(
            response => {

                if (!response.validUser)
                {
                    setAsigneeFound(false);
                    setEnableValidateButton(true)
                    setAssigneeAliasError(`Unable to find alias ${keyAssignee} in system.`);
                }
                else{

                    UserService.getUser(keyAssignee.toLowerCase()).then(response=>{
                        setAssigneeName(response.userName);
                        setKeyAssignee(response.userAlias);
                        setAssigneeEmail(response.userAlias +"@amazon.com");
                        setAsigneeFound(true);
                        setAssigneeAliasError("");
                        setEnableValidateButton(false)
                    })
                }
            })
            .catch(err => {
                setAsigneeFound(false);
                setEnableValidateButton(true)
                setAssigneeAliasError(`Unable to find alias ${keyAssignee} in system.`);
            });
    }

    const isSameDelegateAssignee= function(delegatesList) {
        if(delegatesList.length === 0){
            return false;
        }
        else if(delegatesList.length === 1){
            return keyAssignee === delegates[0].alias;
        }
        else {
            let aliases = new Set([keyAssignee,delegates[0].alias,delegates[1].alias]);
            return aliases.size !== 3;
        }
    }

    const isValidDelegate = function(delegatesList) {
        if(delegatesList.length === 0) {
            return true;
        }
        else if(delegatesList.length === 1) {
            const idx = (delegatesList[0].rank !== undefined) ? delegatesList[0].rank - 1 : 0;
            return delegates[idx].alias !== "";
        }
        else{
            return delegates[0].alias !== "" && delegates[1].alias !== "";
        }
    }

    /**
     * Method to save keys / commit a draft
     */
    const saveKeys = async function() {
        //Retrieve the criteria in the expected format by the API, and validate them and the assignee
        if(!validateMandatoryFields(selectedValues)) {
            return;
        }
        //Validate the delegates
        const delegatesList = delegates.filter(delegate => delegate.name)
            .map(delegate => {return {...delegate, confirmationStatus: "CONFIRMED"}});
        if(isSameDelegateAssignee(delegatesList)){
            updateMessage("Key delegates and key assignee should be distinct","error");
        }
        else if(!isValidDelegate(delegatesList)){
            updateMessage("Select a valid delegate for key","error");
        }
        else if(!verifyUpdateReasonSelected(updateReasonRef.current, updateMessage)) {
            return;
        }
        else{
            // validate if it's okay to save keys
            try{
                let attributeList = Object.values(attributes);
                attributeList.forEach(attr => {attr.alias = user.userAlias});
                let result = await Amplify.API.post("ApproverMatrixAPI", "/background-activity", {
                    body: {
                        processId: processId,
                        activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                        attributeValidation: true,
                        attributes: attributeList
                    }
                });
                console.log(result);
                if (result.existUnfinishedActivity || !result.attributeValuesMatched){
                    updateMessage("Unable to save Keys because there are admins editing this process! Please come back later and refresh page", "error");
                    return;
                }
            }catch(err){
                console.log(err);
                updateMessage("Unable to save Keys because we cannot validate background activity and attribute values!", "error");
                return;
            }
            // ready to save keys
            let requestObj = {
                body: {
                    alias:user.userAlias,
                    processId:processId,
                    assigneeConfirmationStatus:"CONFIRMED",
                    keyConfirmed:true, 
                    keyDelegates: delegatesList,
                    keyAssignee:keyAssignee,
                    keyAssigneeName:assigneeName,
                    keyCriteria:selectedValues,
                    updateReason: updateReasonRef.current,
                    simTicketId: simTicketIdRef.current,
                    notes: notesRef.current,
                    ...(currentDraftId.current) && {draftId: currentDraftId.current},
                }
            };
    
            Amplify.API.post("ApproverMatrixAPI", `/keys`, requestObj)
                .then(response => {
                    updateMessage("Your request to create keys in bulk has been successfully submitted", "success");
                    history.push("/");
                })
                .catch(err => {
                    // on failure show error message
                    err?.response?.data?.responseMessage ?
                        updateMessage("Unable to save Keys! " + err.response.data.responseMessage, "error")
                        : updateMessage("Unable to save Keys! Ensure you have filled all required fields.", "error")
                });
        }
    }


    /**
     * Function to calculate the total possible keys for the values selected w.r.t the attributes by the User
     * Fo example, if values from 2 attributes are selected, one with 1 value selection, the other with 3 values selection,
     * then total max possible key combinations should be 1 * 3 = 3
     */
    const calculateTotalKeys = (selectedCriteria) => {
        let totalKeys = 0;
        for (const attributeId in selectedCriteria) {
            if(selectedCriteria[attributeId] && selectedCriteria[attributeId].length > 0) {
                if(totalKeys === 0) {
                    totalKeys = selectedCriteria[attributeId].length;
                }
                else {
                    totalKeys *= selectedCriteria[attributeId].length;
                }
            }
        }
        return totalKeys;
    };

    /**
     * Function to save a Draft
     * Validate the draft name, validate the selection of mandatory attributes and assignee,
     * construct the draft criteria object in the expected format,
     * include draft id if present and call the POST method to save
     */
    const saveDraft = async () => {
        if(!validateNameCharacters(draftName)) {//Validate the composition of draft name characters
            if(draftName.trim().length === 0) {
                setDraftNameError("Draft name cannot be empty");
                updateMessage("Draft name cannot be empty", "error");
            }
            else {
                setDraftNameError("Draft name should only contain alphanumerics or spaces");
                updateMessage("Draft name should only contain alphanumerics or spaces", "error");
            }
            if(currentDraftId.current) {
                attributesErrorRef.current.scrollIntoView();
            }
            return;   
        }
        else {
            setDraftNameError("");
        }
        
        //Validate the selection of mandatory attributes and a valid assignee and then proceed forwards 
        //to making the API call
        if(validateMandatoryFields(selectedValues)) {
            // validate background activity
            try{
                let attributeList = Object.values(attributes);
                attributeList.forEach(attr => {attr.alias = user.userAlias});
                let result = await Amplify.API.post("ApproverMatrixAPI", "/background-activity", {
                    body: {
                        processId: processId,
                        activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                        attributeValidation: true,
                        attributes: attributeList
                    }
                });
                console.log(result);
                if (result.existUnfinishedActivity || !result.attributeValuesMatched){
                    updateMessage("Unable to save Draft because there are admins editing this process! Please come back later and refresh page", "error");
                    return;
                }
            }catch(err){
                console.log(err);
                updateMessage("Unable to save Draft because we cannot validate background activity and attribute values!", "error");
                return;
            }
            // no background activity, ready to save 

            const delegatesList = delegates.filter(delegate => delegate.name)
                                    .map(delegate => {return {...delegate, confirmationStatus: "CONFIRMED"}});
            //Construct the request body
            const requestObj = {
                body: {
                        lastUpdatedUserAlias:user.userAlias,
                        lastUpdatedUserName: user.userName,
                        processId:processId,
                        draftDelegates: delegatesList,
                        ...(assigneeFound) && {draftAssignee: keyAssignee},
                        ...(assigneeFound) && {draftAssigneeName: assigneeName},
                        ...(assigneeFound) && {draftAssigneeEmail: assigneeEmail},
                        ...(currentDraftId.current) && {draftId: currentDraftId.current},
                        draftName: draftName.trim(),
                        draftCriteria: selectedValues,
                        totalKeys: calculateTotalKeys(selectedValues) + ""
                }
            };
            //POST request to save/update the draft
            Amplify.API.post("ApproverMatrixAPI", `/drafts`, requestObj)
                .then(response => {
                    setDraftNameError("");
                    updateMessage("Draft saved successfully!", "success");
                    history.push("/");
                })
                .catch(err => {
                    err?.response?.data?.errorMessage ?
                        updateMessage("Unable to save the Draft! " + err.response.data.errorMessage, "error")
                        : updateMessage("Unable to save the Draft! Please try again.", "error")
                    if(err?.response?.data?.errorMessage?.includes("Draft name")) {
                        setDraftNameError("Draft name is not unique");
                        if(currentDraftId.current) {
                            attributesErrorRef.current.scrollIntoView();
                        }
                    }
                });
        }
    };

    /**
     * Function check if each delegate is valid or not. Checks the boolean flag.
     *
     */
    const isAllDelegateValid = ()=>{
        for(const index in validDelegateList)
        {
            if(!validDelegateList[index]) return false;
        }
        return true;
    }

    /**
     * Method to Validate both the assignee and the mandatory attributes selection
     * @param criteria - the selected attributes criteria
     */
    const validateMandatoryFields = (criteria) => {
        //Validate the selection of mandatory attributes and a valid assignee
        if(!IsAllCoreAttributeSet(criteria)) {
            updateMessage("Select a value for all mandatory attributes","error");
            attributesErrorRef.current.scrollIntoView();
            return false;
        }
        else if(!assigneeFound) {
            updateMessage("Select a valid assignee for key","error");
            assigneeErrorRef.current.scrollIntoView();
            if(!currentDraftId.current) {
                setOpenConfirmationDialog(false);
            }
            return false;
        }
        else if(!isAllDelegateValid()) {
            updateMessage("Select a valid delegate for key","error");
            assigneeErrorRef.current.scrollIntoView();
            if(!currentDraftId.current) {
                setOpenConfirmationDialog(false);
            }
            return false;
        }
        return true;
    };

    /**
     * Wrapper function to save a draft
     * Validate the mandatory attributes value section before popping up the confirmation dialog to enter a draft name 
     * before saving the draft
     * If the current view is a draft view i.e the user is revisiting a saved draft, proceed towards saving the draft
     * Otherwise, if the current view is a fresh keys view, open a confirmation dialog with the draft name input
     */
    const confirmSaveDraft = () => {
        if(currentDraftId.current) {
            //In a draft view, i.e the User is revisiting a saved draft
            saveDraft();
        }
        else {
            //Open the dialog only when the assignee and mandatory attribute values are selected
            if(validateMandatoryFields(selectedValues)) {
                confirmationDialogContent.current = DRAFT_ACTION_CONSTANTS.SAVE_DRAFT;
                setOpenConfirmationDialog(true);
            }
        }
    };

    /**
     * Function to pop up the confirmation dialog for deleting a draft
     */
    const confirmDeleteDraft = () => {
        confirmationDialogContent.current = DRAFT_ACTION_CONSTANTS.DELETE_DRAFT;
        setOpenConfirmationDialog(true);
    };

    /**
     * Function to retrieve the current confirmation dialog content based on the action selected by the user
     */
    const generateConfirmationDialogContent = () => {
        switch(confirmationDialogContent.current) {
            case DRAFT_ACTION_CONSTANTS.SAVE_DRAFT: return generateDraftNameDialogContent();
            case DRAFT_ACTION_CONSTANTS.DELETE_DRAFT: return generateDeleteDraftContent();
            default: return null;
        }
    };

    /**
     * Function to generate the draft name content for the confirmation dialog.
     * Apart from the content from generateDraftNameContent(), include save and cancel buttons as the 
     * action buttons for the user
     */
    const generateDraftNameDialogContent = () => {
        return (
            <>
                {generateDraftNameContent()}
                <Row className="py-5 justify-content-md-center">
                    <Col lg={6} className="d-flex justify-content-center">
                        <Button onClick={()=> saveDraft()} className={applyBlueThemeToButton(true)}>Save</Button>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-center">
                        <Button onClick={()=> handleDialogClose()} className={`reset-all-button-wrapper`}>Cancel</Button>
                    </Col>
                </Row>
            </>
        );
    };

    /**
     * Function to close the confirmation dialog
     */
    const handleDialogClose = () => {
        setOpenConfirmationDialog(false);
    };

    /**
     * Function to delete a Draft
     */
    const deleteDraft = async () => {
        // validate background activity
        try{
            let attributeList = Object.values(attributes);
            attributeList.forEach(attr => {attr.alias = user.userAlias});
            let result = await Amplify.API.post("ApproverMatrixAPI", "/background-activity", {
                body: {
                    processId: processId,
                    activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                    attributeValidation: true,
                    attributes: attributeList
                }
            });
            console.log(result);
            if (result.existUnfinishedActivity || !result.attributeValuesMatched){
                updateMessage("Unable to delete Draft because there are admins editing this process! Please come back later and refresh page", "error");
                return;
            }
        }catch(err){
            console.log(err);
            updateMessage("Unable to delete Draft because we cannot validate background activity and attribute values!", "error");
            return;
        }
        //Construct the request body
        const requestObj = {
            body: {
                    lastUpdatedUserAlias:user.userAlias,
                    processId:processId,
                    draftId: currentDraftId.current,
                    draftName: draftName.trim()
                }
        };
        //POST request to save/update the draft
        Amplify.API.del("ApproverMatrixAPI", `/draft`, requestObj)
            .then(response => {
                updateMessage("Draft deleted successfully!", "success");
                history.push("/");
            })
            .catch(err => {
                err?.response?.data?.errorMessage ?
                    updateMessage("Unable to delete the Draft! " + err.response.data.errorMessage, "error")
                    : updateMessage("Unable to delete the Draft! Please try again.", "error");
            });
    };

    /**
     * Method to validate the selection of all the required attribute values
     * @param keyCriteria - criteria attributes in their expected format: 
     * {attributeId: ['value1', 'value2'], attributeId2: ['value1', 'value2']}
     */
    const IsAllCoreAttributeSet = function (keyCriteria){
        let coreAttributes = [];
        Object.values(attributes).forEach(attribute =>  {
            if(attribute.coreAttribute)
                coreAttributes.push(attribute.attributeId);

        });
        let selectedAttributes = Object.keys(keyCriteria);
        return coreAttributes.every(val => selectedAttributes.includes(val) && keyCriteria[val].length);
    };

    /**
     * Handler to update the change in draft name input
     */
    const handleDraftNameChange = (event) => {
        setDraftName(event.detail.value);
    };



    /**
     * Function to generate key combinations of the selected key criteria combinations in the create keys / view draft page,
     * when the user selects the "Generate Keys" action
     * @param selectedValues : selected values in the format: {"id1": ["v1", "v2"], "id2": ["v3"]}
     * @returns list of key combinations in the format: [{""}]
     */
    const generateKeyCombinations = (selectedValues) => {
        const keyCombinations = [];
        let attributeIdsList = Object.keys(selectedValues);
        //Filter out attributes without any selected values. Empty attribute value arrays can be come across when the user 
        //visits the existing draft page view
        attributeIdsList = attributeIdsList.filter(id => selectedValues[id].length > 0);
        if(attributeIdsList.length === 0) {
            return [];
        }
        const attributesLength = attributeIdsList.length;

        /**
         * A DFS helper function to generate the key combinations.
         * Consider {id1: ["v1"], id2: ["v2", "v3"]} as the selectedValues map
         * The helper function traverses this in a DFS way which results in: {id1: "v1", id2: "v2"}, {id1:"v1", id2: "v3"}
         */
        const helper = (criteriaMap, index) => {
            //Index out of bounds case
            if(index >= attributesLength) {
                //Push a deep copy of the criteriaMap object to retain the combinations
                keyCombinations.push(
                    {
                        keyCriteria: JSON.parse(JSON.stringify(criteriaMap)),
                        keyAssignee: keyAssignee,
                        keyAssigneeName: assigneeName,
                        isKeyAssigneeActive: true,
                        keyDelegates: delegates
                    }
                );
                return;
            }

            const attributeId = attributeIdsList[index];//Current attributeId in the attributeIdsList list
            for(let idx = 0; idx < selectedValues[attributeId].length; idx++) {
                criteriaMap[attributeId] = selectedValues[attributeId][idx];
                helper(criteriaMap, index + 1);
            }
        }

        helper({}, 0);
        return keyCombinations;
    };

    /**
     * Function to generate a matrix of keys for the selected criteria attributes in the create key / view draft page
     */
    const generateKeysMatrix = () => {
        setGeneratingKeys(true);
        displayActionsRow.current = true;
        setKeyCombinations(flattenKeys(generateKeyCombinations(selectedValues), attributes));
        setGenerateTable(true);
        setGeneratingKeys(false);
    };

    /**
     * Function to reset the selections of all the single and multi valued key criteria attributes
     */
    const resetAllSelections = () => {
        setSelectedValues({});
    };

    /**
     * Function to generate the content for the confirmation dialog in the case of save draft 
     * action selected by the user
     */
    const generateDraftNameContent = () => {
        return (
            <Row  className="p-1 mb-3 justify-content-md-center row-control draft-name-wrapper">
                <Col lg={2}
                    className={`d-flex  ${(currentDraftId.current ? "existing-" : "") + "draft-name-label-wrapper"}`}>
                    <FormField className="pt-2 px-2 fw-bold"
                            label="Draft Name"/>
                </Col>
                <Col lg={2} className={`justify-content-center ${(currentDraftId.current ? "existing-" : "") + "draft-name-input-wrapper"}`}>
                    <Input id="draft-name-input"
                                type='text'
                                className = "pt-2 px-4 justify-content-md-left"
                                value = {draftName}
                                onChange = {handleDraftNameChange}
                                autoComplete = {false}/>
                </Col>
                <Col lg={1} className={`justify-content-center pt-1 ${(currentDraftId.current ? "existing-" : "") + "draft-name-error-wrapper"}`}>
                    <FormField id= "username-filter-error-label "
                                className="pt-2 px-2 fw-bold error-label" 
                                errorText={draftNameError}/>
                </Col>
            </Row>
        );
    };

    /**
     * Function to generate the content for the confirmation dialog in the case of delete draft 
     * action selected by the user
     */
    const generateDeleteDraftContent = () => {
        return (
            <>
                <div className="delete-draft-title-wrapper">
                    <h4>Do you want to delete the draft?</h4>
                </div>
                <Row className="py-5 justify-content-md-center">
                    <Col lg={6} className="d-flex justify-content-center">
                        <Button onClick={()=> deleteDraft()} className={applyBlueThemeToButton(true)}>Confirm</Button>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-center">
                        <Button onClick={()=> handleDialogClose()} className="reset-all-button-wrapper"
                        >Cancel</Button>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <Container className="pt-4 pl-10 justify-content-lg-end create-keys-container">
            <div style={{float: "right"}}>
                <Button onClick={() => {history.push('/key/drafts')}} className={applyBlueThemeToButton(true)}>
                    Drafts
                </Button>
            </div>
            <Row className="pb-4 mt-5 justify-content-md-center " ref={attributesErrorRef}>
                <Col id ="attribute-header-col"lg={4} className="d-flex justify-content-center">
                    <React.Fragment>
                        <h2 id ="attribute-header">Create Keys</h2>
                    </React.Fragment>

                </Col>
            </Row>

            {   //Display the draft name input only in case the current view is a saved draft view
                currentDraftId.current ?
                    generateDraftNameContent()
                    :
                    null
            }

            <Row className="pb-4 mt-5 justify-content-md-center ">
                <Col id ="attribute-header-col"lg={4} className="d-flex justify-content-center">
                        <h3 id ="attribute-header">Select a single value</h3>
                </Col>
            </Row>

            {
                Object.values(attributes).filter(item => !item.multiValued).map((attribute,index) => {
                    let currentSelectedValue = {};
                    //After the initial page load, the attribute values might have been updated
                    //which modifies the selectedValues map from
                    //{id1: {label: "", value: ""}} to   {id1: ""}
                    //Should convert that back to {id1: {label: "", value: ""}} for the Single select filter
                    //to retain the selected value
                    if(selectedValues != null && Object.keys(selectedValues).length) {
                        if(typeof selectedValues[attribute.attributeId] === "string") {
                            currentSelectedValue = {
                                label: selectedValues[attribute.attributeId],
                                value: selectedValues[attribute.attributeId]
                            }
                        }
                        else if(Array.isArray(selectedValues[attribute.attributeId])) {
                            currentSelectedValue = selectedValues[attribute.attributeId].at(0);
                        }
                    }
                    return(
                        <AttributeFilter setSelectedValues = {setSelectedValues}
                            attribute = {attribute} selectedValues = {selectedValues}
                            index={index} key = {attribute.attributeId}
                            selectedValue = {currentSelectedValue}
                            classNamePrefix="create-keys"
                            displayResetButton
                            criteria={draftCriteria}
                        />
                    )
                })
            }

            <Row className="pb-4 mt-5 justify-content-md-center ">
                <Col id ="attribute-header-col"lg={4} className="d-flex justify-content-center">
                        <h3 id ="attribute-header">Select one or more values</h3>
                </Col>
            </Row>

            {
                //Make a deep copy of the multiValued attributes to avoid changing the
                //attribute state's attributeValues properties
                JSON.parse(JSON.stringify(Object.values(attributes).filter(item => item.multiValued))).map((attribute,index) => {
                    attribute.attributeValues = attribute.attributeValues.map(elem => ({label: elem, value: elem})).sort((a, b) =>
                            {
                                let l =  a.value.toLowerCase(), r = b.value.toLowerCase();

                                if (l < r) { return -1; }
                                if (l > r) { return 1; }
                                return 0;
                            }
                        );
                    attribute.label = attribute.attributeName + (attribute.coreAttribute ? "*" : "");
                    //After the initial page load, the attribute values might have been updated
                    //which modifies the selectedValues map from
                    //{id1: [{label: "", value: ""}, {label: "", value: ""}] } to  
                    //{id1: selectedAttributes: [{label: "", value: ""}, {label: "", value: ""}]}
                    //Should convert that back to{id1: [{label: "", value: ""}, {label: "", value: ""}] } for the Multi select filter
                    //to retain the selected values
                    let currentSelectedValues = [];
                    if(selectedValues !== null && Object.keys(selectedValues).length) {
                        if(Array.isArray(selectedValues[attribute.attributeId])) {
                            currentSelectedValues = selectedValues[attribute.attributeId];
                        }
                        else if(typeof selectedValues[attribute.attributeId] === "object") {
                            currentSelectedValues = selectedValues[attribute.attributeId].selectedAttributes
                        }
                    }
                    return(
                        <KeyCreationMultiAttribute setKeyOptions = {setSelectedValues}
                            attribute = {attribute} keyOptions = {selectedValues}
                            index={attribute.attributeId} key = {attribute.attributeId}
                            selectedValues={currentSelectedValues}
                            classNamePrefix="create-keys"
                            displaySelectAll
                            criteria={draftCriteria}
                        />
                    )
                })
            }

            <Row className="pt-4 pb-2m-4 justify-content-md-center">
                <Col lg={2} className="d-flex justify-content-center">
                    <Button onClick={()=> resetAllSelections()} className="reset-all-button-wrapper">Reset All</Button>
                </Col>
            </Row>

            <Row className="pt-4 justify-content-md-center " ref={assigneeErrorRef}>
                <Col id="assignee-header-col" lg={4} className="d-flex justify-content-center">
                    <React.Fragment>
                        <h2 id="assignee-header">Assignee*</h2>
                    </React.Fragment>

                </Col>
            </Row>
            <Row className="p-2 d-flex mb-3 mx-3 justify-content-md-center  row-control row " >
                <div className="p-2 d-flex justify-content-lg-left border border-dark border-primary keys-assignee-section-div">


                    <FormField className="name-label-field pt-3 px-4 fw-bold justify-content-end property-label" label="Alias: " />
                    <Input id="keyAssigneeInputBox"
                           type='text'
                           className = "pt-2 px-4 justify-content-md-left"
                           value = {keyAssignee}
                           onChange = {({detail}) => {
                               setAsigneeFound(false);
                               setEnableValidateButton(true)
                               setKeyAssignee(detail.value)}
                           }
                           autoComplete = {false}/>
                    <FormField id= "username-filter-error-label "
                               className="pt-2 px-2 fw-bold error-label"
                               errorText={assigneeAliasError} />

                    {
                        assigneeFound?
                            <React.Fragment>
                                <FormField className="pt-3 px-2 justify-content-lg-end property-label" label="Name: "/>
                                <FormField  id="keyAssigneeNameLabel" className="pt-3 px-2 justify-content-lg-left name-label" label={assigneeName}/>


                                <FormField  className = "pt-3 px-2 justify-content-lg-end property-label" label="Email: "/>
                                <FormField id="keyAssigneeEmailLabel" className="pt-3  px-2 justify-content-lg-left email-label" label={assigneeEmail}/>
                            </React.Fragment>
                            : undefined
                    }
                    <div className="btn-div" style={{paddingTop: "0.5rem"}}>
                        <Button disabled={!enableValidateButton}
                                className={applyBlueThemeToButton(enableValidateButton)}
                                onClick = {() => getNameFromPeopleAPI()
                                }>Validate</Button>
                    </div>
                </div>

            </Row>

            <Row className = "pt-4 pb-2m-4 justify-content-md-center ">
                <Col id="delegate-header-col" lg={4} className ="d-flex justify-content-center">
                    <h2 id="delegate-header">Delegates</h2>                  
                </Col>
            </Row>
            {
                delegates.map((delegate,index) => {
                    return (
                        <KeyDelegates delegate={delegate}
                                      setValidDelegateList={setValidDelegateList}
                                      validDelegateList = {validDelegateList}
                                      index={index}
                                      setDelegates = {setDelegates}
                                      delegates={delegates}
                                      disableRank />


                    )
                })
            }
            <Row className="py-5 justify-content-md-center">
                <Col lg={2} className="d-flex justify-content-center">
                    <Button  onClick={()=> generateKeysMatrix()} className={applyBlueThemeToButton(true)}>Generate Keys</Button>
                </Col>
            </Row>

            <Row>
            { 
                generateTable ?
                    <FilteredKeysTable  id = "filtered-keys-table"
                                        relevantKeys={keyCombinations}
                                        attributes = {attributes}
                                        enableExport={false}
                                        loading={generatingKeys}
                    />
                    :undefined
            }
            </Row>

            <Row className="pt-4 pb-2m-4 justify-content-md-center">
                {
                    displayActionsRow.current === true ?
                    <>
                        <AdditionalInfo simTicketIdRef={simTicketIdRef} notesRef={notesRef} updateReasons={props.updateReasons}
                                    updateReasonRef={updateReasonRef} justifyCenter marginBottom={"mb-5"}></AdditionalInfo>
                        <Col lg={2} className="d-flex justify-content-center">
                                <Button onClick={()=> saveKeys()} className={applyBlueThemeToButton(true)}
                                >{currentDraftId.current ? "Commit Draft" : "Create Keys"}</Button>
                        </Col>
                        <Col lg={2} className="d-flex justify-content-center">
                            <Button onClick={()=> confirmSaveDraft()} className={applyBlueThemeToButton(true)}
                            >{currentDraftId.current ? "Update Draft" : "Save as Draft"}</Button>
                        </Col>
                        {
                            currentDraftId.current ?
                                <Col lg={2} className="d-flex justify-content-center">
                                    <Button onClick={()=> confirmDeleteDraft()} className="reset-all-button-wrapper">Delete Draft</Button>
                                </Col>
                                :
                                null
                        }
                        <Col lg={2} className="d-flex justify-content-center">
                            <Link to="/">
                                <Button className="reset-all-button-wrapper"
                                >Cancel</Button>
                            </Link>
                        </Col>
                    </>
                    :
                    null
                }
            </Row>
            
            <ConfirmationDialog open={openConfirmationDialog} onClose={handleDialogClose}>
               {
                    generateConfirmationDialogContent()
               }
            </ConfirmationDialog>
        </Container>
    )
}