import { useCollection } from "@amzn/awsui-collection-hooks";
import { Table } from "@amzn/awsui-components-react";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

/**
 * Component responsible for displaying the RACI table
 * @param props props needed to display the table
 * - mode: whether editing or viewing
 * - associatedKeys: list of associatedKeys with the checkpoint
 * - delegateRank: max delegate rank to display
 * - selectedTabId: which tab to display
 * - roleAttributeId: attribute id for "Role"
 * - checkpoint: checkpoint being displayed (used to initialize overrides)
 * - responsibilityAttributeId: attribute id for "Responsibility"
 * - tableVals: table values set by this component
 * - setTableVals: table values set method
 * - maxDelegateRank: the maximum delegate rank for the associated keys
 * - checkpointOverridesAssignees: list of all overrides for assignees
 * - checkpointOverridesDelegates: list of all delegate overrides
 * - setCheckpointOverridesAssignees: method to update assignee overrides
 * - setCheckpointOverridesDelegates: method to update delegate overrides
 * - loading: whether table data is still being loaded
 * @returns {JSX.Element}
 * @constructor
 */
export default function RaciTable(props) {
    const mode = props.mode;
    const associatedKeys = props.associatedKeys;
    const generatedTable = props.generatedTable;
    const selectedTabId = props.selectedTabId;
    const roleAttributeId = props.roleAttributeId;
    const responsibilityAttributeId = props.responsibilityAttributeId;
    const tableVals = props.tableVals;
    const maxDelegateRank = props.maxDelegateRank;
    const setTableVals = props.setTableVals;
    const checkpointOverridesAssignees = props.checkpointOverridesAssignees;
    const checkpointOverridesDelegates = props.checkpointOverridesDelegates;
    const setCheckpointOverridesAssignees = props.setCheckpointOverridesAssignees;
    const setCheckpointOverridesDelegates = props.setCheckpointOverridesDelegates;
    const loading = props.loading;
    const page = props.page;
    const setPage = props.setPage;
    const rowsPerPage = 200;

    const phonetoolLinkURL = "https://phonetool.amazon.com/users";

    const NOT_AVAILABLE_TEXT = "Not Available";
    const NOT_APPLICABLE_TEXT = "Not Applicable";
    const DUMMY_VALUES = new Set([NOT_APPLICABLE_TEXT,NOT_AVAILABLE_TEXT]);
    const items = tableVals[selectedTabId]?.slice((page-1)*rowsPerPage, page*rowsPerPage) ?? [];

    // RACI table columns
    const [raciTableCols, setRaciTableCols] = useState([]);

    const getPageCount = () => {
        return tableVals[selectedTabId] !== undefined ? Math.ceil(tableVals[selectedTabId].length / rowsPerPage) : 1;
    }

    // method to update overrides deletes for assignees
    const updateKeyAssigneeDeleteOverride = (alias, responsibility, role, isDelete) => {
        const newItem = {userAlias: alias, responsibility: responsibility, role: role}
        let newOverridesAssignees = []
        if (!isDelete) {
            newOverridesAssignees = [...checkpointOverridesAssignees]
            newOverridesAssignees.push(newItem)
        }
        else {
            newOverridesAssignees = checkpointOverridesAssignees.filter(override => !(override.userAlias === alias &&
                override.role === role && override.responsibility === responsibility))
        }
        setCheckpointOverridesAssignees(newOverridesAssignees)
    }

    // method to update override deletes for delegates
    const updateKeyDelegatesDeleteOverride = (alias, responsibility, role, isDelete) => {
        const newItem = {userAlias: alias, responsibility: responsibility, role: role}
        let newOverridesDelegates = []
        if (!isDelete) {
            newOverridesDelegates = [...checkpointOverridesDelegates]
            newOverridesDelegates.push(newItem)
        }
        else {
            newOverridesDelegates = checkpointOverridesDelegates.filter(override => !(override.userAlias === alias && override.role === role &&
                override.responsibility === responsibility))
        }
        setCheckpointOverridesDelegates(newOverridesDelegates)
    }

    // method to set RACI table columns
    useEffect(() => {
        let newCols = [
            {
                id: "role",
                header: "Role",
                cell: e => e.role,
                width: 250,
                sortingField: "role",
                sortingDescending: false
            },
            {
                id: "assignee",
                header: "Assignee",
                cell: e => e.assignee,
                width: 250,
                minWidth: 250,
                maxWidth: 250
            }]

        for (let i = 1; i <= maxDelegateRank; i++) {
            newCols.push({id: `delegate${i}`, header: `Delegate ${i}`,
                cell: e => e[`delegate${i}`], width: 250, minWidth: 250, maxWidth: 250})
        }
        setRaciTableCols(newCols)
    }, [maxDelegateRank])

    // method to fetch RACI table values from associated keys
    // want to only have the dependencies below, not refresh on all dependencies
    useEffect(() => {
        let newTableVals = {}
        if (generatedTable) {
            // populate table with RACI values
            let data = {};
            responsibilityAttributeId?.values?.forEach(value => {
                data[value] = associatedKeys.filter(key => key.keyCriteria[responsibilityAttributeId.id] === value)
            });
            Object.keys(data).forEach((key,index) => {
                const dataVal = data[key];
                newTableVals[key] = dataVal.map(val => {
                    const currRole = val.keyCriteria[roleAttributeId.id]
                    const currResponsibility = val.keyCriteria[responsibilityAttributeId.id]
                    const currAssignee = val.keyAssignee
                    const currAssigneeName= val.keyAssigneeName
                    const currAssigneeActive = val.isKeyAssigneeActive
                    const hasAssigneeOverride = checkpointOverridesAssignees.some(assigneeOverride => (assigneeOverride.role === currRole
                        && assigneeOverride.responsibility === currResponsibility && assigneeOverride.userAlias === currAssignee))

                    let assigneeRow = (!DUMMY_VALUES.has(currAssignee) ?
                        (hasAssigneeOverride ?
                        <React.Fragment><div> <a id={"assigneeLink-"+index} href={`${phonetoolLinkURL}/${currAssignee}`} rel="noreferrer" target="_blank">
                            <strike>{currAssigneeName}</strike></a>{mode !== "view" ?
                            <div className="float-end"><Button id={"assigneeAddBackButton-"+index} variant="success" className="ms-3" onClick={() =>
                                updateKeyAssigneeDeleteOverride(currAssignee, currResponsibility,
                                    currRole,true)}>Add Back</Button></div>
                            : null}</div></React.Fragment> :
                        <React.Fragment>
                            {
                                currAssigneeActive ?
                                    <a id={"assigneeLink-"+index} href={`${phonetoolLinkURL}/${currAssignee}`} rel="noreferrer" target="_blank">{currAssigneeName}</a> :
                                    <span id={"assigneeName-"+index}>{currAssigneeName} (Inactive)</span>
                            }
                        </React.Fragment>)
                        :
                        (hasAssigneeOverride ?
                            <React.Fragment><div>
                                <strike>{currAssignee}</strike>{mode !== "view" ?
                                <div className="float-end"><Button variant="success" className="ms-3" onClick={() =>
                                    updateKeyAssigneeDeleteOverride(currAssignee, currResponsibility,
                                        currRole,true)}>Add Back</Button></div>
                                : null}</div></React.Fragment> :
                            <React.Fragment> <span>{currAssignee}</span>
                            </React.Fragment>)
                    )
                    let newTableRow = {
                        role: currRole,
                        responsibility: currResponsibility,
                        assignee: currAssignee ? assigneeRow : null,
                        assigneeAlias: currAssignee,
                        assigneeName:currAssigneeName,
                        // active status of employee
                        assigneeAliasActive: currAssigneeActive,
                        assigneeAliasDeleted: hasAssigneeOverride
                    }
                    val.keyDelegates.forEach(delegate => {
                        const currDelegate = delegate.alias
                        const currDelegateName = delegate.name;
                        const currDelegateActive = delegate.isActive;
                        const currRank = delegate.rank
                        const hasDelegateOverride = checkpointOverridesDelegates.some(delegateOverride => delegateOverride.role === currRole
                            && delegateOverride.responsibility === currResponsibility && delegateOverride.userAlias === currDelegate)

                        newTableRow[`delegate${currRank}`] = (!DUMMY_VALUES.has(currDelegate) ?
                            (hasDelegateOverride ?
                            <React.Fragment>
                                <a id={"delegate"+currRank+"Link-"+index} href={`${phonetoolLinkURL}/${currDelegate}`} rel="noreferrer" target="_blank"><strike>{currDelegateName}</strike></a>{mode !== "view" ?
                                <div className="float-end"><Button id={"delegate"+currRank+"AddBacKButton-"+index} variant="success" onClick={() =>
                                    updateKeyDelegatesDeleteOverride(currDelegate, currResponsibility, currRole,
                                        true)} className="ms-3">Add Back</Button></div> : null}
                            </React.Fragment> :
                            <React.Fragment>
                                {
                                    currDelegateActive ?
                                        <a id={"delegate"+currRank+"Link-"+index} href={`${phonetoolLinkURL}/${currDelegate}`} rel="noreferrer" target="_blank">{currDelegateName}</a>:
                                        <span id={"delegate"+currRank+"Name"+index}>{currDelegateName} (Inactive)</span>
                                }
                            </React.Fragment>) :

                        (hasDelegateOverride ?
                            <React.Fragment>
                               <strike id={"delegate"+currRank+"StrikeName-"+index}>{currDelegateName}</strike>{mode !== "view" ?
                                <div className="float-end"><Button variant="success" onClick={() =>
                                    updateKeyDelegatesDeleteOverride(currDelegate, currResponsibility, currRole,
                                        true)} className="ms-3">Add Back</Button></div> : null}
                            </React.Fragment> :
                            <React.Fragment><span id={"delegate"+currRank+"Name-"+index}>{currDelegateName}</span></React.Fragment>)
                    )
                        newTableRow[`delegate${currRank}Alias`] = currDelegate;
                        newTableRow[`delegate${currRank}Name`] = currDelegateName;
                        newTableRow[`delegate${currRank}AliasDeleted`] = hasDelegateOverride;
                        // active status of employee
                        newTableRow[`delegate${currRank}AliasActive`] = currDelegateActive;
                    })
                    return newTableRow;
                })
            });
            setTableVals(newTableVals);
        } // eslint-disable-next-line
    }, [generatedTable, associatedKeys, checkpointOverridesAssignees, checkpointOverridesDelegates, mode])

    const { items: retrievedKeys, collectionProps } = useCollection(items, {
        sorting: {
            defaultState: {
                sortingColumn: {
                    id: "role",
                    header: "Role",
                    cell: e => e.role,
                    width: 250,
                    sortingField: "role",
                    sortingDescending: false
                },
                isDescending: false
            }
        },
    });



    return (
        <Table
            id="RACITable"
            loadingText="Loading responsibility matrix"
            loading={loading}
            columnDefinitions={raciTableCols}
            {...collectionProps}
            pagination={
                <Pagination
                    id="create-checkpoint-table-pagination"
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                    currentPageIndex={page}
                    onChange={({ detail }) =>{
                        setPage(detail.currentPageIndex);
                    }}
                    pagesCount={getPageCount()}
                />
            }
            items={retrievedKeys}
        />
    )
}