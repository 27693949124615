import { FormField, Multiselect } from "@amzn/awsui-components-react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../assets/css/components/AttributeFilter.css";

export default function KeyCreationMultiAttribute(props) {
    const attribute = props.attribute;
    const setKeyOptions = props.setKeyOptions;
    const keyOptions = props.keyOptions ?? [];
    const [currentAttributeSelectedValues, setCurrentAttributeSelectedValues] = useState(props.attribute.selectedValues);
    const index = props.index;
    const SELECT_ALL_LABEL = "Select All";

    /**
     * Updates the value of the current attribute to the value retrieved as a part of response criteria.
     */
    useEffect(() => {
        if(!attribute || !props.criteria) return;
        if(props.criteria[attribute.attributeId]?.length > 0) {
            setCurrentAttributeSelectedValues(props.criteria[attribute.attributeId].map(value => {return {label: value, value: value}}));
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.criteria]);

    /**
     * Empties the value of the current attribute, in reaction to a Reset.
     */
    useEffect(() => {
        if(!attribute || !keyOptions) return;
        if(!keyOptions[index]) {
            setCurrentAttributeSelectedValues([]);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyOptions]);

    const updateSelectedValue = function (options) {

        //If the Select All option is selected, call the handleSelectAllValues()
        if(props.displaySelectAll) {
            for(let idx = 0; idx < options.length; idx++) {
                if(options[idx].label === SELECT_ALL_LABEL) {
                    handleSelectAllValues();
                    return;
                }
            }
        }

        setCurrentAttributeSelectedValues(options);
        const attributeSelectedValues = options.map(option => option.value) ?? [];
        const _options = JSON.parse(JSON.stringify(keyOptions));
        _options[index] = attributeSelectedValues;
        setKeyOptions(_options);
    };

    /**
     * Handler to select all the values for the current MultiSelect filter
     */
    const handleSelectAllValues = () => {
        //Set all the available values to reflect through chiclets, and selected in the drop-down
        setCurrentAttributeSelectedValues(attribute.attributeValues);
        //Update the parent components selected Values object
        const attributeSelectedValues = attribute.attributeValues?.map(option => option.value) ?? [];
        const _options = JSON.parse(JSON.stringify(keyOptions));
        _options[index] = attributeSelectedValues;
        setKeyOptions(_options);
    };

    /**
     * Function to generate the drop down options.
     * Append the select all option at the beginning in the event of presence of props.displaySelectAll
     */
    const generateOptionsList = () => {
        if(props.displaySelectAll) {
            return [{label: SELECT_ALL_LABEL, value: SELECT_ALL_LABEL}, ...attribute.attributeValues];
        }
        return attribute.attributeValues;
    };

    return (
        <Row  className="p-3  mb-3 justify-content-md-center gap-5 row-control px-lg-4" key={ index}>
            <Col id={"filter-criteria-label-col-" +index} lg={2}
                 className={`d-flex  justify-content-end ${props.classNamePrefix}-multi-select-label-wrapper`}>
                <FormField id={"filter-criteria-label-" +index}  className="pt-2 px-2 fw-bold "
                           label={attribute.label}/>
            </Col>

            <Col id={"filter-criteria-values-multiselect-dropdown-col-" + index} lg={3}
                 className={`justify-content-left ${props.classNamePrefix}-multi-select-filter-wrapper`}>
                <Multiselect id={"attributeSelectDropdown-" + attribute.label.replace(/\s/g, "")}
                             placeholder="Not Specified"
                             options={generateOptionsList()}
                             className="pt-2"
                             selectedOptions={currentAttributeSelectedValues}
                             onChange={({detail}) => updateSelectedValue(detail.selectedOptions)}
                             filteringType="auto"
                             autoComplete={false}/>
            </Col>
        </Row>
    )
}