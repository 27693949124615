import React, { useEffect, useState } from "react";
import {FormField, Select} from "@amzn/awsui-components-react";
import ProcessRoleSortTable from "./ProcessRoleSortTable";
import {Container, Button, Row, Col} from "react-bootstrap";
import {ReportService} from "../service/ReportService";
import Icon from "@mdi/react";
import { mdiArrowExpandUp } from '@mdi/js';
import { mdiArrowExpandDown } from '@mdi/js'
import { mdiPencil } from '@mdi/js';
// import _ from "lodash";
import "../assets/css/components/Process.css";

export default function ProcessRoleSortSection(props) {
    const [showSortOrder, setShowSortOrder] = useState(false);
    const [sortOrderRetrieved, setSortOrderRetrieved] = useState(false);
    // const [disableSave, setDisableSave] = React.useState(true);
    const [selectValueErrorText, setSelectValueErrorText] = React.useState("");
    const [sortOrderErrorText, setSortOrderErrorText] = useState("");
    const [renameRolesMap, setRenameRolesMap] = useState(new Map());
    const [newRolesSet, setNewRolesSet] = useState(new Set());
    const [newRolesRecord, setNewRolesRecord] = useState(new Map());
    const setDisableProcessSave = props.setDisableProcessSave;
    const processId = props.processId;
    const updateMessage = props.updateMessage;
    const roles = props.roles;
    const productFamilyTypeSelections = props.productFamilyTypeSelections;
    const rolesDiffMap = props.rolesDiffMap;
    const productFamilyTypeDiffMap = props.productFamilyTypeDiffMap;
    const items = props.sortOrderItems;
    const setItems = props.setSortOrderItems;
    const selectedProductFamilyType = props.selectedProductFamilyType;
    const setSelectedProductFamilyType = props.setSelectedProductFamilyType;
    const sortOrderItemsEdited = props.sortOrderItemsEdited;
    const setSortOrderItemsEdited = props.setSortOrderItemsEdited;
    const getRolesBeforeRename=props.getRolesBeforeRename;
    const getProductFamilyTypeBeforeRename=props.getProductFamilyTypeBeforeRename;

    const getRolesRenameMap = () => {
        // inverted index from old value to new value
        let retMap = new Map();
        rolesDiffMap.forEach(
            (v, k) => {
                if (v.modifyType === "RENAME"){
                    retMap.set(v.oldValue, k);
                }
            }
        );
        return retMap;
    }

    const initializeSortOrderItems = (inputRoles, inputProductFamilyType) =>{
        // exclude those unsaved new attribute values
        let newItems = {};
        let newOrder = 0;
        inputRoles.forEach((role) => {
                if (!(rolesDiffMap.get(role)?.modifyType === "ADD")){
                    // only persisted attribute values will be given a sort order
                    newItems[newOrder+1] = { role: role,
                        sortOrder: newOrder+1, 
                        processId: processId,
                        productFamilyType: inputProductFamilyType};
                    newOrder += 1;
                }
            }
        );
        // console.log(newItems);
        return newItems;
    }

    const getSortOrderByProductFamilyType = (inputOption, reset=false) => {
        let option = {...inputOption};
        option.value = getProductFamilyTypeBeforeRename(inputOption.value);
        // const clonedRoles = _.cloneDeep(roles);
        const clonedRoles = getRolesBeforeRename([...roles]);
        // if this option has already been editted
        if (newRolesRecord.has(option.value) && sortOrderItemsEdited.has(option.value) && !reset){
            setItems(sortOrderItemsEdited.get(option.value));
            setNewRolesSet(newRolesRecord.get(option.value));
            setSortOrderRetrieved(true);
            setShowSortOrder(true);
            return;
        }
        // else we fetch the sort order for that option from database
        ReportService.getSortOrderForProductFamilyTypeAndProcess(processId, option.value).then(
            response => {
                setSortOrderRetrieved(true);
                if (response.downloadOrder.length === 0) {
                    updateMessage('Please define a new sort order');
                    let savedRolesWithoutOrder = new Set(clonedRoles);
                    setNewRolesSet(savedRolesWithoutOrder);
                    setNewRolesRecord((prevState)=>{
                        return new Map(prevState).set(option.value, savedRolesWithoutOrder);
                    });
                    setRenameRolesMap(getRolesRenameMap());
                    let sortedItemsObj = initializeSortOrderItems(clonedRoles, option.value);
                    setItems(sortedItemsObj);
                    setSortOrderItemsEdited((prevState)=>{
                        return new Map(prevState).set(option.value, sortedItemsObj);
                    });
                    // setDisableSave(false);
                } else {
                    let downloadedRoles = response.downloadOrder.map((item)=>item.role.trim());
                    let downloadedRolesSet = new Set(downloadedRoles);
                    let clonedRolesSet = new Set(clonedRoles);
                    let errorRolesSet = new Set(downloadedRoles.filter(x => !clonedRolesSet.has(x)));
                    // if role values are defined in database, but not in current webpage's role values, leading to inconsistency
                    if (errorRolesSet.size > 0){
                        updateMessage("Getting invalid sort order from database: " + [...errorRolesSet].join(", ") + " are not defined roles, please redefine sort order", "error");
                        // setItems(initializeSortOrderItems(clonedRoles, option.value));
                        setItems({});
                        setSortOrderErrorText("found inconsistent sort order values, cannot display sort order");
                        // setDisableSave(true);
                    }else{
                        // roles saved but without an order definition -> rolesDiffMap shows its modify type is not ADD -> meaning this role value is saved
                        let savedRolesWithoutOrder = new Set(clonedRoles.filter(x => !(rolesDiffMap.get(x)?.modifyType === "ADD" ||
                                                                                       downloadedRolesSet.has(x)) ));
                        // console.log(savedRolesWithoutOrder);
                        // all the new roles, including non-persisted ones
                        // let unsavedRolesWithoutOrder = new Set(clonedRoles.filter(x => !downloadedRolesSet.has(x)));
                        setNewRolesSet(savedRolesWithoutOrder);
                        setNewRolesRecord((prevState)=>{
                            return new Map(prevState).set(option.value, savedRolesWithoutOrder);
                        });
                        setRenameRolesMap(getRolesRenameMap());
                        let sortedRoles = response.downloadOrder.sort((a, b) => a.sortOrder - b.sortOrder);
                        let savedRolesWithoutOrderList = [...savedRolesWithoutOrder].sort();
                        savedRolesWithoutOrderList.forEach( (x) => {
                                let newOrder = sortedRoles.length+1;
                                sortedRoles.push({
                                    role: x,
                                    sortOrder: newOrder,
                                    processId: processId,
                                    productFamilyType: option.value
                                });
                            }
                        );
                        // if (newRoles.length > 0){
                        //     setDisableSave(false);
                        // }
                        let sortedItemsObj = sortedRoles.reduce((a, v) => ({ ...a, [v.sortOrder]: v}), {});
                        setItems(sortedItemsObj);
                        setSortOrderItemsEdited((prevState)=>{
                            return new Map(prevState).set(option.value, sortedItemsObj);
                        });
                    }
                }
                setShowSortOrder(true);
            }
        ).catch(err => {
                console.log(err);
                updateMessage('Unable to display sort order due to server or network issue. Please try again in a few minutes.');
            }
        );
    };

    const openSortOrderTable = () => {
        if (selectedProductFamilyType.value === "") {
            updateMessage("Please select 'Product Family Type' to view sort order.", "error");
            setSelectValueErrorText("Please select one");
            return;
        }
        getSortOrderByProductFamilyType(selectedProductFamilyType);
        setDisableProcessSave(false);
    };

    const toggleSortOrderTable = ()=>{
        setShowSortOrder(!showSortOrder);
    }

    const getSortOrderTableTitle = ()=>{
        let title = "Sort Order for Product Family Type: ";
        let oldname = "";
        let rename = "";
        if (productFamilyTypeDiffMap.get(selectedProductFamilyType.value)?.modifyType === "RENAME"){
            let newname = selectedProductFamilyType.value;
            oldname = productFamilyTypeDiffMap.get(selectedProductFamilyType.value).oldValue;
            rename = ` (will be renamed to ${newname} if you save)`;
        }else{
            oldname = selectedProductFamilyType.value;
        }
        return title + oldname + rename;
    }

    const getPersistedProductFamilyTypeOptions = ()=>{
        return productFamilyTypeSelections.filter((option)=>{
            return !(productFamilyTypeDiffMap.get(option.value)?.modifyType === "ADD");
        });
    }

    useEffect(()=>{
        setShowSortOrder(false);
        setSortOrderRetrieved(false);
        setItems({});
        setSelectedProductFamilyType({ label: "Select one", value: "" });
    }, [productFamilyTypeSelections, roles, rolesDiffMap, productFamilyTypeDiffMap, setSelectedProductFamilyType, setItems])

    return (
        <Row className="p-2 d-flex mb-3 mx-3 justify-content-md-center  row-control row" >
            <Row className="pb-4 justify-content-md-center">
                {/* <Col lg={1}></Col> */}
                <Col lg={3} className="d-flex justify-content-center">
                    <FormField className="pt-2 px-2 fw-bold" label="Sort Attribute">
                    </FormField>
                </Col>
                <Col lg={3} className="d-flex justify-content-center">
                    <FormField className="pt-2 px-2 fw-bold" label="Based on">
                    </FormField>
                </Col>
                <Col lg={3} className="d-flex justify-content-center">
                    <FormField className="pt-2 px-2 fw-bold " label="Select Value">
                    </FormField>
                </Col>
                <Col lg={3} className="d-flex justify-content-center">
                    <FormField className="pt-2 px-2 fw-bold " label="Review & Edit">
                    </FormField>
                </Col>
                {/* <Col lg={1}></Col> */}
            </Row>
            <Row className="pb-4 justify-content-md-center">
                {/* <Col lg={1}></Col> */}
                <Col lg={3} className="d-flex justify-content-center">
                    <Col lg={4} className="d-flex justify-content-center">
                        <h3 className="p-2 m-1">Role</h3>
                    </Col>
                </Col>
                <Col lg={3} className="d-flex justify-content-center">
                    <Col lg={10} className="d-flex justify-content-center">
                        <h3 className="p-2 m-1">Product Family Type</h3>
                    </Col>
                </Col>
                <Col lg={3} className="d-flex justify-content-center">
                    <Col lg={8} className="d-flex justify-content-center">
                        <Row>
                            <Select selectedOption={selectedProductFamilyType}
                                    onChange={({ detail }) => {
                                        setSelectValueErrorText("");
                                        let prevSelection = {...selectedProductFamilyType};
                                        if (detail.selectedOption !== prevSelection){
                                            setSortOrderRetrieved(false);
                                            if (prevSelection.value !== "" && Object.keys(items).length > 0){
                                                let itemToBeSaved = {...items};
                                                setSortOrderItemsEdited((prevState)=>{
                                                    return new Map(prevState).set(getProductFamilyTypeBeforeRename(prevSelection.value), itemToBeSaved);
                                                });
                                            }
                                            setItems({});
                                            setSelectedProductFamilyType(detail.selectedOption);
                                        }
                                    }}
                                    options={getPersistedProductFamilyTypeOptions()}
                                    selectedAriaLabel="Selected"
                            />
                            <FormField
                                errorText={selectValueErrorText}>
                            </FormField>
                        </Row>
                    </Col>
                </Col>
                <Col lg={3} className="d-flex justify-content-center">
                    {/* <FormField className="pt-2 px-2 fw-bold " label="Click Here to Edit">
                    </FormField> */}
                    <Button variant="primary" onClick={openSortOrderTable}>
                        <Icon path={mdiPencil} size={1.5} />
                    </Button>
                </Col>
                {/* <Col lg={1}></Col> */}
            </Row>

            {sortOrderRetrieved ? 
                <Row className="mt-5 d-flex justify-content-md-center align-items-center">
                    <Col lg={10} className="sort-order-table-divider">
                        <Row className="d-flex justify-content-md-center">
                            <Button variant="light" className="sort-order-table-close-btn" onClick={toggleSortOrderTable}>
                                {showSortOrder?
                                    <Icon path={mdiArrowExpandUp} size={1} />
                                    :
                                    <Icon path={mdiArrowExpandDown} size={1} />
                                }
                            </Button>
                        </Row>
                    </Col>
                </Row>
                :
                undefined
            }

            {sortOrderRetrieved && showSortOrder?
                <React.Fragment>
                    <Row className="d-flex justify-content-md-center">
                        <Col lg={5}>
                            <h3>
                                {getSortOrderTableTitle()}
                            </h3>
                            <FormField errorText={sortOrderErrorText}/> 
                        </Col>
                    </Row>
                    <Row className="mt-4 d-flex justify-content-md-center">
                        {/* <Col lg={2} className="d-flex justify-content-md-center">
                            <Button variant="primary"
                                    size="lg"
                                    disabled={disableSave}
                                    onClick={() => {saveSortOrder();}}>
                                Save Ordering Only
                            </Button>
                        </Col> */}
                        <Col lg={2} className="d-flex justify-content-md-center">
                            <Button variant="light"
                                    size="lg"
                                    onClick={() => {
                                            // force fetch and reset sort order for the selected option
                                            getSortOrderByProductFamilyType(selectedProductFamilyType, true);
                                        }}>
                                Reset Ordering
                            </Button>
                        </Col>
                    </Row>
                    <Row className="d-flex sort-order-table">
                        <Container>
                            <ProcessRoleSortTable 
                                items={items}
                                setItems={setItems}
                                // setDisableSave={setDisableSave}
                                renameRolesMap={renameRolesMap}
                                newRolesSet={newRolesSet}
                            />
                        </Container>
                    </Row>
                </React.Fragment>
                :
                undefined
            }
        </Row>
    );
}