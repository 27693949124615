import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog'
import { DialogContent } from '@material-ui/core';
import "../assets/css/components/ConfirmationDialog.css";

/**
 * Customizable component to display a dialog
 */
const ConfirmationDialog = (props) => {
    /**
     * Handler to close the dialog
     */
    const handleClose = () => {
      props.onClose();
    };
  
    return (
      <Dialog onClose={handleClose} open={props.open} className={`confirmation-dialog ${props.className}`}>
          <DialogContent>
            {props.children}
          </DialogContent>
      </Dialog>
    );
};

Dialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default ConfirmationDialog;