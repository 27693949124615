import { BreadcrumbGroup, Button, DatePicker, ExpandableSection, FormField, Icon, Tabs } from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../assets/css/components/CheckpointView.css";
import "../../assets/css/components/Keys.css";
import { ReportService } from "../../service/ReportService";
import AttributeFilter from "../AttributeFilter";
import { ACTIVITY_TYPE_ATTR_VALUES_UPDATE, CREATE_CHECKPOINT_MODE, RESPONSIBILITY_VALUES_SORT_LIST } from "../common/Constants";
import { sortListByOrder } from "../common/Utils";
import CheckpointReport from "./CheckpointReport";
import DeleteCheckpointDialogContent from "./DeleteCheckpointDialogContent";
import RaciTable from "./RaciTable";

/**
 * Loading page at the moment is just the Navbar
 *
 * @param props properties needed to render the page
 * - mode: whether we are creating or editing or viewing
 * - processId: the process id that is selected
 * - user: the user who is logged in
 * - updateMessage: method used to update the checkpoint message
 * - responsibilityAttributeId: attribute id for responsibility
 * - roleAttributeId: attribute id for role
 * - attributes: list of all attributes for given process
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckpointView(props) {

    const mode = props.mode;
    const processId = props.process.processId;
    const user = props.user;
    const updateMessage = props.updateMessage;
    const responsibilityAttributeId = props.responsibilityAttributeId;
    const roleAttributeId = props.roleAttributeId;
    const attributes = props.attributes;
    const [selectedValues, setSelectedValues] = useState({});
    const hiddenCriteria = ["Role", "Responsibility"];
    const [roleSortOrderMap,setRoleSortOrderMap] = useState({});
    const PRODUCT_FAMILY_TYPE_TEXT = "Product Family Type";
    const [openDeleteCheckpointConfirmationDialog, setOpenDeleteCheckpointConfirmationDialog] = useState(false);

    // get ID from the URL
    const { checkpointId } = useParams();
    const isCheckpointCreateMode = checkpointId === CREATE_CHECKPOINT_MODE;

    // store the checkpoint state
    const [checkpoint, setCheckpoint] = useState(null);

    // various fields that make up a checkpoint
    const maxDelegateRank = 2;
    const [checkpointDate, setCheckpointDate] = useState("");
    const [description, setDescription] = useState("");
    const [programName, setProgramName] = useState("");

    // RACI table fields
    const [selectedTabId, setSelectedTabId] = useState('');
    const [generatedTable, setGeneratedTable] = useState(false);
    const [tableVals, setTableVals] = useState({});
    const [loading, setLoading] = useState(false);
    const [dateError, setDateError] = useState("");
    const [programNameError, setProgramNameError] = useState("");
    const [page, setPage] = useState(1);
    const [validCombination,setValidCombination] = useState(true);
    const [expandSelectionCriteria, setExpandSelectionCriteria] = useState(true);

    // attribute editor for overrides
    const [overrideItems, setOverrideItems] = useState([]);
    const [checkpointOverridesAssignees, setCheckpointOverridesAssignees] = useState([]);
    const [checkpointOverridesDelegates, setCheckpointOverridesDelegates] = useState([]);
    // list of associated keys with events
    const [associatedKeys, setAssociatedKeys] = useState([]);

    // various maps and links that are used throughout the page
    const rankMap = {1: {label: "1st Rank", value: 1}, 2: {label: "All Ranks", value: 2}}

    const attributeIDNameMap = {}
    const attributeNameIdMap = {}

    Object.values(attributes).forEach(attribute =>  attributeIDNameMap[attribute.attributeId] = attribute.attributeName);
    Object.values(attributes).forEach(attribute =>  attributeNameIdMap[attribute.attributeName] = attribute.attributeId);

    let userPromises = [];

    // make header dynamic based on responsibilityAttribute values
    const raciHeader = responsibilityAttributeId.values ? sortListByOrder(responsibilityAttributeId.values, RESPONSIBILITY_VALUES_SORT_LIST)?.map(col => {
        return ({label: col, id: col})
    }) : []

    // set default tab to first value
    useEffect(() => {
        if (responsibilityAttributeId.values) {
            setSelectedTabId(responsibilityAttributeId.values[0])
        }
    }, [responsibilityAttributeId])

    // used to update url on save success
    const history = useHistory();

    // method that converts overrides from AUI component view to human readable view
    const overridesToList = (overrides) => {
        let overridesList = [];
        overrides.forEach(override => {
            if (override.value) {
                let newValues = override.value.replace(/\s/g, '').split(",")
                overridesList.push({role: override.role.id, responsibility: override.resp.id,
                    assignee: override.key, delegates:newValues})
            }
        })
        return overridesList
    }

    // method that converts overrides from human view to AUI view
    const listToOverrides = (overridesList) => {
        return overridesList.map(override => ({role: {id: override.role, label: override.role},
            resp: {id: override.responsibility, label: override.responsibility},
            key: override.assignee, value: override.delegates.join(", ")}))
    }

    // method that fetches the checkpoint based off of supplied id
    useEffect(()=> {
        if (!isCheckpointCreateMode) {
            API.get("ApproverMatrixAPI", `/checkpoints/${checkpointId}`, {})
                .then(response => {
                    setCheckpoint(response);
                    setCheckpointDate(response.checkpointDate);
                    setProgramName(response.programName);
                    setDescription(response.description);
                    setCheckpointOverridesAssignees(response.checkpointOverridesAssigneesRemove);
                    setCheckpointOverridesDelegates(response.checkpointOverridesDelegatesRemove);
                    setOverrideItems(listToOverrides(response.checkpointOverridesAdd));
                    setSelectedValues(response.checkpointCriteria);
                })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkpointId])

    const getCSVSortOrder = () =>{
        const productFamilyTypeKey = attributeNameIdMap[PRODUCT_FAMILY_TYPE_TEXT];
        const productFamilyTypeValue = selectedValues[productFamilyTypeKey];
        ReportService.getSortOrderForProductFamilyTypeAndProcess(processId, productFamilyTypeValue).then(
            response => {
                let sortOrderMap = {};
                Object.values(response.downloadOrder).forEach(value =>  sortOrderMap[value.role.trim()] = value.sortOrder);
                setRoleSortOrderMap(sortOrderMap);
            }).catch(
            err => {
                updateMessage('Unable to fetch sort order for product family type - ' + productFamilyTypeValue);
                setLoading(false);
            }
        );
    };

    /**
     * Validates Cascading Mappings of the selected attributes prior to the retrieval of keys
     */
    const validateCascadingMappings = (checkpointCriteria) => {
        for(const attributeId of Object.keys(attributes)) {
            const attribute = attributes[attributeId];
            if(attribute.parentAttributeId && attribute.cascadingMappings && checkpointCriteria[attributeId]) {
                const selectedAttributeValue = checkpointCriteria[attributeId];
                if(!selectedAttributeValue) return true;
                const mappedParentAttributeValues = attribute.cascadingMappings[selectedAttributeValue];
                if(!mappedParentAttributeValues) return false;
                const selectedParentAttributeValue = checkpointCriteria[attribute.parentAttributeId] ?? '';
                if(!mappedParentAttributeValues.includes(selectedParentAttributeValue)) {
                    return false;
                }
            }
        }
        return true;
    };

    // method that is called when "Generate Table" is clicked. Updates criteria and pulls associated keys
    const generateTable = () => {
        getCSVSortOrder();
        if (Object.keys(selectedValues).length === 0) {
            updateMessage("Please select a value for at least one criteria.", "error");
        } else {
            let emptyCoreAttribute = false;
            Object.values(attributes).forEach(attribute => {
                if (attribute.coreAttribute && !hiddenCriteria.includes(attribute.attributeName)) {
                    if(Array.isArray(selectedValues[attribute.attributeId]) && 
                        (!selectedValues[attribute.attributeId] || !selectedValues[attribute.attributeId][0])) {
                        emptyCoreAttribute = true;
                        return;
                    }
                    else if(!selectedValues[attribute.attributeId]) {
                        emptyCoreAttribute = true;
                        return;
                    }
                }
            })
            if (emptyCoreAttribute) {
                updateMessage("Please select a value for each core attribute.", "error");
            } else {
                const checkpointCriteria = {};
                for(const attributeId of Object.keys(selectedValues)) {
                    if(Array.isArray(selectedValues[attributeId]) && selectedValues[attributeId].length > 0 
                        && selectedValues[attributeId][0]) {
                       checkpointCriteria[attributeId] = selectedValues[attributeId][0];
                    }
                    else if(typeof selectedValues[attributeId] === 'string') {
                        checkpointCriteria[attributeId] = selectedValues[attributeId];
                    }
                }
                if(!validateCascadingMappings(checkpointCriteria)) {
                    updateMessage("Invalid mappings. Please try with a different combination", "error");
                    return;
                }
                setGeneratedTable(false);
                setLoading(true);
                API.post("ApproverMatrixAPI", `/keys/criteria`, {body: {criteria: checkpointCriteria, processId: processId}})
                    .then(response => {

                        if(response.keys.length === 0)
                        {
                            setValidCombination(false);
                        }
                        else{
                            setValidCombination(true);
                            setExpandSelectionCriteria(false);
                            setGeneratedTable(true);
                        }

                        setAssociatedKeys(response.keys);
                        setLoading(false);
                    });

            }
        }
    }

    const compareDates = (checkpointDate) => {
        let currentDate = new Date().setHours(0,0,0,0);
        checkpointDate = new Date(checkpointDate);
        checkpointDate.setDate(checkpointDate.getDate() + 1);
        checkpointDate.setHours(0,0,0,0);
        return currentDate < checkpointDate;
    }

    const startSaveCheckpoint = () =>{
        if (checkpointDate === "" || programName === ""){
            setDateError(checkpointDate === "" ? "Please select a date" : "");
            setProgramNameError(programName === "" ? "Please enter a Program Name" : "");
            updateMessage(programName === "" ?"Please provide Program Name for Checkpoint!":"Please provide Date for Checkpoint!", "error");
        }else{
            Promise.all(userPromises).then(response => {
                let attributeList = Object.values(attributes);
                attributeList.forEach(attr => {attr.alias = user.userAlias});
                let requestBody = {
                    body: {
                        processId: processId,
                        activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                        attributeValidation: true,
                        attributes: attributeList
                    }
                }
                userPromises.push(API.post("ApproverMatrixAPI", "/background-activity", requestBody)
                    .then(response => {
                        console.log(response);
                        if (response.existUnfinishedActivity || (!response.attributeValuesMatched)){
                            // abort save
                            console.log("cannot save checkpoint because of background activities or mismatched attribute values");
                            updateMessage("Unable to save Checkpoint because there are other admins editing this process! Please come back later and refresh page", "error");
                        }else{
                            saveCheckpoint();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        updateMessage("Unable to save Checkpoint because we cannot validate background activity and attribute values!", "error");
                    }))
            })
        }
    }

    // method to save a checkpoint in the DB
    const saveCheckpoint = () => {
        // preset init values
        let checkpointKeys = associatedKeys.map(key => key.keyId);
        let checkpointObj = {
            "processId": processId,
            "maxDelegateRank": 2,
            "active": true,
            "alias": user.userAlias,
            "checkpointDate": checkpointDate,
            "checkpointOverridesAssigneesRemove": [...checkpointOverridesAssignees],
            "checkpointOverridesDelegatesRemove": [...checkpointOverridesDelegates],
            "checkpointKeys": checkpointKeys,
            "description": description,
            "programName": programName
        }


        // convert override items to a map
        const overrideList = overridesToList(overrideItems)

        // try to save all the users in overrides (if they are not there error is thrown)
        overrideList.forEach(override =>  {
            userPromises.push(API.post("ApproverMatrixAPI", `/users`, {body: {userAlias: override.assignee, alias: user.userAlias,devAdmin:false}}))
            override.delegates.forEach(delegate => {
                userPromises.push(API.post("ApproverMatrixAPI", `/users`, {body: {userAlias: delegate, alias: user.userAlias,devAdmin:false}}))
            })
        })

        // ensure all calls to API succeeded
        Promise.all(userPromises)
            .then(response => {
                // set checkpoint fields and id if editing
                const checkpointCriteria = {};
                for(const attributeId of Object.keys(selectedValues)) {
                    if(Array.isArray(selectedValues[attributeId]) && selectedValues[attributeId].length > 0 
                        && selectedValues[attributeId][0]) {
                       checkpointCriteria[attributeId] = selectedValues[attributeId][0];
                    }
                    else if(typeof selectedValues[attributeId] === 'string') {
                        checkpointCriteria[attributeId] = selectedValues[attributeId];
                    }
                } 
                checkpointObj["checkpointCriteria"] = checkpointCriteria;
                checkpointObj["checkpointOverridesAdd"] = overrideList;
                checkpointObj["description"] = description;
                checkpointObj["programName"] = programName;
                checkpointObj["checkpointKeys"] = checkpointKeys;
                checkpointObj["checkpointOwner"] = isCheckpointCreateMode ? user.userAlias : checkpoint.checkpointOwner;
                if (!isCheckpointCreateMode) {
                    checkpointObj["checkpointId"] = checkpoint.checkpointId;
                }
                // final call to save checkpoint (if success show message and move to view mode)
                API.post("ApproverMatrixAPI", `/checkpoints`, {body: checkpointObj})
                    .then(response => {
                        updateMessage("Saved checkpoint successfully!", "success")
                        history.push(`/checkpoints`)
                    })
                    .catch(err => {
                        // on failure show error message
                        err?.response?.data?.errorMessage ?
                            updateMessage("Unable to save checkpoint! " + err.response.data.errorMessage, "error")
                            : updateMessage("Unable to save checkpoint. Please try again.", "error")
                    })
            })
            .catch(err => {
                // show error message in case use promises did not go through
                err?.response?.data?.errorMessage ? updateMessage("Unable to save user! " + err.response.data.errorMessage, "error")
                    : updateMessage("Unknown error saving user.", "error")
            });
    }

    // style for save button tooltip
    const FormattedTooltip = withStyles({
        arrow: {
            color: "black",
        },
        tooltip: {
            fontSize: "13px",
            maxWidth: 400,
            textAlign: "center",
            backgroundColor: "black",
        },
    })(Tooltip);

    return (
        <Container className="pt-4">
            <div>
                <BreadcrumbGroup items={[]}>
                </BreadcrumbGroup>
            </div>
            <Row className="justify-content-md-center mb-lg-4">
                <Col lg={4} id="checkpointPageTitle" className="d-flex justify-content-center">
                    {isCheckpointCreateMode ? 
                        <h1>Create Checkpoint</h1> 
                        :
                        <h1>Edit Checkpoint</h1>
                    }
                </Col>

            </Row>
            <div id="Checkpoint-top-row" className="Checkpoint-top-row">
                <div>
                    <FormField label="Program Name*">
                    </FormField>
                    <Input
                        id="programNameInputBox"
                        value={programName}
                        onChange={({detail}) => {
                            setProgramNameError("");
                            setProgramName(detail.value);
                        }}
                        type="text"
                    />
                    <FormField errorText={programNameError}>
                    </FormField> 

                </div>
                <div className="">
                    <FormField
                        label="Milestone Review Date*"
                    >
                        <DatePicker
                            id="checkPointDate"
                            onChange={({ detail }) => {
                                setDateError("");
                                setCheckpointDate(detail.value);}}
                            value={checkpointDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            isDateEnabled={date =>
                                compareDates(date)
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                        <FormField errorText={dateError}>
                        </FormField>
                    </FormField>
                </div>
                <div>
                    <FormField label="Description">
                    </FormField>
                    <Input
                        id="descriptionInputBox"
                        value={description}
                        onChange={({detail}) => setDescription(detail.value)}
                        type="text"/>
                </div>
                <div className="delete-checkpoint-wrapper" id="delete-checkpoint-wrapper">
                    {
                        !isCheckpointCreateMode &&
                            <>
                                <Button className="reset-all-button-wrapper" onClick={() => setOpenDeleteCheckpointConfirmationDialog(true)}>
                                    Delete Checkpoint
                                </Button>
                            </>
                    }
                </div>
            </div>
            
            <div className="checkpointView-selection-criteria-wrapper" id="checkpointView-selection-criteria-wrapper">
                <ExpandableSection 
                    header={<div className="checkpointView-selection-criteria-title-wrapper">
                        <h3 style={{fontWeight: "bold"}}>Selection Criteria</h3>
                    </div>} 
                    onChange={() => setExpandSelectionCriteria(prevState => !prevState)}
                    expanded={expandSelectionCriteria}>    
                    {
                        Object.values(attributes).map((attribute,index) => {
                            if (!hiddenCriteria.includes(attribute.attributeName)){
                                return <AttributeFilter setSelectedValues = {setSelectedValues}
                                                        attribute = {attribute}
                                                        attributes={attributes}
                                                        selectedValues = {selectedValues}
                                                        index = {index}
                                                        key = {index}
                                                        applyCascadingFilters
                                                        criteria={checkpoint?.checkpointCriteria}
                                                        classNamePrefix={'checkpointView'}
                                                        mode={mode}/>
                            } else {
                                return undefined;
                            }

                        })
                    }
                
                <div className="selection-criteria-action-buttons-wrapper">
                    <div>
                        <Button id="generateResponsibilityMatrixButton"
                            className="blue-button-theme-enabled-state" onClick={generateTable}>
                            Generate Responsibility Matrix
                        </Button>
                    </div>
                    <div>
                        <Button id="resetAllButton"
                            onClick={() => {setSelectedValues({})}}
                            className='reset-all-button-wrapper'
                        >
                            Reset All
                        </Button>
                    </div>
                </div>

                {
                    !validCombination &&
                        <div id="selection-criteria-error-wrapper" className="selection-criteria-error-wrapper">
                            <span className="selection-criteria-error">
                                <Icon name="status-warning" />
                            </span>
                            <span className="selection-criteria-error  selection-criteria-error-text">
                                No keys were found for the selected criteria.
                            </span>
                        </div>
                }
            </ExpandableSection>
        </div>
            

            {
                loading ?
                    <Row id="checkpoint-spinner-row" className="justify-content-md-center" >
                        <Col lg={4} className="mt-5 d-flex justify-content-center">
                            <CircularProgress id="checkpoint-spinner" className="ms-3 mb-1" size={40}/>
                            <FormField id="checkpoint-spinner-label"className="pt-3 px-2 fw-bold"></FormField>
                        </Col>
                    </Row>:undefined
            }

            { generatedTable && validCombination &&
                    <>
                        <div style={{marginTop: '2rem'}}>
                                <Tabs activeTabId={selectedTabId}
                                    onChange={(Checkpoint) => {
                                        setSelectedTabId(Checkpoint.detail.activeTabId);
                                        setPage(1);
                                    }}
                                    tabs={raciHeader}
                                />
                        </div>
                        <div className="checkpoint-RaciTable" id="checkpoint-RaciTable">
                            <RaciTable mode={mode} associatedKeys={associatedKeys} generatedTable={generatedTable}
                                        maxDelegateRank={maxDelegateRank} loading={loading}
                                        selectedTabId={selectedTabId}
                                        roleAttributeId={roleAttributeId} responsibilityAttributeId={responsibilityAttributeId}
                                        setTableVals={setTableVals} tableVals={tableVals} page={page} setPage={setPage}
                                        checkpointOverridesAssignees={checkpointOverridesAssignees}
                                        checkpointOverridesDelegates={checkpointOverridesDelegates}
                                        setCheckpointOverridesAssignees={setCheckpointOverridesAssignees}
                                        setCheckpointOverridesDelegates={setCheckpointOverridesDelegates}/>
                        </div>
                        <CheckpointReport   responsibilityAttributeId={responsibilityAttributeId} tableVals={tableVals} overridesToList={overridesToList}
                                            overrideItems={overrideItems} maxDelegateRank={maxDelegateRank}
                                            rankMap={rankMap}
                                            checkpointDate ={checkpointDate}
                                            programName = {programName}
                                            selectedValues = {selectedValues}
                                            attributes = {attributes}
                                            roleAttributeId={roleAttributeId}
                                            roleSortOrderMap={roleSortOrderMap}
                                            updateMessage={updateMessage}
                                            user={user}
                                            processId={processId}
                                />
                    </>
                }
            { 
                generatedTable && validCombination &&
                    <div className="checkpoint-final-row-wrapper" id="checkpoint-final-row-wrapper">
                        <div>
                            <FormattedTooltip arrow interactive title="Clicking on Save finalizes the checkpoint and ensures the meeting owner gets a reminder 2 days prior to the meeting date.">
                                <div>
                                    <Button onClick={startSaveCheckpoint} className="blue-button-theme-enabled-state">Save Checkpoint</Button>
                                </div>
                            </FormattedTooltip>
                        </div>
                        <div>
                            <Link to={'/checkpoints'}>
                                    <Button>Cancel</Button>
                            </Link>
                        </div>
                    </div>
            }

            {
                openDeleteCheckpointConfirmationDialog &&
                    <DeleteCheckpointDialogContent checkpoint={checkpoint} updateMessage={updateMessage} 
                        handleDialogClose={() => setOpenDeleteCheckpointConfirmationDialog(false)} user={user} />
            }
        </Container>
    )
}