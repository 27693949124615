import { Button, Input } from '@amzn/awsui-components-react';
import React from 'react';

/**
 * Component to render an added attribute for Update Responsibility Matrix
 */
const AddedAttribute = (props) => {
    const bulkEditAttribute = props.bulkEditAttribute;
    const setBulkEditAttributes = props.setBulkEditAttributes;

    /**
     * Removes an attribute from the Update Responsibility Matrix state
     */
    const handleRemoveAttribute = () => {
        setBulkEditAttributes(prevState => {
            const temp = [...prevState];
            const chosenBulkEditAttributeIndex = temp?.findIndex(attribute => attribute.label === bulkEditAttribute.label);
            temp?.splice(chosenBulkEditAttributeIndex, 1);
            return temp;
        });
    }

    return (
        <div id='addedAttribute' className='addedAttribute'>
            <div id='addedAttribute-attribute' className='addedAttribute-attribute'>
                <span className='addAttribute-select-attribute-title-wrapper'>Attribute</span>
                <div id='addedAttribute-attribute-select-wrapper' className='addedAttribute-attribute-select-wrapper'>
                    <Input value={bulkEditAttribute.label} disabled />
                </div>
            </div>
            <div id='addedAttribute-new-value' className='addedAttribute-new-value'>
                <span className='addAttribute-select-attribute-title-wrapper'>New Value</span>
                <div id="addedAttribute-new-value-input-wrapper" className='addedAttribute-new-value-input-wrapper'>
                    <Input value={bulkEditAttribute.value} disabled />
                </div>
            </div>
            <div id="addedAttribute-remove-wrapper" className='addedAttribute-remove-wrapper'>
                <Button onClick={handleRemoveAttribute} className='reset-all-button-wrapper'>
                    Remove Attribute
                </Button>
            </div>
        </div>
    );
};

export default AddedAttribute;