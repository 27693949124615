import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Amplify} from "aws-amplify";
import {Button, Col, Container, Row, Modal} from "react-bootstrap";
import {FormField, Input, Textarea} from "@amzn/awsui-components-react";
import Icon from "@mdi/react";
import { mdiPlusCircleOutline} from "@mdi/js";
import ProcessAttributes from "./ProcessAttributes";
import ProcessAdmin from "./ProcessAdmin";
import {useHistory} from "react-router-dom";
import "../assets/css/components/Process.css";
import ProcessRoleSortSection from "./ProcessRoleSortSection";
import AdditionalInfo from "./AdditionalInfo";
import {ReportService} from "../service/ReportService";
import { verifyUpdateReasonSelected } from "./common/Utils";
import {CircularProgress} from "@material-ui/core";
import {ACTIVITY_TYPE_ATTR_VALUES_UPDATE} from "./common/Constants"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * Page that loads when you try to create a new process or edit an existing process
 *
 * @param props properties needed to render the page
 * - mode: whether we are creating or editing a processs
 * - processId: the process id that is selected ,empty for a new process
 * - user: the user who is logged in
 * - attributes: list of all attributes for given process
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProcessView(props) {

    // get ID from the URL
    const query = useQuery();
    let id = query.get("processId");

    const mode = props.mode;
    const user = props.user;
    const updateMessage = props.updateMessage;
    const isDuplidateProcessName = props.isDuplidateProcessName;
    const refreshProcessMetadata = props.refreshProcessMetadata;

    const [disableProcessSave, setDisableProcessSave] = useState(true);
    const [attributes, setAttributes] = useState([]);
    const [attributesBackup, setAttributesBackup] = useState([]);
    const [roles, setRoles] = useState([]);
    const [productFamilyTypeOptions, setProductFamilyTypeOptions] = useState([]);
    const [rolesDiffMap, setRolesDiffMap] = useState(new Map());
    const [productFamilyTypeDiffMap, setProductFamilyTypeDiffMap] = useState(new Map());
    const [processId, setProcessId] = useState("");
    const [processAdmins, setProcessAdmins] = useState(mode === 'create' ? [{alias:"", validationError:"", validated:false, persisted:false, remove: false}] : []);
    const [processName, setProcessName] = useState("");
    const [processNameBackup, setProcessNameBackup] = useState("");
    const [description, setDescription] = useState("");
    const [processNameError, setProcessNameError] = useState("");
    const [disableAddAttribute, setDisableAddAttribute] = useState(false);
    const [sortOrderItemsEdited, setSortOrderItemsEdited] = useState(new Map());
    const [sortOrderItems, setSortOrderItems] = useState({});
    const [selectedProductFamilyType, setSelectedProductFamilyType] = useState({ label: "Select one", value: "" });
    const [savingProcess, setSavingProcess] = useState(false);
    const [openSavingProcessModal, setOpenSavingProcessModal] = useState(false);
    const [savingProcessHasErrors, setSavingProcessHasErrors] = useState(false);
    const [finishedSavingProcess, setFinishedSavingProcess] = useState(false);
    const [canReloadWindow, setCanReloadWindow] = useState(false);
    const simTicketIdRef = useRef("");
    const notesRef = useRef("");
    const updateReasonRef = useRef("");

    const history = useHistory();

    let userPromises = [];

    /**
     * Reload window after completing process save and exiting saving process modal
     */
    useEffect(() => {
        if (!savingProcess && !openSavingProcessModal && canReloadWindow) {
            window.location.reload();
        }
    }, [openSavingProcessModal, canReloadWindow, savingProcess]);

   // method that fetches the process based off of supplied id
    useEffect(()=>{
        if(mode === "create")
        {
            setAttributes([
                {
                    attributeName: "Role",
                    attributeValues: [],
                    coreAttribute: true,
                    attributeOrder:0,
                    remove: false,
                    attributeValuesDiffMap: new Map()
                },
                {
                    attributeName: "Responsibility",
                    attributeValues: [],
                    coreAttribute: true,
                    attributeOrder:1,
                    remove: false,
                    attributeValuesDiffMap: new Map()
                }
            ]);
        }
    },[mode]);

    useEffect(()=> {
        if (mode !== "create") {
            Amplify.API.get("ApproverMatrixAPI", `/processes/${id}`)
                .then(response => {
                   setProcessId(response.processId);
                   setProcessName(response.processName);
                   setProcessNameBackup(response.processName);
                   setProcessAdmins(response.processAdmins.map(admin => ({alias:admin, validated: true, validationError:"", persisted:true, remove:false})));
                   setDescription(response.description);
                })
        }

    }, [id, mode])

    useEffect(()=> {
        const initAttributes = (resAttributes)=>{
            resAttributes.sort((a, b)=>a.attributeOrder - b.attributeOrder);
            resAttributes.forEach(resAttr => {
                resAttr.alias = user.userAlias;
            })
            setAttributesBackup(resAttributes);
            setAttributes(resAttributes.map((attr)=>{
                let retobj = {
                    ...attr, 
                    remove: false,
                    attributeValues: attr.attributeValues.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1),
                    attributeValuesDiffMap: new Map(attr.attributeValues.map(
                        (val) => {
                            return [
                                val, 
                                {
                                    oldValue: val,
                                    modifyType: "NONE",
                                    persisted: true
                                }
                            ];
                        }
                    ))
                }
                return retobj;
            }));
        }

        if (mode !== "create" && processId !== "") {
            Amplify.API.get("ApproverMatrixAPI", `/attributes?processId=${processId}`)
                .then(response => {
                    let resAttributes = response.attributes;
                    initAttributes(resAttributes);
                    setRoles(resAttributes.find((attribute) => attribute.attributeName === 'Role').attributeValues.map(s => s.trim()));
                    const productFamilyTypes = resAttributes.find((attribute) => attribute.attributeName === 'Product Family Type');
                    const productFamilyTypeOptions = [];
                    if (productFamilyTypes !== undefined) {
                        productFamilyTypes.attributeValues.forEach((value) => {
                            productFamilyTypeOptions.push({label: value, value: value});
                        });
                    }
                    setProductFamilyTypeOptions(productFamilyTypeOptions);
                })
        }
    }, [processId, mode, user.userAlias]);

    const removeAdminByIndex = (index)=>{
        let count = processAdmins.length - processAdmins.filter((admin)=>{return admin.remove}).length;
        if (count === 1){
            updateMessage("The system must have at least one admin. ", "error");
            return;
        }
        setProcessAdmins(
            (prevAdmins)=>{
                const updatedAdmins = [...prevAdmins];
                updatedAdmins[index].remove = true;
                return updatedAdmins;
            }
        );
    }

    const undoRemoveAdminByIndex = (index)=>{
        setProcessAdmins(
            (prevAdmins)=>{
                const updatedAdmins = [...prevAdmins];
                if (updatedAdmins[index].persisted){
                    updatedAdmins[index].remove = false;
                }
                return updatedAdmins;
            }
        );
    }

    const getEventualProcessAdmins = ()=>{
        return processAdmins.filter(pa => !pa.remove).map(pa => pa.alias);
    }

    const validateProcess = () => {
        let valid = true;
        console.log(processAdmins);
        processAdmins.forEach((admin) => {
            if (!admin.remove && !admin.validated) {
                admin.validationError = "Please enter a valid alias";
                valid = false;
            }
        });
        if (processName === "") {
            setProcessNameError("Please enter a Process Name.");
            valid = false;
        }
        if (processName !== processNameBackup && isDuplidateProcessName(processName)){
            setProcessNameError("Duplcate Process Name! Please enter a new Process Name.");
            valid = false;
        }
        attributes.forEach((attribute) => {
            if (attribute.remove){
                return;
            }
            if (attribute.attributeValues.length === 0 && attribute.attributeName === ""){
                valid = false;
                attribute.attributeNameError = "Please enter an attribute name";
                attribute.attributeValuesError = "Please enter at least one attribute value";
            }
            else if (attribute.attributeValues.length !== 0 && attribute.attributeName === "") {
                valid = false;
                attribute.attributeNameError = "Please enter an attribute name";
            } else if (attribute.attributeName !== "" && attribute.attributeValues.length === 0) {
                valid = false;
                attribute.attributeValuesError = "Please enter at least one attribute value";
            }
        });
        return valid;
    }

    const getRolesBeforeRename = (inputRoles) => {
        return inputRoles.map((v)=>{
            if (rolesDiffMap.get(v)?.modifyType === "RENAME"){
                return rolesDiffMap.get(v).oldValue.trim();
            }else{
                return v.trim();
            }
        });
    }

    const getProductFamilyTypeBeforeRename = (inputProdFamType)=>{
        if (productFamilyTypeDiffMap.get(inputProdFamType)?.modifyType === "RENAME"){
            let oldProductFamilyType = productFamilyTypeDiffMap.get(inputProdFamType).oldValue;
            return oldProductFamilyType;
        }
        return inputProdFamType;
    }

    const validateBackgroundActivityAndAttributes = (nextStepFunction, params=[]) => {
        console.log("validating background activity and attributes");
        Promise.all(userPromises).then(response => {
            let requestBody = {
                body: {
                    processId: processId,
                    activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                    attributeValidation: true,
                    attributes: attributesBackup
                }
            }
            console.log(attributesBackup);
            userPromises.push(Amplify.API.post("ApproverMatrixAPI", "/background-activity", requestBody)
                .then(response => {
                    console.log(response);
                    if (response.existUnfinishedActivity || (!response.attributeValuesMatched)){
                        // abort save
                        console.log("cannot save process because of background activities or mismatched attribute values");
                        updateMessage("Unable to complete process because an admin is currently making updates. Please " +
                            "try again in a few minutes.", "error");
                    }else{
                        nextStepFunction(...params);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setSavingProcess(false);
                    updateMessage("Unable to complete process due to server or network issue. Please try again in a few minutes. ", "error");
                }))
        })
    }

    const startSaveProcess = ()=>{
        if(!validateProcess()) {
            updateMessage("Please fill out all of the required fields. ", "error");
        } 
        else if(!verifyUpdateReasonSelected(updateReasonRef.current, updateMessage)) {
            return;
        }else if (processId === "" || mode === "create"){
            // in create mode
            console.log(mode);
            console.log(mode==="create");
            console.log("in create mode, skip check 1");
            saveProcess();
        }else{
            validateBackgroundActivityAndAttributes(saveProcess);
        }
    }

    const saveProcess = () => {
        // mark saving process right now
        if (savingProcess){
            return;
        }
        setSavingProcess(true);
        setOpenSavingProcessModal(true);
        setDisableProcessSave(true);
        let requestObj = {
            body: {
                alias: user.userAlias,
                processId: processId,
                processName: processName,
                processAdmins: getEventualProcessAdmins(),
                description: description}
        };
        if(mode === "create") {
            requestObj = {
                body: {
                    alias: user.userAlias,
                    processName: processName,
                    processAdmins: getEventualProcessAdmins(),
                    description: description
                }
            };
        }
        Promise.all(userPromises)
            .then(response => {
                userPromises.push(Amplify.API.post("ApproverMatrixAPI", `/processes`, requestObj)
                    .then(response => {
                        setProcessId(response.processId);
                        saveSortOrder(response.processId);
                    })
                    .catch(err => {
                        console.log(err);
                        setSavingProcessHasErrors(true);
                        setSavingProcess(false);
                    }))
            })
    }

    const saveSortOrder = (processID=processId) => {
        // check if there is no sort order to save
        if (Object.keys(sortOrderItems).length === 0 && sortOrderItemsEdited.size === 0){
            // redirecting to next step
            console.log("skipping saving sort order");
            // saveProcessAttribute(processID);
            saveProcessAttributesList(processID);
        }else{
            let sortItemsToBeSaved = new Map(sortOrderItemsEdited).set(getProductFamilyTypeBeforeRename(selectedProductFamilyType.value), sortOrderItems);
            Promise.all(userPromises).then(response => {
                // save sort order only
                sortItemsToBeSaved.forEach((itemObj, key)=>{
                    userPromises.push(ReportService.saveCSVSortOrder({csvSortOrderList: {downloadOrder: Object.values(itemObj)}})
                    .then(
                        response => {
                            console.log('Successfully saved CSV sort order for product family type: ' + key);
                            if (key === selectedProductFamilyType.value){
                                console.log("update sort order items for currectly selected option: ", key);
                                const sortedRoles = response.csvSortOrderList.downloadOrder.sort((a, b) => a.sortOrder - b.sortOrder);
                                setSortOrderItems(sortedRoles.reduce((a, v) => ({ ...a, [v.sortOrder]: v}), {}));
                            }
                        }
                    ).catch(
                        err => {
                            console.log(err);
                            setSavingProcess(false);
                            setSavingProcessHasErrors(true);
                        }
                    ))
                });
                Promise.all(userPromises).then(response => {
                    // finish saving sort order changes (before rename), begin to save attribute changes (including rename)
                    // save sort order might take seconds to finish, we can check background activity again to make sure
                    if (processId === "" || mode === "create"){
                        saveProcessAttributesList(processID);
                    }else{
                        validateBackgroundActivityAndAttributes(saveProcessAttributesList, [processID]);
                    }
                })
            });
        }
    };

    const getAttributeValuesDiffList = (diffMap)=>{
        let retList = [];
        diffMap.forEach((value, key)=>{
            if (value.modifyType !== "NONE"){
                retList.push({
                    oldValue: value.oldValue,
                    modifyType: value.modifyType,
                    newValue: key
                })
            }
        });
        return retList;
    }

    const saveProcessAttributesList = (processID=processId) => {
        console.log("saving attrs");
        let attributesUpdateReqList = [];
        Promise.all(userPromises).then(response => {
            attributes.forEach(attribute => {
                if (attribute.remove){
                    return;
                }
                let valuesDiffList = getAttributeValuesDiffList(attribute.attributeValuesDiffMap);
                // check the generation of diff list
                if (valuesDiffList.length === 0){
                    return;
                }
                // construct request body
                let attributeReq = {};
                if(attribute.attributeId) {
                    attributeReq = {
                        alias: user.userAlias,
                        attributeId: attribute.attributeId,
                        attributeName: attribute.attributeName,
                        attributeValues: attribute.attributeValues,
                        attributeValuesDiffList: valuesDiffList,
                        processId: processID,
                        coreAttribute: attribute.coreAttribute,
                        multiValued: attribute.multiValued,
                        displayInEmail:attribute.displayInEmail,
                        attributeOrder:attribute.attributeOrder,
                        updateReason: updateReasonRef.current,
                        simTicketId: simTicketIdRef.current,
                        notes: notesRef.current
                    };
                } else {
                    attributeReq= {
                        alias:user.userAlias,
                        attributeName: attribute.attributeName,
                        attributeValues: attribute.attributeValues,
                        attributeValuesDiffList: valuesDiffList,
                        processId:processID,
                        coreAttribute:attribute.coreAttribute,
                        multiValued: true,
                        displayInEmail:false,
                        attributeOrder:attribute.attributeOrder,
                        updateReason: updateReasonRef.current,
                        simTicketId: simTicketIdRef.current,
                        notes: notesRef.current
                    };
                }
                attributesUpdateReqList.push(attributeReq);
            });
            if (attributesUpdateReqList.length === 0){
                finishSaveProcess();
                return;
            }
            let attributesReqbody = {
                body : {
                    attributes: attributesUpdateReqList
                }
            }
            console.log(attributesReqbody);
            userPromises.push(Amplify.API.post("ApproverMatrixAPI", `/attributes`, attributesReqbody )
                .then(response => {
                    console.log("save all process attributes successfully");
                    waitForBackgroundActivity(processID);
                })
                .catch(err => {
                    // on failure show error message
                    console.log(err);
                    setSavingProcess(false);
                    setSavingProcessHasErrors(true);
                }));
        });
    }


    const waitForBackgroundActivity = (processID=processId) =>{
        const checkFinished = ()=>{
            let requestBody = {
                body: {
                    processId: processID,
                    activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                    attributeValidation: false
                }
            }
            console.log(requestBody);
            userPromises.push(
                Amplify.API.post("ApproverMatrixAPI", "/background-activity", requestBody)
                .then(response => {
                    console.log(response);
                    if (response.existUnfinishedActivity){
                        setTimeout(checkFinished, 8000);
                    }else{
                        finishSaveProcess();
                    }
                })
                .catch(err =>{
                    console.log(err.response);
                    setSavingProcess(false);
                    setSavingProcessHasErrors(true);
                })
            )
        }
        // check if everything about save has been finished 
        Promise.all(userPromises).then(response => {
            setTimeout(checkFinished, 5000);
        })
    }


    const finishSaveProcess = ()=>{
        // check if everything about save has been finished 
        Promise.all(userPromises).then(response => {
            console.log("Finished saving process.");
            setFinishedSavingProcess(true);
            refreshProcessMetadata();
            setSavingProcess(false);
            setDisableProcessSave(true);
            setCanReloadWindow(true);
        })
    }

    useEffect(() => {
        if (!disableProcessSave){
            //there are edits unsaved
            const blockLeavePage = history.block("Are you sure you want to leave this page? There might be unsaved changes.");
            return ()=>{
                blockLeavePage();
            }
        }
    }, [history, disableProcessSave]);


    const onAddAdminButtonClick = useCallback(() => {
        setDisableProcessSave(false);
        setProcessAdmins(processAdmins => [...processAdmins, {alias:"", validationError:"", validated:false, persisted:false, remove:false}]);
    }, []);


    const onAddAttributesButtonClick = useCallback(() => {
        //Attribute order is set to attribute length since order starts from 0
        let count = 0;
        attributes.forEach(attr => {
            if (!attr.remove){
                count += 1;
            }
        });
        setAttributes(attributes => [...attributes, {attributeName:"",
                                                    attributeValues:[],
                                                    coreAttribute:false,
                                                    attributeOrder:count,
                                                    multiValued:true,
                                                    displayInEmail:false, 
                                                    remove: false,
                                                    attributeValuesDiffMap:new Map()}]);
        setDisableAddAttribute(true);
        setDisableProcessSave(false);
        updateMessage("Please change attribute name before adding new values!");
    }, [attributes, updateMessage]);

    const shouldDisplaySortOrderSection = () => {
        // if we want to support sort order for other attributes, change here
        return attributes.some((attr) => {return attr.attributeName === "Product Family Type"});
    }

    return (
        <Container className="pt-4 justify-content-lg-center container-fluid processview-container">
            <Modal show={openSavingProcessModal} backdrop="static" centered>
                <Modal.Header>
                    <h2>Saving Changes</h2>
                </Modal.Header>
                    <Modal.Body>
                        <Row className="d-flex align-items-center">
                            {
                                finishedSavingProcess ?
                                    <></> :
                                    <Col className="d-flex align-items-center" lg={2}>
                                        <CircularProgress className="ms-3 mb-1" size={30}/>
                                    </Col>
                            }
                            {
                                !finishedSavingProcess ?
                                    <Col className="d-flexalign-items-center " lg={10}>
                                        <h4>Saving Process...</h4>
                                        <h4>Updating Keys, Checkpoints, Drafts...</h4>
                                        <h4><i>This might take several minutes to finish.</i></h4>
                                    </Col> :
                                    savingProcessHasErrors ?
                                        <Col className="d-flexalign-items-center " lg={10}>
                                            <h4>Unable to save some or all changes due to server or network issue.</h4>
                                            <h4><i>Please try again in a few minutes.</i></h4>
                                        </Col> :
                                        <Col className="d-flexalign-items-center " lg={10}>
                                            <h4>All updates saved successfully.</h4>
                                            <h4> </h4>
                                        </Col>
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Button className="p-2 exitButton cancel-button-wrapper border-0"
                                    disabled={!finishedSavingProcess}
                                    onClick={() => setOpenSavingProcessModal(false)}
                                    variant="normal"
                            >Close</Button>
                        </Row>
                    </Modal.Footer>
            </Modal>
            {/* title */}
            <Row className="justify-content-md-center mb-lg-5">
                <Col lg={4} className="d-flex justify-content-center">
                    {mode === "create" ? <h1>Create Process</h1> : null}
                    {mode === "edit" ? <React.Fragment>
                        <h1>Edit Process</h1>
                    </React.Fragment>: null}
                </Col>
            </Row>

            {/* name and description */}
            <Row className="justify-content-md-center mb-lg-5 process-view-shadow">
                <Col lg={4} className="p-5 d-flex justify-content-center">
                    <Col lg={8}>
                        <FormField
                            label="Name">
                        </FormField>
                        <Input
                            value={processName}
                            onChange={({detail}) => {
                                setProcessNameError("");
                                setDisableProcessSave(false);
                                setProcessName(detail.value);
                            }}
                            autoComplete={false}
                        />
                        <FormField
                            errorText={processNameError}>
                        </FormField>
                    </Col>
                </Col>
                <Col lg={4} className="p-5 d-flex justify-content-center">
                    <Col lg={8}>
                        <FormField
                            label="Description">
                        </FormField>
                        <Textarea
                            value={description}
                            style={{marginLeft:"10px"}}
                            onChange={({detail}) => {
                                setDisableProcessSave(false);
                                setDescription(detail.value)
                            }}
                            autoComplete={false}
                            rows={1}
                            type="textArea"/>
                    </Col>
                </Col>
            </Row>
            
            {/* attribute edit section */}
            <Row className="pb-4 justify-content-md-center mb-lg-5 process-view-shadow">
                <Row className="pt-4 pb-2 justify-content-md-center mb-lg-5">
                        <Col lg={8} className="d-flex justify-content-center">
                            <React.Fragment>
                                <h2>Attributes</h2>
                            </React.Fragment>
                        </Col>
                </Row>
                <Row className="pb-4 justify-content-md-center">
                    <Col lg={4} className="d-flex justify-content-md-center ">
                        <FormField label="Attribute Name"/>
                    </Col>
                    <Col lg={4} className="d-flex justify-content-center">
                        <FormField label="Attributes Values"/>
                    </Col>
                    <Col lg={4} className="d-flex justify-content-center">
                        <FormField label="Add Values"/>
                    </Col>
                </Row>
                            
                {attributes.map((attribute,index) => {
                    if (attribute.remove){
                        return undefined;
                    }else{
                        return(
                            <ProcessAttributes 
                                key={index}
                                setDisableAddAttribute={setDisableAddAttribute}
                                userPromises={userPromises}
                                attribute={attribute}
                                setAttributes={setAttributes}
                                attributes={attributes}
                                setRoles={setRoles}
                                setRolesDiffMap={setRolesDiffMap}
                                setProductFamilyTypeOptions={setProductFamilyTypeOptions}
                                setProductFamilyTypeDiffMap={setProductFamilyTypeDiffMap}
                                index={index}
                                updateMessage={updateMessage}
                                setDisableProcessSave={setDisableProcessSave}
                                >
                            </ProcessAttributes>
                        )
                    }
                })}

                <Row className="mt-5 d-flex justify-content-center">
                    <Col lg={8} className="d-flex"></Col>
                    <Col lg={4} className="d-flex justify-content-center">
                        <Button variant="primary"
                            size="lg"
                            onClick={()=>{onAddAttributesButtonClick()}}
                            disabled={disableAddAttribute}>
                        <Icon className="ms-2"
                            path={mdiPlusCircleOutline}
                            color="white"
                            size={1.3} >
                        </Icon>
                        <span className="ms-2 px-2">Add New Attribute</span>
                        </Button>
                    </Col>
                </Row>
            </Row>

            {/* process admin section  */}
            <Row className="pb-4 justify-content-md-center mb-lg-5 process-view-shadow">
                <Row className="pt-4 pb-2 justify-content-md-center mb-lg-5">
                    <Col lg={8} className="d-flex justify-content-center">
                        <React.Fragment>
                            <h2>Process Admins</h2>
                        </React.Fragment>
                    </Col>
                </Row>

                {processAdmins.map((admin,index) => {
                    return (
                        admin.remove && !admin.persisted ? undefined:
                        <ProcessAdmin  
                            key={index}
                            admin={admin}
                            userPromises={userPromises}
                            setProcessAdmins={setProcessAdmins}
                            index={index}
                            processAdmin={processAdmins}
                            removeAdminByIndex={removeAdminByIndex}
                            undoRemoveAdminByIndex={undoRemoveAdminByIndex}
                            setDisableProcessSave={setDisableProcessSave}
                            >
                        </ProcessAdmin>
                    )
                })}

                <Row className="mt-5 d-flex justify-content-md-center">
                    <Col lg={8} className="d-flex"></Col>
                    <Col lg={4} className="d-flex justify-content-md-center">
                        <Button onClick={()=>{onAddAdminButtonClick()}}
                                variant="primary"
                                size="lg">
                            <Icon className="ms-2"
                                path={mdiPlusCircleOutline}
                                color="white"
                                size={1.3} >
                            </Icon>
                            <span className="ms-2 px-2">Add New Admin</span>
                        </Button>
                    </Col>
                </Row>
            </Row>
            
            {/* CSV Sort Order Section */}
            {
                shouldDisplaySortOrderSection() ?             
                <Row className="pb-4 justify-content-md-center mb-lg-5 process-view-shadow">
                    <Row className="py-4 justify-content-md-center">
                        <Col lg={8} className="d-flex justify-content-center">
                            <h2>CSV Export Sort Order for Role</h2>
                        </Col>
                    </Row>
                    <Row className="mb-3 justify-content-md-center">
                        <Col lg={10} className="d-flex justify-content-center">
                            {/* <h4>Disclaimer: <i><b>New</b> attribute values and <b>renamed</b> attribute values will <b>NOT</b> be reflected in sort order until the changes are saved!</i> </h4> */}
                            <h4>Please Note: Any changes to attribute values will <b>NOT</b> be reflected in the sort order until those updates are saved.</h4>
                        </Col>
                    </Row>
                    <ProcessRoleSortSection processId={processId}
                                            updateMessage={updateMessage}
                                            productFamilyTypeSelections={productFamilyTypeOptions}
                                            productFamilyTypeDiffMap={productFamilyTypeDiffMap}
                                            roles={roles}
                                            rolesDiffMap={rolesDiffMap}
                                            getProductFamilyTypeBeforeRename={getProductFamilyTypeBeforeRename}
                                            getRolesBeforeRename={getRolesBeforeRename}
                                            setDisableProcessSave={setDisableProcessSave}
                                            sortOrderItems={sortOrderItems}
                                            setSortOrderItems={setSortOrderItems}
                                            selectedProductFamilyType={selectedProductFamilyType}
                                            setSelectedProductFamilyType={setSelectedProductFamilyType}
                                            sortOrderItemsEdited={sortOrderItemsEdited}
                                            setSortOrderItemsEdited={setSortOrderItemsEdited}
                    />
                </Row> : undefined
            }

            {/* Additional Info Section */}
            <Row className="pb-4 justify-content-md-center mb-lg-5 process-view-shadow">
                <Row className="py-4 justify-content-md-center">
                    <Col lg={8} className="d-flex justify-content-center">
                        <React.Fragment>
                            <h2>Additional Information</h2>
                        </React.Fragment>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-md-center">
                        <AdditionalInfo simTicketIdRef={simTicketIdRef} notesRef={notesRef} updateReasons={props.updateReasons}
                        updateReasonRef={updateReasonRef} justifyCenter></AdditionalInfo>
                </Row>
            </Row>

            {/* Save and Cancel Buttons */}
            <Row className="pb-4 justify-content-md-center mb-lg-5 ">
                <Col lg={4} className="d-flex justify-content-center">
                    <Col lg={2} className="m-5">
                        <Button onClick={() => startSaveProcess()}
                                className="p-2 btn-primary process-button"
                                disabled={disableProcessSave}>
                            Save
                        </Button>
                    </Col>
                    <Col lg={2} className="m-5">
                        <Link to="/">
                           <Button  variant="normal"
                                    className="p-2 btn-outline-secondary process-button">
                                Cancel
                            </Button>
                        </Link>
                    </Col>
                </Col>
            </Row>
        </Container>
    )
}